.likePopup {
  display: flex;
  background-color: #fff;
  &__avatar {
    border-radius: 72px;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
  &__avatarImg {
    width: 100%;
    height: 100%;
  }

  .data-loader-wr {
    width: 40px;
    height: 40px;
    position: relative;
  }
  .data-loader {
    width: 40px;
    height: 40px;
    &::after {
      width: 40px;
      height: 40px;
    }
  }
}
