@import "../../../../../common.scss";

.proposal-history {
  margin-top: 24px;

  &__header {
    display: flex;
    align-items: center;

    .cr-button {
      font: normal normal bold 24px/40px PT Sans;
      letter-spacing: 0px;
      color: var(--base-blue);
      border: none;
      flex-direction: row-reverse;
      background: transparent;
      padding: 0;

      @include tablet() {
        font: normal normal bold 24px/29px PT Sans;
      }

      @include phone() {
        font: normal normal bold 20px/25px PT Sans;
        justify-content: flex-end;
        align-items: flex-start;
      }

      &__icon {
        @include phone() {
          margin-right: 0;
        }
      }

      &__text {
        @include phone() {
          white-space: normal;
          text-align: left;
        }
      }

      &:active,
      &:hover {
        background: transparent;
        color: var(--base-blue);
      }
      &__icon {
        margin-left: 8px;

        @include tablet() {
          margin-left: 12px;
        }
      }
    }

    .data-loader-wr {
      .data-loader,
      .data-loader::after {
        width: 30px;
        height: 30px;
      }
    }
  }

  &__body {
    margin-top: 16px;
    .proposal-card {
      padding: 0;
      &:first-child {
        margin-top: 16px;
      }

      &__actions {
        display: none;
      }
    }
  }

  &-card {
    & + & {
      margin-top: 24px;
    }
  }
}
