@import "../../../../common";

.resend-email-modal {
  &__root {
    z-index: 1003 !important;
  }

  width: 650px;
  max-width: 100%;
  font-size: rem(16);
  padding: rem(30);
  @include phone() {
    padding: rem(16);
  }

  &__title {
    margin-top: 0;
    font-weight: bold;
    text-transform: uppercase;
    font-size: rem(17);
    line-height: rem(30);
    margin-bottom: rem(20);
    @include phone() {
      margin-right: rem(32);
    }
  }

  &__body {
    margin-top: 0;
  }

  &__text {
    padding-top: rem(10);
    line-height: rem(23);
  }

  &__buttons {
    text-align: right;
    margin-top: rem(30);

    button:first-child {
      margin-right: rem(15);
      @include phone() {
        margin-right: 0;
        margin-bottom: rem(16);
      }
    }
  }
}
