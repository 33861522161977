@import "../../../../../common.scss";

.selection-forward-form {
  &__buttons {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;

    @include tablet-only() {
      margin-top: 24px;
    }

    @include phone() {
      margin-top: 1.25rem;
    }

    > *:first-child {
      margin-left: auto;
    }
  }
}
