@import "../../../../../common.scss";

.folder-buttons {
  @include tablet() {
    display: flex;
  }

  @include tablet-only() {
    gap: 16px;
    flex-wrap: wrap;
  }

  @include phone {
    gap: 0.5rem;
    font-size: 10px;
    line-height: normal;
    padding: 0.5rem 0;
  }

  .cr-button {
    padding: 0 19px;
    height: 42px;
    border-radius: 21px;
    font-family: PT Sans;
    font-style: italic;
    font-size: 18px;
    line-height: 23px;
    text-transform: none;
    letter-spacing: 0px;

    + .cr-button {
      margin-left: 16px;

      @include tablet-only() {
        margin-left: 0;
      }
    }
  }
}

.folder-button {
  @include phone {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-secondary-l2);
    &__icon {
      width: 2rem;
      height: 2rem;
    }
  }

  &--active {
    color: var(--base-blue);
  }
}
