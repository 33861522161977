@import "../../../../../../common";

.personal-post-comments {
  h2 {
    margin: rem(32) 0;
  }

  &__list {
    > div:not(:last-of-type) {
      margin-bottom: 32px;
    }
  }

  &__err-msg,
  &__no-comments {
    font: normal normal normal rem(20) / rem(32) PT Sans;
    color: var(--base-text);
  }

  .comment-card-list {
    scroll-margin-top: 81px + 12px;
    @include tablet() {
      scroll-margin-top: 65px + 12px;
    }
  }
}
