@import "../../../common.scss";

.stage__header {
  display: flex;
  flex-direction: column;
  padding-top: rem(8);
  padding-bottom: rem(32);
  background-color: #fff;
  position: relative;
  @include afterBeforeBackground(#fff);

  @include phone() {
    padding: rem(16) rem(16) 0;
  }

  &__stats {
    display: flex;
    margin-top: rem(8);
    > div {
      margin-right: rem(24);
    }

    @include phone() {
      margin: 0;
      padding-top: rem(8);
      padding-bottom: rem(16);
      justify-content: space-between;

      .item__stats {
        margin: 0;
      }
    }
  }

  &__description {
    font: normal normal normal rem(20) / rem(32) PT Sans;
    letter-spacing: 0;
    color: var(--base-text);
    margin-top: rem(32);

    @include tablet() {
      font: normal normal normal rem(18) / rem(24) PT Sans;
    }

    @include phone() {
      margin-top: 0;
      font: normal normal normal rem(16) / rem(20) PT Sans;
    }
  }
}
