@import "../../../../../../common";

.personal-post-controls {
  display: flex;
  justify-content: space-between;
  margin: rem(32) 0;

  &__buttons {
    display: flex;
    align-items: center;

    button.cr-button-outlined {
      display: none;
    }

    > div > div:not(:last-of-type),
    > button {
      margin-right: rem(24);
    }

    .flex {
      align-items: center;

      .cr-checkbox {
        margin: 0;
      }
    }
  }

  &__icon {
    color: var(--base-gray);
    cursor: pointer;
  }

  &__disable {
    cursor: default;

    i {
      opacity: 0.6;
    }
  }

  &__user-name {
    font-weight: bold;
  }

  &__end-activity {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font: rem(20) / rem(24) PT Sans;
    color: var(--base-text);
    margin-left: rem(8);
  }

  @include tablet() {

  }

  @include phone() {
    &__buttons {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      button.cr-button-filled {
        display: none;
      }

      button.cr-button-outlined {
        display: initial;
        border: none;
        color: var(--base-blue) !important;
        background-color: transparent;
      }
    }
  }
}
