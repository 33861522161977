@import "../../../common.scss";

.item__stats {
  display: flex;
  align-items: center;
  color: var(--base-text);

  &-common {
    font: normal normal normal rem(18) / rem(24) PT Sans;

    > i {
      color: var(--textarea-hint-color);
    }

    @include tablet() {
      font: normal normal normal rem(16) / rem(24) PT Sans;
    }

    @include phone() {
      font: normal normal normal rem(14) / rem(19) PT Sans;
    }
  }

  &-main {
    font: normal normal normal rem(20) / rem(24) PT Sans;
    color: #221f20;
  }

  > i {
    margin-right: rem(10);
    font-size: rem(20);

    @include tablet() {
      font-size: rem(24);
    }
    @include phone() {
      margin-right: rem(5);
      font-size: rem(13.34);
    }
  }
}
