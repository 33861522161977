@import "../../../../common";

div.auth-modal {
  max-width: 410px;
  padding: rem(30px) !important;

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;

    .cr-button {
      padding: 15px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font: 14px "Intro Bold";
      text-transform: uppercase;
      text-align: center;
      cursor: pointer;
      outline: none;
      border-radius: rem(6);
      &.mos-ru {
        color: #f20b06;
        border-color: #f20b06;
        margin-bottom: 25px;
        &:hover {
          background-color: #ffe8e8;
        }
      }
    }

    .mos-ru-icon {
      width: 20px;
      height: 20px;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RENENTM3Rjk1NDYyMTFFOUFBMzJGMUNBQkRDQUFCREUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RENENTM3RkE1NDYyMTFFOUFBMzJGMUNBQkRDQUFCREUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpEQ0Q1MzdGNzU0NjIxMUU5QUEzMkYxQ0FCRENBQUJERSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpEQ0Q1MzdGODU0NjIxMUU5QUEzMkYxQ0FCRENBQUJERSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PqASqC4AAAB1SURBVHjaYvz//z/DW3kuBiD4z0AZYAQRTAxUBiy4bCIBoPiM6i4cNXDUwFEDB9zA50iZHRs+CsRfsIiDwAtsBqYgS2ABVkDMjUX8KRAnw4sqpAIWX/F0E4grgHgfEH8itTxEByDDLIH4PbUipZJYw0AAIMAAaxgiEfFml/AAAAAASUVORK5CYII=");
    }
  }

  @include phone() {
    padding: rem(16) !important;
    width: 100% !important;
    min-height: 100vh !important;
    max-width: initial;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
