@import "../../../../common";

.fragment-modal {
  &__header {
    line-height: 1em;
  }
  .editor-content {
    word-break: break-word;

    & > p {
      margin-top: rem(24);
    }

    img {
      border-radius: 4px;
    }
  }

  @include phone() {
    &__footer {
      flex-direction: column-reverse;
    }
  }
}
