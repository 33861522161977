@import "../../../common.scss";

.calendar-stripe {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  letter-spacing: 0;

  &__month {
    text-transform: capitalize;
    text-align: center;
    margin-bottom: rem(23px);
    font: normal normal bold rem(24px) / rem(28px) PT Sans;
    color: var(--base-text);
  }

  &__cell {
    display: block;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    position: relative;

    &--today,
    &--active {
      .calendar-stripe__cell-date {
        color: var(--base-blue);
      }

      .calendar-stripe__cell-week {
        color: var(--base-blue);
      }
    }

    &--ghost {
      pointer-events: none;
      color: #797a8080;
      .calendar-stripe__cell {
        &-date {
          color: currentColor;
        }

        &-week {
          color: currentColor;
        }
      }
    }

    &-date {
      font: normal normal bold 20px/28px PT Sans;
      color: var(--base-text);

      @include tablet() {
        font: normal normal bold 18px/24px PT Sans;
      }
    }

    &-week {
      font: italic normal normal 16px/23px PT Sans;
      color: var(--base-gray);

      @include tablet() {
        font: italic normal normal 14px/20px PT Sans;
      }

      @include phone() {
        text-transform: capitalize;
      }
    }

    &-underline {
      border-bottom: 1px solid #65aae0;
      position: absolute;
      position: absolute;
      bottom: -5px;
      width: 100%;
    }
  }

  &__content {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  &__controls {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    cursor: pointer;

    &-left,
    &-right {
      width: 100%;
      height: 100%;
    }
  }

  &__underline {
    width: 100%;
    border-bottom: 1px solid #dedede;
    margin-top: 5px;
  }

  &__swipable {
    display: flex;
    width: 100%;
    transition: transform 0.4s;
  }

  &__arrow {
    z-index: 1;
    cursor: pointer;
    position: absolute;

    //&-left, &-right {
    //  top: 66px;
    //}

    &-left {
      left: -10px;

      @include phone() {
        left: -20px;
      }
    }

    &-right {
      right: -10px;

      @include phone() {
        right: -20px;
      }
    }

    &--disabled {
      color: lightgrey;
      pointer-events: none;
    }
  }
}
