@import "../../../common.scss";

.voting {
  .stage__header {
    padding-top: 0;
    padding-bottom: rem(40);

    @include phone() {
      padding-bottom: rem(20);
    }

    .page-title {
      margin-top: rem(40);
      @include tablet() {
        margin-top: rem(17);
      }
    }

    .item__stats-common {
      @include tablet() {
        font: normal normal normal rem(18) / rem(24) PT Sans;
      }
      @include phone() {
        font: normal normal normal rem(14) / rem(19) PT Sans;
      }
    }
  }
  &__action-bar {
    padding: rem(42px) rem(1px);
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    background: var(--base-ligth-blue);
    @include afterBeforeBackground(var(--base-ligth-blue));

    &.no-tabs {
      @include tablet() {
        .voting__change-topic-button {
          margin-top: 0;
        }
      }
      @include phone() {
        .voting__change-topic-button {
          margin-top: rem(24);
        }
      }
    }

    @include tablet() {
      padding-top: rem(32);
      padding-bottom: rem(40);
    }

    @include phone() {
      padding: 0;
      .tabs-mobile {
        background-color: white;
        margin: rem(16) 0;
        padding: rem(8) rem(16);
        width: 100%;
      }
    }

    .voting__change-topic-button {
      @include adaptive(940) {
        margin-top: rem(24);
      }
      .cr-button {
        background-color: transparent;
      }
    }

    .tabs {
      flex-grow: 1;
      align-items: center;
      .cr-button:not(.cr-button-filled) {
        background-color: transparent;
      }

      @include tablet() {
        flex-basis: 100%;

        .cr-button {
          margin: 0 rem(16px) 0 0;
        }
      }
    }
    &__stage-announce--wrapper,
    &__stage-finished--wrapper {
      flex-grow: 1;
      @include phone() {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: rem(24) rem(16);
        .voting__change-topic-button {
          margin-top: rem(24);
        }
      }
    }

    &__stage-announce,
    &__stage-finished {
      font: normal normal bold rem(28) / rem(32) PT Sans;
      letter-spacing: 0px;
      color: var(--base-text);

      @include tablet() {
        font: normal normal bold rem(26) / rem(33) PT Sans;
        letter-spacing: 0.52px;
      }

      @include phone() {
        font: normal normal bold rem(20) / rem(26) PT Sans;
        letter-spacing: 0px;
      }
    }
  }

  .stage-body-wrapper {
    padding-bottom: 32px;
    position: relative;
    flex-direction: column;
    background: var(--base-ligth-blue);
    @include afterBeforeBackground(var(--base-ligth-blue));

    .proposal:last-child {
      @include phone() {
        margin-bottom: rem(24);
      }
    }

    .voting__change-topic-button {
      width: 100%;
      @include phone() {
        padding: 0 rem(16);
      }

      .cr-button {
        background-color: transparent;
      }
    }
  }

  &__is-team-member {
    font: normal normal normal rem(20) / rem(32) PT Sans;
    letter-spacing: 0;
    color: var(--base-text);
    @include tablet() {
      font: normal normal normal rem(18) / rem(24) PT Sans;
    }
    @include phone() {
      padding: rem(16);
    }
  }
}

.data-loader-wr {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  // background-color: #fffd;
  z-index: 1000;
}
