.attachment-view {
    border-radius: 4px;
    margin-right: 4px;
    margin-top: 4px;
    
    &__link {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
}