@import "../../../../../common";

.warning-items {
  margin-top: rem(32);

  &__item {
    margin-top: rem(12);

    &__row {
      display: flex;
      padding-bottom: rem(12);
      font: normal normal normal rem(14px) / rem(20) PT Sans;

      &__title {
        width: 25%;
        color: var(--color-secondary-l2);
        word-break: break-word;
      }

      &__content {
        padding-left: rem(8);
        width: 75%;
        color: var(--base-text);
      }
    }
  }

  &__item:not(:last-of-type) {
    border-bottom: 1px solid var(--color-secondary-l5);
  }
}
