@import "../../../../common.scss";

$frontmenCardSize: 256px;
$frontmenCardSizeTablet: 176px;
$frontmenCardSizePhone: 66px;

.team__frontmen__card {
  display: flex;
  justify-content: center;
  margin-top: rem(24);
  min-height: 372px;
  position: relative;

  &__content {
    display: flex;
    flex-direction: column;
    @include phone() {
      flex-direction: row;
      align-items: flex-start;
      width: 100%;
    }
  }

  &__picture {
    width: $frontmenCardSize;
    height: $frontmenCardSize;
    background-color: var(--base-border);
    border-radius: 4px;
    @include tablet() {
      width: $frontmenCardSizeTablet;
      height: $frontmenCardSizeTablet;
    }
    @include phone() {
      width: $frontmenCardSizePhone;
      height: $frontmenCardSizePhone;
      margin-right: rem(8);
    }
  }
  &__name {
    width: $frontmenCardSize;
    font: normal normal bold rem(20) / rem(28) PT Sans;
    color: var(--base-text);
    margin-top: rem(32);
    margin-bottom: rem(12);
    @include tablet() {
      margin-top: rem(16);
      margin-bottom: rem(12);
      width: $frontmenCardSizeTablet;
      font: normal normal bold rem(18) / rem(26) PT Sans;
    }
    @include phone() {
      margin-top: 0;
      margin-bottom: rem(8);
      width: auto;
      font: normal normal bold rem(16) / rem(16) PT Sans;
    }
  }

  &__position {
    width: $frontmenCardSize;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font: normal normal normal rem(18) / rem(28) PT Sans;
    color: var(--base-text);
    visibility: visible;
    &.invisible {
      visibility: hidden;
    }

    @include tablet() {
      width: $frontmenCardSizeTablet;
      font: normal normal normal rem(16) / rem(22) PT Sans;
      color: var(--textarea-hint-color);
    }
    @include phone() {
      width: auto;
      font: normal normal normal rem(14) / rem(20) PT Sans;
    }
  }

  button {
    position: absolute;
  }

  @include tablet() {
    min-height: auto;
    margin-top: rem(32);
  }
  @include phone() {
    margin-top: rem(16);
    justify-content: flex-start;
    &__block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: rem(8);
    }
  }
}
