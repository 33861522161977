@import "../../../../common";
.complaint-modal {
  width: 800px;
  color: var(--base-text);

  &__subhead {
    display: flex;
    align-items: center;
    margin-top: rem(32);
    @include phone() {
      margin-top: calc(var(--header-height-phone) - 15px) !important;
    }
  }

  &__body {
    @include phone() {
      margin-top: 0 !important;
    }
  }

  &__user {
    display: flex;
    align-items: center;
  }

  &__user-name {
    font: normal normal bold rem(20) / rem(24) PT Sans;
    margin-left: rem(16);
    display: flex;
    align-items: center;
  }

  .cr-textarea__input {
    width: 100%;
  }

  &__footer {
    @include phone() {
      .submit-form__footer {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
      }

      button.cr-button {
        margin-bottom: 0 !important;
        margin-left: 0;
      }
    }
  }
}
