@import "../../../../common.scss";

$experdCardWidth: 432px;
$experdCardColumnGap: 32px;

.team__user-card {
  &--detailed {
    display: flex;
    align-self: flex-end;
    padding: rem(24) rem(68) 0;
    position: relative;

    .ui-icon-close {
      position: absolute;
      top: rem(0);
      right: rem(48);
      width: rem(24);
      height: rem(24);
      cursor: pointer;
      color: var(--color-secondary-l2);
      @include tablet() {
        right: 0;
      }
    }

    a {
      color: var(--color-primary);
    }

    &-wrapper {
      display: flex;

      @include tablet() {
        flex-direction: row;
      }
      @include phone() {
        flex-direction: column;
        align-items: center;
      }
    }
    &__info {
      display: flex;
      flex-direction: column;
      margin-right: rem(92);
      @include tablet() {
        margin-right: rem(24);
      }
      @include phone() {
        align-items: center;
        margin-right: 0;
        &_text {
          display: flex;
          width: 100%;
          flex-direction: column;
        }
      }
    }

    &__picture {
      width: 256px;
      height: 256px;
      background-color: var(--base-border);
      border-radius: 4px;
      @include tablet() {
        width: 100px;
        height: 100px;
      }
    }
    .social-network {
      @include tablet() {
        flex-wrap: wrap;
        a:not(:nth-child(3n)) {
          .social-network__item {
            margin-right: rem(12);
          }
        }
        a {
          .social-network__item {
            margin-right: 0;
            margin-top: rem(12);
          }
        }
      }

      @include phone() {
        display: flex;
        justify-content: space-between;
      }
    }
    &__name {
      font: normal normal bold rem(20) / rem(32) PT Sans;
      letter-spacing: 0;
      color: var(--base-text);
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      margin-top: 32px;
      @include tablet() {
        margin-top: 0;
        justify-content: flex-start;
        font: normal normal bold rem(18) / rem(26) PT Sans;
      }
      @include phone() {
        font: normal normal bold rem(16) / rem(22) PT Sans;
      }
    }

    &__role {
      font: italic normal normal rem(18) / rem(24) PT Sans;
      letter-spacing: 0;
      color: var(--base-gray);
      margin-top: rem(2);
      margin-bottom: rem(8);
      @include tablet() {
        text-align: left;
        font: italic normal normal rem(16) / rem(22) PT Sans;
        margin-bottom: rem(23);
      }
      @include phone() {
        font: italic normal normal rem(12) / rem(18) PT Sans;
      }
    }

    &__description {
      font: normal normal normal rem(20) / rem(32) PT Sans;
      letter-spacing: 0;
      color: var(--base-text);
      @include tablet() {
        margin-bottom: rem(24);
        font: normal normal normal rem(18) / rem(24) PT Sans;
      }
      @include phone() {
        font: normal normal normal rem(16) / rem(20) PT Sans;
      }
    }

    &__attachments {
      display: flex;
      flex-direction: column;
      &_img {
        display: flex;
        margin-top: rem(24);
        @include tablet() {
          margin-bottom: rem(24);
        }
        @include phone() {
          margin-top: 0;
        }
      }
    }

    &__details button {
      margin-top: rem(32);
      @include phone() {
        margin-top: 0;
      }
    }

    @include tablet() {
      flex-direction: column;
      padding: 0 rem(32);
    }
    @include phone() {
      padding: 0;
    }
  }
}
.experts .team__user-card {
  &--detailed {
    .ui-icon-close {
      @include tablet() {
        top: rem(-16);
        right: rem(48);
      }
    }
    &-wrapper {
      @include phone() {
        align-items: flex-start;
      }
    }
    &__picture {
      @include tablet() {
        width: 64px;
        height: 64px;
        border-radius: 50%;
      }
      @include phone() {
        width: 40px;
        height: 40px;
        margin-right: rem(8);
      }
    }
    &__info {
      @include tablet() {
        margin-right: rem(16);
      }
      .social-network {
        @include tablet() {
          display: none !important;
        }
      }
      @include phone() {
        flex-direction: row;
        align-items: center;
        margin-bottom: rem(16);
        &_text {
          display: flex;
          flex-direction: column;
        }
      }
    }
    &__role {
      @include phone() {
        margin-bottom: 0;
      }
    }
    &__details {
      @include phone() {
        max-width: 100%;
      }
    }
    &__attachments {
      @include tablet() {
        padding-left: rem(80);
      }
      @include phone() {
        padding: 0;
      }
      &_img {
        @include phone() {
          overflow-x: scroll;
        }
      }
    }
    @include tablet() {
      padding: rem(48) rem(68) 0;
    }
  }
}
.frontmen .team__user-card {
  &--detailed {
    &__info {
      @include phone() {
        align-items: flex-start;

        &_text {
          align-items: center;
        }
      }
      .social-network {
        @include phone() {
          flex-wrap: nowrap;
          margin-top: rem(16);
          a {
            margin-right: rem(8);
            &:nth-last-child(1) {
              margin-right: 0;
            }
            .social-network__item {
              margin-right: 0;
              margin-top: 0;
            }
          }
        }
      }
    }
    &__name {
      @include tablet() {
        margin-bottom: rem(12);
      }
      @include phone() {
        margin-bottom: 0;
      }
    }

    &__role {
      @include tablet() {
        display: none;
      }
    }
    &__picture {
      @include phone() {
        width: 184px;
        height: 184px;
        margin-bottom: rem(16);
      }
    }
    &__details {
      @include phone() {
        margin-top: rem(12);
        max-width: 100%;
      }
    }
    &__attachments {
      &_img {
        @include phone() {
          overflow-x: scroll;
        }
      }
    }
  }
}

.coordinators .team__user-card {
  &--detailed {
    &__picture {
      width: 192px;
      height: 192px;
      border-radius: 50%;
      align-self: center;
      margin: 0 rem(17);
      @include tablet() {
        width: 100px;
        height: 100px;
        margin: 0;
      }
      @include phone() {
        width: 184px;
        height: 184px;
        margin-bottom: rem(16);
      }
    }
    &__name {
      margin-top: rem(24);
      @include tablet() {
        margin-top: 0;
      }
      @include phone() {
        margin-top: rem(8);
      }
    }
    &__name,
    &__role {
      text-align: center;
      justify-content: center;
      @include tablet() {
        text-align: left;
        justify-content: flex-start;
      }
      @include phone() {
        text-align: center;
        justify-content: center;
      }
    }
    &__role {
      @include tablet() {
        margin-bottom: rem(12);
      }
    }
    &__info {
      margin-right: rem(40);
      @include tablet() {
        margin-right: rem(24);
      }
      @include phone() {
        margin-right: 0;
      }
    }
    &__attachments {
      @include tablet() {
        padding-left: rem(124);
      }
      @include phone() {
        padding-left: 0;
      }
    }
  }
}

.arrow-back__wrapper {
  display: flex;
  align-items: center;
  color: var(--base-blue);
  padding: rem(13) rem(16);
  background: #fff;
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 19;
  .ui-icon-arrow-back {
    margin-right: rem(10);
  }
  &_text {
    font: normal normal bold rem(14) / rem(19) PT Sans;
  }
}

.team__phone_wrapper {
  padding-top: rem(81);
  &__list {
    .carousel-slider {
      position: relative;
      padding-bottom: rem(68);
      .carousel-slider__prev,
      .carousel-slider__next {
        position: absolute;
        bottom: 0;
        background: transparent;
        box-shadow: none;
        transform: translate(0, -50%);
      }
      .carousel-slider__prev {
        left: 36%;
      }

      .carousel-slider__next {
        right: 36%;
      }
    }
  }
}
