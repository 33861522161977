@import "../../../../../common.scss";

.selection-tag-filter,
.popup-search-table {
  &__table {
    table td,
    table th {
      @include tablet() {
        padding: 2px 0 10px;
      }

      @include phone() {
        vertical-align: top;
        padding: 3px 0;
      }
    }

    table td {
      @include tablet-only() {
        padding: 10px 0;
      }

      @include phone() {
        padding: 0 0 13px;
      }
    }

    table th {
      @include phone() {
        padding: 0 0 15px;
      }
    }

    &__body {
      td {
        @include phone() {
          line-height: 18px !important;
        }
      }
    }
  }

  &__table-name-column {
    @include tablet-only() {
      width: 208px;
    }

    @include phone() {
      min-width: 95px;
      padding-top: 6px !important;
    }

    &:not(th) {
      @include phone() {
        padding-top: 9px !important;
      }
    }
  }

  &__table-proposal-count-column {
    @include tablet-only() {
      width: 88px;
    }

    @include phone() {
      min-width: 36px;
    }

    &:not(th) {
      @include tablet-only() {
        padding-left: 8px !important;
      }

      @include phone() {
        padding-top: 10px !important;
      }
    }
  }

  &__table-finalized-column {
    @include tablet-only() {
      width: 88px;
    }

    @include phone() {
      min-width: 35px;
    }

    &:not(th) {
      @include tablet-only() {
        padding-left: 8px !important;
      }

      @include phone() {
        padding-top: 10px !important;
      }
    }
  }

  &__table-author-column {
    &:not(td) {
      @include phone() {
        padding-top: 6px !important;
      }
    }

    &:not(th) {
      @include phone() {
        line-height: 19px !important;
      }
    }
  }
}

.crowd-table__select-column {
  @include tablet() {
    padding-left: 3px !important;
    padding-right: 26px !important;
  }

  @include phone() {
    padding-right: 15px !important;
    padding-right: 15px !important;
  }

  &:not(th) {
    @include phone() {
      padding-top: 16px !important;
    }
  }
}

.selection-tag-filter {
  &__table {
    table th {
      @include tablet-only() {
        padding: 0px 0 12px;
      }

      @include phone() {
        padding-bottom: 12px !important;
      }
    }

    table td {
      @include tablet-only() {
        padding: 7px 0;
      }
    }

    &-proposal-count-column {
      &:not(th) {
        @include phone() {
          padding-left: 0 !important;
        }
      }
    }

    &-author-column {
      &:not(th) {
        @include phone() {
          padding-top: 11px !important;
        }
      }
    }
  }
}

.selection-page__tag-combine-popup {
  .selection-tag-filter {
    &__table {
      table th {
        @include tablet-only() {
          padding: 0 0 18px;
        }
      }

      &-proposal-count-column {
        &:not(th) {
          @include phone() {
            padding-left: 10px !important;
          }
        }
      }

      &-author-column {
        &:not(th) {
          @include phone() {
            padding-top: 14px !important;
          }
        }
      }

      &-name-column {
        @include tablet-only() {
          width: 180px;
        }
      }
    }
  }
}
