@import "../../../../common.scss";

.project-questionnaire-form {
  &__root {
    z-index: 1001 !important;
  }

  &__title {
    font: normal normal normal rem(20) / rem(32) PT Sans;
    @include phone() {
      font: normal normal normal rem(16) / rem(28) PT Sans;
    }
    a {
      color: var(--base-blue);
    }
  }
  &__field {
    margin-top: rem(32);
    @include phone() {
      margin-top: rem(16);
    }

    &__radio,
    &__checkbox {
      &__options {
        display: flex;
        @include phone() {
          flex-direction: column;
        }
      }
    }

    .cr-input,
    .cr-textarea,
    .cr-select,
    .cr-checkbox {
      margin-top: rem(16);
    }

    .cr-checkbox {
      margin-right: rem(32);
      font: normal normal normal rem(20) / rem(24) PT Sans;
      @include phone() {
        margin-top: rem(16);
      }

      label {
        align-items: center;
      }
    }

    .cr-radio {
      @include phone() {
        margin: rem(16) 0 rem(2) 0;
      }
      label {
        font: normal normal normal rem(20) / rem(32) PT Sans;
      }
    }
  }
  &__field__label {
    display: block;
    font: normal normal bold rem(18) / rem(21) PT Sans;
    letter-spacing: 0px;
    color: var(--base-text);
    &--required {
      color: #65aae0;
      margin-left: rem(5);
    }
  }
  .cr-select {
    .cr-select__placeholder {
      .ui-icon-arrow-slider-left {
        right: 20px;
        top: 22px;
        font-size: 16px;
      }
    }
  }
  &__field__hint {
    margin-left: rem(16);
    margin-top: rem(8);
    font: normal normal normal rem(14) / rem(23) PT Sans;
    letter-spacing: 0px;
    color: var(--textarea-hint-color);
    &--questionnaire {
      margin-left: 0;
      margin-top: rem(8);
    }

    @include phone() {
      margin-left: 0;
    }
  }
  .cr-textarea__length {
    @include phone() {
      margin-left: 0;
    }
  }

  .crowd-modal__footer {
    @include phone() {
      margin-top: rem(16);
    }
    .submit-form__footer {
      @include phone() {
        width: 100%;
      }
    }
  }
}
