@import "../../../../../common";

.crop-avatar {
  margin-left: auto;

  &-modal {
    max-width: 648px;
  }

  &__title {
    font: normal normal bold rem(36) / rem(24) Bebas Neue;
    color: var(--base-text);
  }

  &__content {
    text-align: center;
  }

  &__buttons {
    display: flex;
    margin-top: rem(20);
    justify-content: flex-end;

    button {
      margin-left: rem(16);
    }
  }
  &__error {
    margin-bottom: rem(32);
  }

  &-disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}
.image__error {
  .cr-button {
    border-radius: 5px;
  }
}
