@import "../../../common.scss";

.selection-page {
  font: normal normal normal 20px/24px PT Sans;
  color: var(--base-text);

  @include phone() {
    margin: 0 16px 44px;
  }

  .page-title {
    line-height: 51px;
    margin-top: 41px;

    @include tablet-only() {
      margin-top: 8px;
      line-height: 47px;
    }

    @include phone() {
      margin-top: 14px;
      line-height: 30px;
    }
  }

  &__block-indent {
    margin-top: 2rem;

    @include tablet-only() {
      margin-top: 1.5rem;
    }

    @include phone() {
      margin-top: 1rem;
    }
  }

  &__indicators {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-top: 6px;
    font: normal normal bold 18px/24px PT Sans;

    @include phone() {
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      font-size: 14px;
      margin-top: 6px;
    }

    .ui-icon {
      @include phone() {
        font-size: 16px;
      }
    }
  }

  &__remained {
    &_finishing {
      color: var(--color-negative);
    }
  }

  &__unreviewed {
    letter-spacing: 0px;
    color: var(--color-secondary-l2);

    @include tablet-only {
      font-size: 16px;
    }
  }

  &__remained,
  &__unreviewed {
    gap: 0.5rem;
    display: flex;
    align-items: center;

    @include phone() {
      height: 18px;
    }
  }

  &__main {
    display: flex;

    @include phone() {
      flex-direction: column;
    }
  }

  &__idea {
    &:last-child {
      @include phone() {
        margin-bottom: 20px;
      }
    }

    &-user-info-icon {
      @include tablet() {
        font-size: 19px;
      }
    }

    &__info {
      margin-top: 24px;
      position: relative;

      @include tablet-only() {
        margin-top: 18px;
      }

      @include phone() {
        margin-top: 16px;
      }

      &__main-mark {
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(calc(-100% - 16px), -8px);
        font-size: 40px;
        color: var(--color-accent);
        margin-right: 16px;
      }
    }

    &__control {
      display: flex;
      align-items: center;

      @include tablet-only() {
        margin-top: 24px;
      }

      @include phone() {
        display: block;
        background-color: var(--color-primary-l1);
        padding: 1.25rem 1rem;
        border-radius: 0.25rem;

        .cr-select {
          margin-top: 0;
        }
      }

      &--short-margin {
        @include tablet() {
          margin-top: 12px;
        }
      }

      .cr-button {
        padding: 0;
        .ic {
          color: var(--color-secondary-l2);
          margin: 0;

          @include tablet-only() {
            font-size: 24px;
          }
        }
        &:hover {
          .ic {
            color: var(--color-primary);
          }
        }
      }

      > * + * {
        margin-left: 24px;

        @include tablet-only() {
          margin-left: 20px;
        }
      }

      .user-info {
        margin-left: auto;

        @include tablet-only() {
          margin-top: 2px;
        }

        &__name {
          @include tablet-only() {
            font-size: 16px;
          }
        }

        &__sub-text {
          @include tablet-only() {
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 0;
          }
        }

        .user-avatar__container {
          @include tablet-only() {
            margin-right: 6px;
          }
        }
      }
    }

    &__current-state {
      color: var(--color-primary);
      font-weight: bold;
      font-size: 18px;
      display: flex;

      @include tablet-only() {
        font-size: 16px;
      }

      .ic {
        margin-right: 12px;
        font-size: 20px;
        line-height: 20px;

        @include tablet-only() {
          font-size: 24px;
        }
      }
    }
  }

  &__fetching {
    opacity: 0.3;
    pointer-events: none;
  }

  &__filters {
    @include phone() {
      margin-bottom: 1.25rem;
    }
    &-edit-tag-card {
      @include tablet-only() {
        margin-top: 16px !important;
      }
    }
  }

  &__tag {
    display: flex;
    align-items: center;
    position: relative;

    & #{&}-name {
      font: normal normal bold 20px/24px PT Sans;
      color: var(--color-primary);
      text-transform: none;
      padding: 0;

      @include phone() {
        font-size: 16px;
      }
    }

    .cr-button__text {
      @include phone() {
        line-height: 18px;
      }
    }

    & #{&}-control {
      margin-left: 24px;

      @include tablet-only() {
        margin-left: 20px;
      }

      @include phone() {
        margin-left: 0.75rem;
      }

      .cr-button {
        display: flex;
        padding: 0;

        .ic {
          color: var(--color-secondary-l2);
          font-size: 24px;
          line-height: 1;
          margin: 0;

          @include phone() {
            font-size: 1.25rem;
          }
        }
      }

      & .cr-button:hover,
      &_active .cr-button {
        .ic {
          color: var(--color-primary);
        }
      }
    }

    &-counter {
      font: normal normal bold 20px/32px PT Sans;
      color: var(--color-secondary-l2);
      margin-left: 4px;

      @include tablet-only() {
        line-height: 18px;
      }

      @include phone() {
        font-size: 16px;
        line-height: 18px;
      }
    }

    &-idea-counter {
      font: italic normal normal 18px/24px PT Sans;
      color: var(--color-secondary-l2);
      margin-top: 16px;

      @include phone() {
        margin-top: 14px;
      }
    }

    &-combine-popup {
      position: static;

      .popup {
        @include tablet-only() {
          left: 0px;
        }

        &__header {
          @include tablet-only() {
            font-size: 36px;
            line-height: 36px;
          }
        }

        .popup__footer .cr-button:not(:first-child) {
          @include tablet-only() {
            margin-left: 18px;
          }
        }
      }

      .crowd-modal__footer .cr-button:first-child {
        @include phone() {
          margin-top: 4px;
        }
      }
    }
  }

  &__inputs {
    display: flex;
    align-items: center;
    margin-top: 16px;
    gap: 2rem;

    @include tablet-only() {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px 24px;
    }

    @include phone() {
      flex-direction: column;
      align-items: stretch;
      gap: 1rem;
    }

    > div {
      flex: 1;
      max-width: 316px;

      @include tablet() {
        flex: auto;
        max-width: none;
        width: 100%;
      }
    }

    .cr-select {
      margin: 0;
    }

    .cr-search {
      input {
        padding: 18px 1rem 17px 3.5rem !important;
      }
      .ui-icon {
        color: var(--color-secondary-l2);
        position: absolute;
        left: 1rem;
      }
    }

    .cr-search input,
    .cr-select__input,
    .cr-datepicker {
      height: 56px;
      border-radius: 4px;
    }

    .cr-checkbox {
      margin-top: 0;
      font-size: 20px;
      line-height: 32px;
    }
  }

  &__table-icon {
    font-size: 18px;
    color: var(--color-primary);
  }

  &__table-primary {
    color: var(--color-primary);
  }

  &__loader {
    margin-top: rem(32);
  }

  & &__card-label-info {
    font-size: 20px;
    line-height: 24px;
  }

  .closing-card {
    margin-top: 24px;

    @include phone() {
      margin-top: 1rem;
    }
  }

  .popup-search-table .popup {
    width: 652px;
    top: calc(100% + 8px);
  }

  .selection-info {
    width: 316px;
    margin-left: 32px;
  }

  .popup-search-table {
    .crowd-table {
      max-height: 410px;
      overflow: auto;

      th {
        position: sticky;
        top: 0;
        background-color: #fff;
      }
    }
  }

  .pagination {
    margin-top: 2rem;
    padding-bottom: 0;

    @include tablet-only() {
      margin-top: 24px;
      align-items: center;
      height: 24px;
    }

    @include phone() {
      margin-top: 0px;
    }

    &__page {
      margin: 0 4px;

      @include tablet {
        display: flex;
        align-items: center;
      }

      @include tablet-only() {
        font-size: 16px;
      }

      @include phone() {
        font-size: 18px;
      }
    }

    .directions {
      @include tablet-only() {
        font-size: 34px;
      }

      &:first-child {
        margin-right: 12px;

        @include tablet-only() {
          margin-right: 0px;
        }
      }

      &:last-child {
        margin-left: 12px;

        @include tablet-only() {
          margin-left: 0px;
        }
      }
    }
  }
}
