@import "../../../../../common";

.profile-pic {
  position: relative;
  width: 96px;
  height: 96px;
  cursor: pointer;

  &__img {
    border-radius: 50%;
    max-height: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__overlay {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: all 0.2s;
    transform: scale(0.5);
    opacity: 0;

    &-img {
      width: 100%;
      height: 32px;

      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAiCAMAAAAau2s5AAAAkFBMVEUAru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru9iTbBoAAAAL3RSTlMABgcJDA0OEBgZGyorLFJtbm9/gI6PkJSVmLW2u7zHy8/Q0dTW19ja8/T4+fr8/Rdds7cAAAEJSURBVHgBrdTrboIwAMXxUzZRESpeRKdblTmQy7Dv/3Y7kaRhZG2axf+H5pD8ElMv4ndrbVrDEZ2XpPOSdE4pUlVp0waPNtpUqVSARRc9iG4s2SUCxNj9KfMAy5GzyCUUz9McjuYnEoWWJ51TkjTQDIMW26JpvrYLDNJsBMX+rh/d315cULxr04dwwIMetLfDuOPDTYahvHF0sRXuuMsp2LTk3FlhwS37KTkLK2y5w36+cra+sPJ96avzMkV/mYIzs8Kk40MpJxNZcnSJ5xt+8P4In/GlYHFWftfXLAHc0PRv6PNTmJFUUDyPM5eLjiQKqfYqRZD7uM/A/KU4yyMwsTrXLlWfVwL4AQjgczXyoz9iAAAAAElFTkSuQmCC);
      background-repeat: no-repeat;
      background-position: center;
    }

    &-text {
      text-align: center;
      color: var(--base-bright-blue);
    }
  }
}

@include min(550) {
  .profile-pic:hover {
    .profile-pic__overlay {
      background: rgba(235, 235, 235, 0.9);
      transform: scale(1);
      opacity: 1;
    }
  }
}
