@import "../../../../common.scss";

.my-comments {
  .comment-card {
    &__stats {
      display: none;
    }

    &__block {
      padding-left: 0;
    }
  }
  @include phone {
    &__panel {
      display: flex;
      justify-content: center;
      // .cr-datepicker {
      //   .PickerFrom,
      //   .PickerTo {
      //     width: 127px;
      //   }
      //   .PickerTo {
      //     .DayPickerInput-Overlay {
      //       left: -143px;
      //     }
      //   }
      // }
    }
  }
}
