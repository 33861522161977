@import "../../../../common.scss";

.profile-blog-card {
  &__body {
    margin-top: rem(12px);

    img {
      border-radius: 4px;
    }
  }

  &__footer {
    margin-top: rem(24px);
    display: flex;
    align-items: center;
  }

  &__buttons-icon {
    &:not(:last-child) {
      margin-right: rem(24px);
    }
  }

  .ui-icon-edit,
  .ui-icon-delete {
    font-size: rem(24px);
    color: var(--base-gray);
    cursor: pointer;
  }

  &__attachments {
    display: flex;
    margin-top: rem(24px);
  }
}
