@import "../../../../common.scss";

$background-color: #DBF4FF;
$primary-color: #65AAE0;
$resetHover-color: #1473E6;
$resetHover-bgcolor: #65AAE01F;

.voting-filter__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: $background-color;
  padding: 48px;
  margin-top: -6px;
  margin-bottom: 32px;

  @include tablet() {
    padding: 24px;
    width: 100vw;
    margin-left: -50vw;
    position: relative; 
    left: 50%
  }

  @include phone() {
    margin-top: 0;
    margin-bottom: 16px;
  }

  .cr-select {
    width: 100%;
    margin-top: 16px;
    background-color: $background-color;
    @include tablet() {
      margin-top: 0;
    }

    &__label {
      font: bold rem(18px) PT Sans;    
      margin-bottom: 16px;
      color: #282828;
    }

    &__input {
      background-color: white;
      
      &-list {
        font-weight: bold;

        &-item {
          padding: 8px 0 8px 24px;
          color: #0C1014;

          &:first-child {
            margin-top: 16px;
          }

          &:last-child {
            margin-bottom: 16px;
          }
        }
      }
    }

    .ui-icon-arrow-slider-left {
      margin-right: 12px;
    }
  }
}

.buttons__container {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;

  @include phone() {
    flex-direction: column-reverse;
    margin-top: 16px;
  }

  button {
    margin-top: 24px;
    text-transform: none;
    font-weight: bold;
    border-radius: 6px;
    display: inline-block;

    @include phone {
      margin-top: 0;
    }

    &.inactive {
      background-color: rgba($primary-color, 0.6);
      color: white;
    }

    &.filter__button--reset {
      color: $primary-color;
      background-color: $background-color;

      @include phone() {
        margin-bottom: 8px;
      }

      &:hover {
      color: $resetHover-color;
      background-color: $resetHover-bgcolor;
      }
    }
  }
}

  