@import "../../../../common.scss";

.user-blocking-form {
  &__comment {
    margin-bottom: rem(32px);
  }

  &__options {
    display: flex;
    > div {
      flex-grow: 1;
      @include phone() {
        display: flex;
        flex-direction: column;
      }
    }

    &__radio-group-label {
      font: normal normal bold rem(18px) / rem(28px) PT Sans;
      letter-spacing: 0px;
      color: var(--base-text);
    }
  }
}
