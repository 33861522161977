@import "./../../../../common";

.logged-in {
  display: flex;
  align-items: center;
  color: var(--base-blue);
  margin-left: rem(32);

  &-avartItem {
    margin-left: rem(26);
    margin-right: rem(38);
  }

  &-exit {
    margin-left: rem(26);
  }
}
