@import "../../../../common.scss";

.closing-card {
  position: relative;
  padding: 48px;
  color: var(--base-text);
  border-radius: 4px;
  font-size: 0;

  & > * {
    font: normal normal normal 20px/24px PT Sans;
  }

  @include tablet-only() {
    padding: 24px;
  }

  @include phone() {
    padding: 20px 16px;
  }

  &_blue {
    background-color: var(--color-primary-l1);
  }
  &_gray {
    background-color: var(--color-secondary-l6);
  }

  &_small {
    padding: 16px 24px;
  }

  label {
    font: normal normal bold 18px/28px PT Sans;
    letter-spacing: 0px;
    display: block;

    @include tablet-only() {
      line-height: 21px;
    }

    @include phone() {
      font-size: 16px;
      line-height: 20px;
    }

    &:not(:first-of-type) {
      margin-top: 24px;

      @include tablet-only() {
        margin-top: 16px;
      }

      @include phone() {
        margin-top: 1.25rem;
      }
    }
  }

  .cr-input,
  .cr-select {
    margin-top: 16px;

    @include tablet-only() {
      margin-top: 8px;
    }

    @include phone() {
      margin-top: 16px;
    }
  }

  & &__close-btn {
    padding: 0;
    position: absolute;
    top: 24px;
    right: 24px;

    @include phone() {
      top: 17px;
      right: 16px;
    }

    .ic {
      margin: 0;
      font-size: 24px;
      line-height: 24px;
      color: var(--color-secondary-l2);

      @include phone() {
        font-size: 24px;
      }
    }
    &:hover {
      .ic {
        color: var(--color-primary);
      }
    }
  }
}
