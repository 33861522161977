@import "../../../../common.scss";

.cr-timepicker {
  border: 1px solid #65aae0;
  border-radius: 4px;
  padding: rem(10px);
  font: normal normal normal rem(18px) / rem(24px) PT Sans;
  letter-spacing: 0px;
  position: relative;
  padding: rem(25px) 0 rem(5px) rem(10px);

  input {
    border: none;
    outline: none;
  }

  &__title {
    font: normal normal normal rem(14px) / rem(24px) PT Sans;
    letter-spacing: 0;
    color: var(--textarea-hint-color);
    position: absolute;
    top: 0;
  }
}
