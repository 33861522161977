@import "./common";
@import "./svg-icons";
@import "./assets/fonts/bebasneue/bebasneue.css";
@import "./assets/fonts/Intro2012/stylesheet.css";
@import "./assets/fonts/PTSansWeb/stylesheet.css";
@import "./assets/fonts/Roboto/stylesheet.css";
@import "~swiper/swiper";
@import "./theme.scss";
@import "./assets/icons/platform-icons/style.css";
@import "./assets/icons/platform-icons-thin/style.css";
@import "../src/components/presentational/Cards/PostCard.scss";
@import "~video-react/styles/scss/video-react";

:root {
  --base-white: #ffffff;
  --base-text: #282828;
  --base-border: #dedede;
  --base-blue: #65aae0;
  --base-ligth-blue: #eefbff;
  --base-bright-blue: #00aeef;
  --base-dark-blue: #1473e6;
  --base-ligth-gray: #f2f4f7;
  --base-gray: #7e7e7e;
  --base-purple: #a69cef;
  --base-red: #ff6677;
  --base-pink: #ff5f76;
  --base-yellow: #ffc38d;
  --base-gold: #ffb867;
  --base-orange: #ff9425;
  --header-height: 81px;
  --footer-height: 220px;
  --header-height-tablet: 59px;
  --header-height-phone: 65px;
}

body > form {
  position: fixed !important;
}

body > iframe {
  display: none;
}

a {
  outline: none;
}

.stage-body-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cr-error {
  color: var(--base-red);
}

.react-toast-notifications__container {
  z-index: 2000 !important;
}

.role-icon {
  display: block;
  flex-shrink: 0;
  height: 18px;
  width: 18px;
  background-position: center;
  margin-left: 8px;

  &.chief-expert-icon {
    background: url("./assets/icons/chief-expert-icon.png") 0 0 / contain no-repeat;
  }

  &.project-boss-icon {
    background: url("./assets/icons/project-boss-icon.png") 0 0 / contain no-repeat;
  }
}

.white-block {
  position: relative;
  background: white;
  @include afterBeforeBackground(white);
}

textarea,
input,
button,
select {
  font-family: inherit;
  font-size: inherit;
}

blockquote {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--base-text);
  padding: rem(16) rem(32) rem(16) rem(8);
  margin: rem(16) 0;
  background: var(--base-ligth-gray);
  border-left: 5px solid #f1f1f1;

  &::before {
    content: "\e930";
    font-family: "platform-icons" !important;
    margin-right: rem(16);
    font-size: 24px;
    color: var(--base-gray);
  }
}

.app-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &-white {
    background-color: white;
  }

  &-lightblue {
    background-color: var(--base-ligth-blue);
  }

  &-lightgray {
    background-color: var(--base-ligth-gray);
  }

  &-darkblue {
    background-color: var(--color-primary);
  }
}

.app {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  max-width: 1408px;
  margin: 0 auto;

  &__widen {
    max-width: none;
    padding: var(--header-height) 0 0;
    @include tablet() {
      padding-top: var(--header-height-phone);
      .profile-page .profile-header {
        padding-left: rem(24);
        padding-right: rem(24);
      }
    }
  }

  &__lessen {
    max-width: 1408px;
    padding: var(--header-height) rem(24) 0;

    @include tablet() {
      padding-top: var(--header-height-phone);
    }

    @include phone() {
      padding-left: 0;
      padding-right: 0;
    }
  }

  > section {
    min-height: calc(100vh - var(--header-height) - var(--footer-height));
  }

  &__text {
    ol,
    ul {
      font: normal normal normal 20px/32px PT Sans;
      color: var(--base-text);
      margin: rem(24) 0;
      list-style-type: none;
    }

    ol {
      padding-left: rem(36);
      counter-reset: item;

      > li {
        margin: 0;

        ul {
          margin: 0;
        }

        &:before {
          content: counters(item, ".") ".";
          counter-increment: item;
          color: var(--base-text);
          margin-right: rem(23);
          margin-left: -35px;
          display: block;
          float: left;
        }
      }
    }
    ul {
      padding-left: 16px;
      li {
        margin: 0;
        position: relative;
        &:after {
          content: "";
          background: var(--base-blue);
          border-radius: 100%;
          width: 5px;
          height: 5px;
          display: block;
          position: absolute;
          top: 15px;
          left: -16px;
        }
      }
    }
  }

  &__loader {
    position: fixed;
    top: calc(50% - 45px);
    left: calc(50% - 45px);
  }

  iframe {
    width: 100%;
    height: 500px;
    margin-top: 20px;
  }
}

.simple-btn {
  background: transparent;
  border: none;
  cursor: pointer;
}

.disabled-btn {
  color: var(--color-secondary-l5) !important;
  pointer-events: none;
}

.divider {
  border-bottom: 1px solid #dedede;
}

.empty-content {
  font: normal normal normal rem(16) / rem(32) PT Sans;
  text-align: center;
  margin: 10rem;
  @include phone() {
    padding: 0 rem(16);
    margin: 0;
    margin-top: rem(22);
    font: normal normal normal rem(16) / rem(20) PT Sans;
  }
}

.app-editor__root {
  // Редактирование проходит через старый ckeditor который не поддерживает эти контролы
  .rdw-editor-toolbar {
    div[title="Подчеркивание"],
    div[title="Зачеркивание"],
    div[title="Верхний индекс"],
    div[title="Нижний индекс"],
    div[title="Удалить"],
    div[class="rdw-fontsize-wrapper"],
    div[class="rdw-fontfamily-wrapper"],
    div[title="Monospace"],
    .rdw-link-modal-target-option,
    .rdw-image-modal-size {
      display: none;
    }
  }

  .rdw-block-dropdown {
    width: 160px;
  }

  .rdw-link-modal {
    height: fit-content;
  }

  .rdw-embedded-modal-size {
    display: none;
  }

  .public-DraftStyleDefault-block {
    margin: 0;
  }
}

p[bbcode="right"] {
  text-align: right;
}

p[bbcode="left"] {
  text-align: left;
}

p[bbcode="center"] {
  text-align: center;
}

.app-editor {
  height: 290px !important;
  border-radius: var(--editor-wrapper-border-radius);
}

.editor-content {
  img {
    margin-top: rem(32px);
    max-width: 450px;
  }

  ul,
  ol {
    margin-left: rem(32);
  }

  a {
    color: var(--base-blue);
  }
}

.simple-button {
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  height: fit-content;
}

.cr-file-selector__head-button {
  > span {
    display: flex;
    align-items: center;
    @include tablet() {
      font: normal normal bold rem(16) / rem(24) PT Sans;
      i {
        font-size: rem(24);
        margin-right: rem(12);
      }
    }
    @include phone() {
      font: normal normal bold rem(14) / rem(19) PT Sans;
      i {
        font-size: rem(20);
        margin-right: rem(8);
      }
    }
  }
}
