@import "../../../../common";

.logo {
  display: block;
  flex-shrink: 0;
  height: 57px;
  width: 45px;
  background: url("../../../../assets/Logo-b.svg") 0 0 / contain no-repeat;
  background-position: center;
  z-index: 100;
  cursor: pointer;

  @include tablet() {
    height: 42px;
    width: 33px;
  }

  @include phone {
    height: 49px;
    width: 39px;
  }

  &_hidden {
    visibility: hidden;
  }
}
