@import "../../../common.scss";

.participants {
  &__wrapper {
    background-color: white;
    position: relative;
    padding-bottom: rem(180);
    @include afterBeforeBackground(white);

    @include tablet() {
      padding-bottom: rem(148);
    }
    @include phone() {
      padding: 0 rem(16) rem(140);
    }
  }

  &__head {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-start;

    @include tablet {
      flex-direction: column;
    }

    .page-title {
      width: auto;
      margin-top: rem(40);

      @include tablet {
        margin-top: rem(16);
        line-height: rem(39);
      }

      @include phone {
        line-height: rem(27);
      }
    }

    &__searches {
      display: flex;
      margin-left: auto;

      @include tablet() {
        margin-left: unset;
        width: 100%;
      }

      @include phone {
        flex-direction: column-reverse;
      }

      .cr-search {
        margin-top: rem(40);
        max-width: 318px;
        padding: 0 1px;
        color: var(--color-secondary-l2);

        &:not(:first-child) {
          margin-left: rem(32);
        }

        @include tablet() {
          margin-top: rem(16);
          max-width: unset;
          &:not(:first-child) {
            margin-left: rem(24);
          }
        }
        @include phone() {
          &:last-child {
            width: calc(100% - 90px);
          }
          &:not(:first-child) {
            margin-left: unset;
          }
          &__input {
            height: 44px;
            padding-right: 45px !important;
          }
        }

        &__list {
          max-height: 432px !important;
          @include phone() {
            width: auto !important;
            left: 0 !important;
            right: 0 !important;
          }
        }
      }
    }

    div.cr-checkbox {
      position: absolute;
      right: 0;
      width: auto;
      top: rem(92);
      margin-top: 0;

      label {
        align-items: flex-end;
        color: var(--base-text);
        .cr-checkbox__input {
          outline: none;
        }
      }
      @include tablet() {
        top: rem(120);
      }

      @include phone() {
        top: rem(70);
      }
    }
  }

  &__body {
    margin-top: rem(90);

    .pagination {
      margin-top: rem(40);

      @include tablet() {
        margin-top: rem(32);
        font: normal normal normal 16px/24px PT Sans;
      }
      @include phone() {
        font: normal normal normal 14px/19px PT Sans;
        margin-top: rem(24);
      }
    }

    @include tablet() {
      margin-top: rem(70);
    }
    @include phone() {
      margin-top: rem(24);
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: rem(32);
    grid-row-gap: rem(56);
    border-radius: 4px;

    @include tablet() {
      grid-column-gap: rem(56);
      grid-row-gap: rem(24);
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include phone() {
      grid-row-gap: rem(16);
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__user {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    overflow: hidden;

    &-info {
      margin-left: rem(16);
      overflow: hidden;
      @include phone() {
        margin-left: rem(8);
      }
    }

    &-name {
      font: normal normal bold rem(20) / rem(24) PT Sans;
      letter-spacing: 0px;
      color: var(--base-text);

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: auto !important;

      @supports (-webkit-line-clamp: 2) {
        @include multilineEllipsis(2, 100%, 20px, 1.2);
      }

      @include tablet() {
        font: normal normal bold rem(18) / rem(26) PT Sans;
      }
      @include phone() {
        font: normal normal bold rem(16) / rem(22) PT Sans;
      }
    }

    &-date {
      font: italic normal normal rem(18) / rem(24) PT Sans;
      letter-spacing: 0px;
      color: var(--base-gray);
      margin-top: rem(4);

      @include tablet() {
        font: italic normal normal rem(16) / rem(22) PT Sans;
      }
      @include phone() {
        font: italic normal normal rem(12) / rem(18) PT Sans;
      }
    }
    @include phone() {
      .user-avatar__m {
        width: rem(40);
        min-width: rem(40);
        height: rem(40);
        .user-avatar__m {
          width: rem(40);
          min-width: rem(40);
          height: rem(40);
        }
      }
    }
  }

  &__blog {
    margin-top: rem(40);
    @include phone() {
      margin-top: rem(24);
    }
  }

  &__blog-list {
    scroll-margin-top: 81px;
    @include tablet() {
      scroll-margin-top: 65px;
    }

    .comment-card {
      margin-top: rem(40);

      @include tablet() {
        margin-top: rem(24);
      }
      @include phone() {
        margin-top: rem(16);
      }
    }
  }

  .p-blog-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: rem(32) rem(68);
    border: 1px solid var(--color-primary);
    border-radius: 4px;

    &__title {
      font: normal normal normal rem(20) / rem(32) PT Sans;
      letter-spacing: 0;
      color: var(--base-text);

      @include tablet() {
        font: normal normal normal rem(18) / rem(24) PT Sans;
      }
      @include phone() {
        font: normal normal normal rem(16) / rem(20) PT Sans;
        margin-bottom: rem(16);
      }
    }

    @include tablet() {
      padding: rem(24);
    }
    @include phone() {
      padding: rem(16);
      flex-direction: column;
      margin: 0 rem(16);
    }
  }

  &__footer {
    position: relative;
    margin-top: rem(-125);

    .pagination {
      margin-top: rem(34);
      li {
        color: white;
      }
      @include tablet() {
        margin-top: rem(24);
      }
      @include phone() {
        margin-top: rem(26);
      }
    }
  }

  &__ftitle {
    font: normal normal bold rem(28) / rem(32) PT Sans;
    letter-spacing: 0;
    color: var(--base-text);
    @include tablet() {
      font: normal normal bold rem(26) / rem(33) PT Sans;
    }
    @include phone() {
      padding: 0 rem(16);
      font: normal normal bold rem(20) / rem(26) PT Sans;
    }
  }

  &__search-icon {
    position: absolute;
    right: rem(12);
    cursor: pointer;

    &_close {
      position: absolute;
      right: rem(44);
      cursor: pointer;
      font-size: 16px;
    }
  }

  .ui-icon-chevron-up {
    color: var(--base-blue);
  }
}
