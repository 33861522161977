@import "../../../../common";

.personal-user {
  padding: rem(24) 0;
  background: var(--base-ligth-gray);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 112px;
  @include afterBeforeBackground(var(--base-ligth-gray));

  &__user {
    display: flex;
    align-items: center;

    &__info {
      display: flex;
      flex-direction: column;
      margin: 0 rem(16);
      @include phone() {
        margin: 0 rem(8);
      }
    }

    &__name {
      display: flex;
      font: normal normal bold rem(20) / rem(24) PT Sans;
      color: var(--base-text);
      @include phone() {
        font: normal normal bold rem(16) / rem(22) PT Sans;
      }
    }

    &__place {
      font: italic normal normal rem(18) / rem(24) PT Sans;
      color: var(--base-gray);
      @include phone() {
        font: italic normal normal rem(12) / rem(18) PT Sans;
      }
    }
    @include phone() {
      .user-avatar__m {
        width: rem(40);
        min-width: rem(40);
        height: rem(40);
      }
    }
  }

  &__controls {
    display: flex;
    align-items: center;

    &__send {
      display: flex;
      color: var(--color-secondary-l2);

      &__separator {
        margin-right: rem(24);
      }
    }

    &__tabs {
      margin-left: rem(32);
    }
    @include phone() {
      &__white_line {
        position: absolute;
        width: 100vw;
        height: 16px;
        background-color: #fff;
        left: -16px;
        top: rem(40);
      }
    }
  }
  @include phone() {
    margin: 0;
    padding: rem(16);
    position: relative;
  }
}

@include wide-tablet {
  .personal-user {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__controls {
      display: flex;
      flex-direction: row-reverse;
      align-items: baseline;
      justify-content: space-between;
      width: 100%;
      position: relative;

      &__tabs {
        margin-top: rem(24);
        margin-left: 0;
        @include phone() {
          width: 100%;
          margin-top: rem(30);
        }
      }

      &__send {
        position: relative;
        top: rem(4);
      }
    }
  }
}

@include adaptive(715) {
  .personal-user {
    &__controls {
      flex-wrap: wrap;
      flex-direction: row;
      &__send {
        margin-left: rem(80);
      }
    }
  }
}

@include phone() {
  .personal-user {
    &__controls {
      flex-direction: column;
      &__send {
        margin-left: rem(48);
      }
    }
  }
}
