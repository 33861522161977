@import "../../../../common";

.warning-modal {
  .warning-items {
    display: none;
  }

  @include phone() {
    .warning-table {
      display: none;
    }

    .warning-items {
      display: block;
    }
  }

  @include phone() {
    .warning-items {
      &__no-warnings-msg {
        font: normal normal normal rem(14) / rem(19) PT Sans;
      }
    }
    &__footer {
      flex-direction: column-reverse;
    }
  }
}
