@import "../../../../common.scss";

.cr-back-button {
  position: relative;
  display: flex;
  align-items: center;
  font: normal normal bold rem(18) / rem(28) PT Sans;
  letter-spacing: 0;
  color: var(--color-primary);
  text-decoration: none;
  text-transform: initial;
  margin: rem(26) 0;
  cursor: pointer;
  width: fit-content;

  &:hover {
    color: #00aeef;
    outline: none;
  }

  &__link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__icon {
    height: 100%;
    margin-right: rem(15);
    font-size: 20px;

    @include phone() {
      font-size: 20px;
      margin-right: rem(10);
    }
  }

  &__text {
    white-space: nowrap;

    @include tablet() {
      font: normal normal bold 16px/24px PT Sans;
    }

    @include phone() {
      font: normal normal bold 14px/19px PT Sans;
    }
  }

  @include tablet() {
    font: normal normal bold rem(16) / rem(24) PT Sans;
  }
}
