@import "../../../../common";

.main__actions-stat {
  display: flex;
  width: 100%;
  justify-content: space-around;
  color: var(--base-blue);

  @include phone() {
    justify-content: space-evenly;
  }

  .stats-block {
    display: flex;
    align-items: center;

    @include short-desktop() {
      margin-left: rem(32);
    }

    @include phone() {
      margin-left: 0;
    }

    &__number {
      font: normal normal bold rem(20) / rem(24) PT Sans;
      color: #221f20;
      margin-left: 0.5rem;
    }
  }
}
