@import "../../../../common.scss";

@mixin disable() {
  opacity: 0.3;
  pointer-events: none;
}

.submit-form {
  &__subhead {
    width: 100%;
  }

  &__footer {
    .cr-button {
      margin-left: rem(16);
      @include phone() {
        margin-left: 0;
        &:not(:last-child) {
          margin-bottom: rem(12);
        }
      }
    }
  }

  &__submitting {
    .submit-form__body {
      @include disable();
    }
    .submit-form__footer {
      @include disable();
    }
  }
}
