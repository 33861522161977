@import "../../../../common";

.react-responsive-modal-root {
  .node-modal {
    &__body {
      font: normal normal normal rem(20) / rem(32) PT Sans;
      color: var(--base-text);
    }

    a {
      color: var(--base-blue);
    }
  }
}
