@import "../../../common.scss";

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column-reverse;
  align-items: flex-start;
  font: 16px/24px PT Sans;
  letter-spacing: 0.15px;
  color: #888888;
  margin-top: 100px;
  margin-bottom: 20px;

  @include adaptive(1024) {
    justify-content: unset;
    padding: 0 6px;
  }

  @include tablet() {
    flex-direction: column;
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 80px;
  }

  @include phone() {
    margin-top: 56px;
  }

  &-gray {
    color: #888888;

    .footer__link {
      color: #888888;

      &:visited,
      &:link,
      &:focus {
        color: #808080 !important;
      }

      &:hover,
      &:active {
        color: #00adee !important;
      }
    }
  }

  &-white {
    color: #ffffff;

    .footer__link {
      color: #ffffff;

      &:visited,
      &:link,
      &:focus {
        color: var(--base-ligth-blue) !important;
      }

      &:hover,
      &:active {
        color: var(--base-text) !important;
      }
    }
  }

  &__links {
    text-align: start;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    @include tablet() {
      flex-direction: row;
      padding: 0 10px 15px 10px;
      margin: 0;
      width: fit-content;
    }

    @include phone() {
      flex-direction: column;
    }
  }

  &__info {
    display: flex;
    flex-wrap: wrap;

    @include tablet() {
      align-items: flex-start;
      flex-direction: row;
    }
  }

  &__social {
    align-self: flex-start;
    margin-top: -12px;
    text-align: center;
    display: flex;
    justify-content: center;

    @include tablet() {
      display: contents;
      width: 100%;
      margin-top: 0;
    }

    .social-network {
      .svg-icon {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__link {
    text-align: start;
    color: #8a8a8a;
    cursor: pointer;
    line-height: 24px;
    width: fit-content;
    text-decoration: none !important;
    font: normal normal normal 16px/32px PT Sans;

    @include tablet() {
      font: normal normal normal 14px/20px PT Sans;
      letter-spacing: 0px;
      margin: 4px 0;
      line-height: unset;
      width: fit-content;
    }

    @include phone() {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font: normal normal normal 14px/19px PT Sans;
      letter-spacing: 0px;
      padding: 0 0 12px;
      border-right: none;
      width: 100%;
      text-align: center;
      margin: 0;
    }

    &:nth-last-child(2) {
      border-right: none;

      @include adaptive(1190) {
        padding-left: 0;
      }

      @include tablet() {
        border-bottom: none;
      }
      @include phone() {
        padding-left: rem(20);
      }
    }

    &:visited,
    &:link,
    &:focus {
      color: #808080 !important;
    }

    &:hover,
    &:active {
      color: #00adee !important;
    }

    &-help {
      @include tablet() {
        order: 1;
      }

      @include phone() {
        order: 1;
      }
    }

    &-user-agreement {
      order: 2;
    }

    &-use-agreement {
      order: 3;
    }

    &-data-agreement {
      order: 4;
      padding-left: 0;
    }
  }

  .link-divider {
    border-right: 2px solid #ccc;
    height: 100%;
    padding-left: 20px;
    margin-right: 20px;

    @include phone() {
      border-bottom: 1px solid #ccc;
      width: 100%;
      border-right: 0;
      padding-left: 0;
      margin-right: 0;
      padding-top: 12px;
      margin-right: 12px;
    }
  }

  &-link {
    order: 1;
  }

  &__copyright {
    text-align: end;
    font: normal normal normal 16px/32px PT Sans;
    margin-right: 20px;

    @include adaptive(1024) {
      margin-right: 0;
    }

    @include tablet() {
      display: none;
    }

    &_mobile {
      display: none;

      @include tablet() {
        font: normal normal normal 14px/20px PT Sans;
        letter-spacing: 0px;
        width: 100%;
        display: block;
        padding: 10px 20px;
        margin: 0;
        padding: 4px 0;
        order: 0;
      }

      @include phone() {
        font: normal normal normal 14px/19px PT Sans;
        letter-spacing: 0px;
        text-align: center;
        margin-bottom: 12px;
      }
    }
  }
}
