@import "../../../../common.scss";

.after-register-form {
  &__root {
    z-index: 1002 !important;
  }

  &__body {
    @include phone() {
      margin-top: 0;
    }
  }
}

.after-registration-form {
  &__title {
    color: #7e858c;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include phone() {
      margin-right: rem(32);
      p:first-of-type {
        margin-bottom: rem(16);
      }
    }
  }

  &__body {
    .profile-pic {
      margin: 20px auto;
      width: 100%;
      height: 100%;
      max-width: 140px;
      max-height: 140px;
    }

    &-disabled {
      pointer-events: none;
      opacity: 0.4;
    }

    &-radios {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: rem(24);

      &-title {
        margin-right: 40px;
        font: normal normal normal 16px/28px PT Sans;
        letter-spacing: 0.44px;
        width: 100%;
      }
    }
    .cr-select {
      .cr-select__placeholder {
        .ui-icon-arrow-slider-left {
          right: 20px;
          top: 22px;
          font-size: 16px;
        }
      }
    }
  }

  &__errors {
    margin-top: 20px;
    text-align: center;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;

    @include phone() {
      flex-direction: column-reverse;
    }

    .cr-button {
      margin-left: rem(20);
      @include phone() {
        margin-bottom: rem(20);
        margin-left: 0;
      }
    }
  }

  a {
    color: var(--base-blue);
    text-decoration: underline;
  }
}

.after-registration-modal {
  padding: 30px;

  @include phone() {
    max-width: unset !important;
  }

  // #close-modal-icon {
  //   display: none;
  // }
}

.sudir-img {
  margin: 0 5px 0 10px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RENENTM3Rjk1NDYyMTFFOUFBMzJGMUNBQkRDQUFCREUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RENENTM3RkE1NDYyMTFFOUFBMzJGMUNBQkRDQUFCREUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpEQ0Q1MzdGNzU0NjIxMUU5QUEzMkYxQ0FCRENBQUJERSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpEQ0Q1MzdGODU0NjIxMUU5QUEzMkYxQ0FCRENBQUJERSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PqASqC4AAAB1SURBVHjaYvz//z/DW3kuBiD4z0AZYAQRTAxUBiy4bCIBoPiM6i4cNXDUwFEDB9zA50iZHRs+CsRfsIiDwAtsBqYgS2ABVkDMjUX8KRAnw4sqpAIWX/F0E4grgHgfEH8itTxEByDDLIH4PbUipZJYw0AAIMAAaxgiEfFml/AAAAAASUVORK5CYII=");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
}
