.switcher {
  .cr-checkbox {
    label {
      align-items: center;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0;
    }
    input[type="checkbox"] {
      position: relative;
      width: 34px;
      height: 14px;
      border: none;
      background-color: var(--color-secondary-l8);
      border-radius: 10px;
      margin-right: 16px;
      &:before {
        content: "";
        position: absolute;
        background: white;
        width: 20px;
        height: 20px;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        border-radius: 50%;
        box-shadow: 0 1px 2px 1px var(--color-secondary-l4);
      }
      &:checked {
        background-color: var(--color-primary-l1);
        &:before {
          left: 14px;
          background-color: var(--color-primary);
        }
      }

      &,
      &:before {
        transition: all 0.2s ease-out 0s;
      }
    }
  }
}
