@import "../../../../common.scss";

.maintenance-modal {
  .went-wrong {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: fit-content;
    background-color: #ffffff;
    padding: 20px;

    b {
      color: #282828;
      margin-top: 30px;
      font: normal normal bold 16px/24px PT Sans;
      letter-spacing: 0.13px;
    }

    img {
      width: 170px;
      height: 170px;
    }

    p {
      color: #282828;
      font: normal normal normal 16px/24px PT Sans;
      letter-spacing: 0.13px;
      text-align: center;
    }
  }
}
