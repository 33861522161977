@import "../../../../../common.scss";

.selection-idea-info {
  &,
  ol {
    font: normal normal normal 20px/24px PT Sans;

    @include tablet-only() {
      font-size: 18px;
      line-height: 24px;
    }

    @include phone() {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__title {
    font-weight: bold;
    line-height: 32px;

    @include tablet-only() {
      font-size: 20px;
    }

    @include short-desktop() {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    @include phone() {
      gap: 4px;
      line-height: 20px;
    }
  }

  &__main-mark {
    font-size: 24px;
    color: var(--base-dark-blue);

    @include phone {
      font-size: 19px;
    }
  }

  &__date {
    margin-top: 0.25rem;
    font-style: italic;
    color: var(--color-secondary-l2);

    @include tablet-only() {
      font-size: 16px;
      margin-top: 0;
      line-height: 20px;
    }

    @include phone() {
      font-size: 14px;
      line-height: normal;
    }
  }

  &__list {
    list-style: none;
    display: table;
    border-collapse: collapse;

    @include tablet-only() {
      border: none;
    }

    @include phone() {
      border: none;
    }

    li {
      white-space: pre-wrap;
      border-top: 32px solid transparent;
      counter-increment: selectionIdeaInfoInc;
      display: table-row;

      @include tablet-only() {
        border-top-width: 20px;
      }

      @include phone() {
        border-top-width: 13px;
      }

      &:before {
        content: counter(selectionIdeaInfoInc) ".";
        display: table-cell;
        text-align: right;
        padding-right: 0.75rem;
        font-weight: bold;

        @include tablet() {
          padding-right: 0.5rem;
        }

        @include phone() {
          padding-right: 12px;
        }
      }

      > * + * {
        margin-top: 4px;

        @include tablet() {
          margin-top: 2px;
        }

        @include phone() {
          margin-top: 0;
          line-height: 20.2px;
        }
      }
    }

    &_alone {
      li:before {
        display: none;
      }
    }
    a {
      cursor: pointer;
      color: var(--base-blue);
    }
  }

  & &__tag {
    margin-top: 32px;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-secondary-l2);
    display: flex;
    align-items: center;

    @include tablet-only() {
      margin-top: 16px;
    }

    @include phone() {
      font-size: 14px;
      margin-top: 12px;
    }

    .ic {
      font-size: 24px;
      margin-right: 12px;

      @include phone() {
        font-size: 19px;
      }
    }

    &__edit {
      padding: 0;
      margin-left: 12px;

      .ic {
        color: var(--color-secondary-l2);
        font-size: 20px;
        line-height: 1;
        margin: 0;

        @include phone() {
          font-size: 18px;
        }
      }

      &:hover {
        .ic {
          color: var(--color-primary);
        }
      }
    }
  }

  &__attachments {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;

    @include tablet-only() {
      margin-top: 24px;
      gap: 16px;
    }

    @include phone() {
      margin-top: 1rem;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      width: calc(100%);
    }

    .attachment {
      margin: 0 20px 20px 0;

      @include tablet-only() {
        margin: 0 !important;
      }

      @include phone() {
        margin: 0 !important;
        box-shadow: border-box;
        flex: 0 0 calc(50% - 4px);
      }

      &__content {
        @include tablet-only() {
          width: 120px;
          height: 90px;
        }

        @include phone() {
          width: 100%;
          height: 105px;
        }
      }
    }
  }

  .proposal-history {
    margin-top: 32px;

    @include tablet-only() {
      margin-top: 24px;
    }

    @include phone() {
      margin-top: 20px;
    }
  }

  .closing-card {
    margin-top: 12px;
  }
}
