@import "./../../../../common";

.logged-out {
  display: flex;

  span {
    cursor: pointer;
  }
  a {
    color: var(--base-blue);
    margin-left: rem(24);
    vertical-align: middle;
  }
}
