@import "../../../../common";

.add-comment {
  width: 100%;

  .app-editor {
    padding: rem(16);
    border-radius: 4px;
  }

  &__loader {
    width: 100%;
    margin-top: rem(42);
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin-top: rem(16);

    &__icon-btns {
      display: flex;

      .ui-icon-link {
        position: relative;
      }
    }

    .cr-button {
      &:not(:first-child) {
        margin-left: rem(16);
      }
    }
  }

  &__error {
    color: var(--base-red);
    margin: var(--textarea-hint-margin);
    letter-spacing: var(--textarea-hint-letter-spacing);
    font: var(--textarea-hint-font);
  }

  &__icon {
    color: var(--base-gray);
    margin-left: rem(24);
  }

  blockquote {
    max-width: calc(100% - 1rem);
  }

  @include phone() {
    &__buttons {
      flex-direction: column;
      margin-top: 0;

      .cr-button-outlined {
        margin-top: rem(12);
      }

      .add-comment__buttons__icon-btns {
        margin-top: rem(20);
        .add-comment__icon {
          margin-left: 0;
          &:not(:last-child) {
            margin-right: rem(20);
          }

          i {
            font-size: rem(20);
            line-height: rem(20);
          }
        }
      }

      button.cr-button.cr-button-outlined {
        margin-left: 0;
      }
    }
  }
}
