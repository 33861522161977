@font-face {
  font-family: "platform-icons-thin";
  src: url("platform-icons-thin.ttf?oxdbix") format("truetype"),
    url("platform-icons-thin.woff?oxdbix") format("woff"),
    url("platform-icons-thin.svg?oxdbix#platform-icons-thin") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ui-thin-icon-"],
[class*=" ui-thin-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "platform-icons-thin" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui-thin-icon-report:before {
  content: "\e900";
}
.ui-thin-icon-tune:before {
  content: "\e901";
}
.ui-thin-icon-users:before {
  content: "\e902";
}
.ui-thin-icon-done:before {
  content: "\e903";
}
.ui-thin-icon-comment:before {
  content: "\e911";
}
.ui-thin-icon-comment-reply:before {
  content: "\e912";
}
.ui-thin-icon-favorite:before {
  content: "\e91d";
}
.ui-thin-icon-idea:before {
  content: "\e921";
}
.ui-thin-icon-mail:before {
  content: "\e92c";
}
.ui-thin-icon-record:before {
  content: "\e932";
}
.ui-thin-icon-note:before {
  content: "\e904";
}
.ui-thin-icon-close-thin:before {
  content: "\e905";
}
.ui-thin-icon-push:before {
  content: "\e906";
}
.ui-thin-icon-mount:before {
  content: "\e907";
}
.ui-thin-icon-dialog:before {
  content: "\e908";
}
.ui-thin-icon-console:before {
  content: "\e909";
}
.ui-thin-icon-docs:before {
  content: "\e90a";
}
.ui-thin-icon-medal:before {
  content: "\e90b";
}
.ui-thin-icon-arrow-right-black:before {
  content: "\e90c";
}
.ui-thin-icon-trashic:before {
  content: "\e90d";
}
.ui-thin-icon-tune1:before {
  content: "\e90e";
}
.ui-thin-icon-info:before {
  content: "\e924";
}
.ui-thin-icon-43:before {
  content: "\e90f";
}
