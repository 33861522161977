@import "../../../../common";

.team-login-form {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  background-color: #fff;
  font-size: rem(14);
  padding: rem(30);
  box-shadow: 0px 1px 3px #0000000f;

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: rem(20);
    font-weight: 700;
  }

  .logo {
    margin-left: 0;
  }
}

.link-blue {
  color: var(--base-blue);
}

.link-blue:hover {
  color: var(--base-bright-blue)
}

.team-login-error {
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .team-login-form {
    &__footer {
      flex-direction: column;

      :not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}