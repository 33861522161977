@import "../../../../../common.scss";

.selection-forwarded-show-all {
  margin-bottom: 32px;
  margin-top: 16px;

  @include tablet-only {
    margin-top: 16px;
    margin-bottom: 0;
  }

  @include phone {
    margin-top: 12px;
    margin-bottom: 16px;
  }

  .cr-checkbox {
    margin-top: 0;
    font-size: 20px;

    @include tablet-only {
      font-size: 18px;
    }

    @include phone {
      font-size: 16px;
    }

    label {
      align-items: center;
    }

    &__input {
      margin: 3px 19px 3px 3px;
      padding: 3px;
    }

    span {
      line-height: 24px;
    }
  }
}
