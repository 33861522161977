@import "../../../../../../common";

.personal-post-card {
  &.post-card {
    @include phone() {
      padding: 1rem;
    }
  }
  &__title {
    display: flex;
    justify-content: space-between;
    font: normal normal bold rem(20) / rem(32) PT Sans;
    color: var(--base-text);

    @include phone() {
      font: normal normal bold rem(16) / rem(22) PT Sans;
    }
  }

  &__content {
    margin-top: rem(12);
    font: normal normal normal rem(20) / rem(32) PT Sans;
    color: var(--base-text);
    p,
    a {
      @include tablet() {
        font: normal normal normal rem(18) / rem(24) PT Sans;
      }
      @include phone() {
        font: normal normal normal rem(16) / rem(20) PT Sans;
      }
    }

    img {
      border-radius: 4px;
    }
  }

  &__created {
    font: italic normal normal rem(18) / rem(24) PT Sans;
    color: var(--base-gray);

    @include phone() {
      font: italic normal normal rem(14) / rem(18) PT Sans;
      margin-top: rem(5);
    }
  }

  &__read {
    margin-top: rem(36);

    @include tablet() {
      margin-top: rem(24);
    }
    @include phone() {
      margin-top: rem(16);
      button {
        border: 0;
        font: normal normal bold rem(16) / rem(20) PT Sans;
        width: auto;
        padding: 0;

        &:hover {
          background-color: transparent;
        }
        &:active {
          color: var(--color-primary-d1);
          background-color: transparent;
        }
      }
    }
  }
}
