@import "../../../../../common.scss";

.profile-notifications {
  @include phone() {
    padding: 0 rem(16);

    .swiper-wrapper {
      .swiper-slide {
        max-width: unset;
      }
    }
  }

  .notification {
    height: 221px;
    background-color: var(--color-primary);
    color: white;
    position: relative;
    padding: rem(16) rem(24);
    border-radius: 4px;

    @include tablet() {
      height: 181px;
    }

    @include phone() {
      background: #dbf4ff 0% 0% no-repeat padding-box;
      color: var(--text-mobile-font);
      padding: rem(16);
      margin-top: rem(12);
      height: 240px;

      &.blank {
        display: flex;
        flex-direction: column;
        min-height: 219px;
        .notification__head {
          font: normal normal bold rem(20) / rem(26) PT Sans;
        }
        .notification__empty {
          margin: auto;
        }
      }
    }

    &__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include phone() {
        flex-direction: column-reverse;
        align-items: flex-start;
        width: 100%;
      }

      &-title {
        font: normal normal bold rem(24) / rem(32) PT Sans;
        letter-spacing: 0;
        color: white;
        display: inline;
        position: relative;

        @include tablet() {
          font: normal normal bold rem(22) / rem(29) PT Sans;
        }
        @include phone() {
          margin-top: rem(12);
          font: normal normal bold rem(20) / rem(26) PT Sans;
          color: var(--text-mobile-font);
        }
      }

      &-counter {
        background: var(--base-orange) 0 0 no-repeat padding-box;
        border-radius: 12px;
        text-align: center;
        font: rem(12) / rem(18) Roboto;
        letter-spacing: 0.4px;
        color: #fff;
        font-size: 13px;
        font-weight: 500;
        padding: rem(3);
        min-width: 22px;
        height: 22px;
        margin-left: rem(10);
        position: absolute;
        top: 3px;

        @include tablet() {
          font: normal normal bold rem(16) / rem(16) PT Sans;
        }
      }

      &-tools {
        display: flex;
        @include phone() {
          justify-content: space-between;
          width: 100%;
        }
      }

      &-arrows {
        margin-right: rem(24);

        > span:first-child {
          margin: 0 rem(16);
          @include phone() {
            margin: 0 rem(24) 0 0;
          }
        }

        .icon-arrow {
          cursor: pointer;
          @include phone() {
            color: #65aae0;
            font-size: rem(19);
          }
        }
      }
    }

    &__time {
      font: italic normal normal rem(18) / rem(32) PT Sans;
      letter-spacing: 0;
      color: #ffffff;
      margin-top: rem(20);

      @include tablet() {
        margin-top: rem(16);
        margin-bottom: rem(8);
        font: italic normal normal rem(14) / rem(20) PT Sans;
      }
      @include phone() {
        color: var(--base-gray);
      }
    }

    &__body {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      @include phone() {
        font: normal normal normal rem(16) / rem(20) PT Sans;
        -webkit-line-clamp: 3;
      }

      a {
        text-decoration: underline;
      }
    }

    &__footer {
      text-align: end;
      margin-top: rem(36);
      font: normal normal bold rem(18) / rem(23) PT Sans;
      letter-spacing: 0;
      color: #ffffff;

      @include tablet() {
        font: normal normal bold rem(18) / rem(32) PT Sans;
        margin-top: rem(16);
      }
      @include phone() {
        font: normal normal bold rem(16) / rem(20) PT Sans;
        color: var(--base-blue);
      }
    }

    &__empty {
      height: 75%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--loading {
      pointer-events: none;
      opacity: 0.7;
    }
  }

  .simple-btn {
    color: white;
    @include phone() {
      color: #65aae0;
      font-size: rem(24);
    }
  }
}
