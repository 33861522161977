@import "../../../../../../common";

.personal-comment {
  &__title {
    display: flex;
    font: italic normal normal rem(18) / rem(24) PT Sans;
    &__type {
      display: flex;
      margin-right: rem(5);

      p {
        &:nth-child(1) {
          margin-right: rem(5);
        }

        font: italic normal normal rem(18) / rem(24) PT Sans;
        color: var(--base-gray);

        @include phone() {
          font: italic normal normal rem(14) / rem(18) PT Sans;
        }
      }
    }

    &__link {
      color: var(--base-blue);
    }

    @include tablet() {
      flex-direction: column;
    }
    @include phone() {
      font: italic normal normal rem(14) / rem(18) PT Sans !important;
    }
  }

  &__text {
    white-space: pre-wrap;
    margin-top: rem(16);
    margin-bottom: rem(28);
    font: normal normal normal rem(20) / rem(32) PT Sans;
    color: var(--base-text);

    @include tablet() {
      margin-bottom: rem(24);
      font: normal normal normal rem(18) / rem(24) PT Sans;
    }
    @include phone() {
      margin-bottom: rem(16);
      font: normal normal normal rem(16) / rem(20) PT Sans;
    }
  }

  &__type-label {
    margin-top: rem(32);

    span {
      padding: rem(5) rem(16);
      border-radius: rem(16);
      font: normal normal bold rem(12) / rem(32) PT Sans;
    }

    &__discussion {
      background: var(--base-purple);
      color: #fff;
    }

    &__blog {
      background: #e8e8e8;
      color: var(--base-text);
    }

    @include tablet() {
      margin-top: rem(29);
    }
    @include phone() {
      margin-top: rem(16);
    }
  }

  &__images {
    display: flex;
    flex-wrap: wrap;

    img {
      max-width: 178px;
      max-height: 133px;
      margin-top: rem(16);

      &:not(:last-child) {
        margin-right: rem(16);
      }
    }
  }

  @include phone() {
    &__title {
      font: italic normal normal rem(14) / rem(18) PT Sans;
    }

    &__text {
      font: normal normal normal rem(16) / rem(20) PT Sans;
    }
  }
}
