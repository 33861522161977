@import "../../../../../../common.scss";

.proposal-history-card {
  $root: &;

  .closing-card {
    @include phone() {
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }

  &__top {
    display: flex;
    align-items: center;
  }

  &__date {
    font: italic normal normal 18px/21px PT Sans;
    color: var(--color-secondary-l2);
    @include tablet {
      font-size: 16px;
      line-height: 22px;
    }
    @include phone {
      font-size: 14px;
      line-height: 19px;
    }
  }

  &__status {
    margin-left: 13px;
    font: italic normal normal 18px/21px PT Sans;
    @include tablet {
      font-size: 16px;
      line-height: 22px;
    }
    @include phone {
      font-size: 14px;
      line-height: 19px;
    }
  }

  &__title {
    margin-top: 4px;
    font: normal normal bold 20px/24px PT Sans;
    color: var(--base-text);
    @include tablet {
      font-size: 18px;
      line-height: 26px;
    }
    @include phone {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__list {
    margin-top: 16px;
    list-style: none;
    display: table;
    border-collapse: collapse;
    li {
      & + li {
        border-top: 16px solid transparent;
      }
      white-space: pre-wrap;
      counter-increment: proposalHistoryInc;
      display: table-row;
      &:before {
        content: counter(proposalHistoryInc) ".";
        display: table-cell;
        text-align: right;
        padding-right: 12px;
      }
    }

    &_alone {
      li:before {
        display: none;
      }
    }
  }

  &__attachments {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    margin-bottom: -20px;
    > * {
      margin: 0 20px 20px 0;
    }
  }

  & &__reason-block {
    margin: 24px 0 0 0 !important;

    @include tablet-only() {
      margin: 16px 0 0 0 !important;
    }

    @include phone() {
      padding: 16px;
    }

    label {
      font-size: 20px;
      line-height: 24px;

      @include tablet-only() {
        font-size: 18px;
        line-height: 21px;
      }

      @include phone() {
        font-size: 1rem;
        line-height: 18px;
        margin-bottom: 6px;
      }

      &:not(:first-of-type) {
        margin-top: 16px;
      }
    }

    div {
      @include tablet-only() {
        margin-top: 8px;
        font-size: 18px;
        line-height: 21px;
      }

      @include phone() {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  & &__collapse-btn {
    padding: 0;
    margin-top: 8px;
    font-size: 18px;
    line-height: 24px;

    @include phone() {
      font-size: 14px;
    }

    .ic {
      @include tablet-only() {
        font-size: 24px;
        margin-right: 12px;
      }
    }
  }

  &__list span a {
    cursor: pointer;
    color: var(--base-blue);
  }

  // неполный показ
  &_short {
    #{$root}__reason-block {
      display: none;
    }

    #{$root}__list li > span {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
