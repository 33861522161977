@import "../../../../../common.scss";

.selection-info {
  display: flex;
  flex-direction: column;
  font: normal normal normal 20px/24px PT Sans;
  color: var(--base-text);

  @include tablet-only() {
    font-size: 18px;
  }

  @include phone() {
    font-size: 16px;
    width: calc(100vw - 4rem);
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font: normal normal bold 24px/32px PT Sans;

    @include tablet-only() {
      font-size: 22px;
      line-height: 26px;
    }

    @include phone() {
      font-size: 18px;
      line-height: 21px;
    }

    .ui-icon {
      color: var(--color-secondary-l2);
    }
  }

  &__body {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    line-height: 22.5px;

    @include phone() {
      margin-top: 20px;
      line-height: 19px;
    }

    .cr-button {
      margin-top: 24px;
    }
  }

  &__footer {
    margin-top: 24px;
    display: flex;
    flex-direction: column;

    @include phone() {
      margin-top: 1.25rem;
    }
  }

  &__criteria {
    display: flex;
    flex-direction: column;

    > * {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @include phone() {
        line-height: 18px;
      }

      &:first-child {
        @include phone() {
          margin-bottom: 4px;
        }
      }

      &:not(:first-child) {
        margin-top: 1rem;

        @include tablet() {
          gap: 0.5rem;
        }

        @include phone() {
          margin-top: 8px;
        }
      }

      span {
        @include phone() {
          line-height: 19px;
        }
      }

      .ui-icon {
        @include phone() {
          font-size: 20px;
        }
      }

      .ui-icon-done-symbol {
        color: var(--color-positive);
      }
      .ui-icon-close {
        color: var(--color-negative);
      }
    }
  }

  &__popup {
    .popup {
      border-radius: 1.25rem;
      padding: 1rem;
      top: calc(100% + 0.5rem);

      @include tablet-only() {
        padding: 1.5rem;
        width: 334px;
      }

      @include phone() {
        top: calc(100% - 0.75rem);
      }
    }
  }

  &__icon {
    @include phone() {
      cursor: pointer;
    }

    &--active {
      color: var(--color-accent);
    }
  }
}
