@import "../../../../../../../common.scss";

.message-bubble {
  $root: &;
  max-width: 541px;
  padding: 10px 12px;
  border-radius: 18px;
  margin-bottom: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: stretch;
  font: normal normal normal 18px/24px PT Sans;
  letter-spacing: 0px;
  color: #282828;
  position: relative;

  &--deleted {
    width: fit-content;
    white-space: nowrap;
    color: var(--color-secondary-l4);
    padding-top: 12px;
    padding-bottom: 8px;

    @include phone() {
      white-space: wrap;
    }

    .message-bubble__body {
      flex-direction: row;
      max-width: unset !important;

      .message-bubble__text {
        font: italic normal normal 18px/24px PT Sans;
        padding-left: 12px;
        padding-right: 20px;

        @include phone() {
          font: italic normal normal 14px/19px PT Sans;
        }
      }

      .ui-icon-close-round {
        font-size: 24px;

        @include phone() {
          margin-top: -4px;
        }
      }
    }
  }

  @include phone() {
    font: normal normal normal 14px/19px PT Sans;
    margin-bottom: 16px;
    max-width: 70%;
  }

  &:hover {
    #{$root}__tools {
      opacity: 1;
      visibility: visible;
      transition-delay: 0s;
    }
  }

  &:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid transparent;
    border-bottom: 15px solid white;
    left: -9px;
    bottom: 0px;
  }

  &--mine {
    background-color: #dbf4ff;
    margin-left: auto;
    margin-right: 36px;

    .message-bubble__body {
      max-width: 86%;

      @include phone() {
        max-width: 73%;
      }
    }

    @include phone() {
      margin-right: 22px;
    }

    &:before {
      border-bottom: 15px solid #dbf4ff;
      right: -9px;
      left: unset;
      bottom: 0px;
    }
  }

  &--peer {
    background-color: #ffffff;
    margin-left: 36px;

    @include phone() {
      margin-left: 22px;
    }
  }

  &__body {
    margin-right: 40px;
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    @include phone() {
      margin-right: 37px;
    }
  }

  &__text {
    width: 100%;
    word-wrap: break-word;
  }

  &__attachments {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
  }

  &__time {
    font: normal normal normal 12px/15px PT Sans;
    letter-spacing: 0px;
    color: #b1b2ba;
    margin-left: auto;
    position: absolute;
    right: -24px;
    bottom: -7px;
    transform: translateY(-50%);
  }

  &__sending {
    position: absolute;
    right: -24px;
    bottom: 0;
    transform: translateY(-50%);

    .data-loader-wr {
      position: relative;
      min-height: unset;
      min-width: unset;

      .data-loader,
      .data-loader::after {
        width: 14px;
        height: 14px;
      }
    }
  }

  &__tools {
    display: flex;
    align-items: center;
    border-left: #bde2f7 1px solid;
    padding: 0 0 0 12px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease, visibility 0s linear 0.4s;

    .ui-icon {
      font-size: 20px;
    }

    .ui-icon-delete {
      color: #797a80;
    }
  }

  &__modal {
    max-width: 416px !important;
    height: 316px;

    .info-modal__message {
      font: normal normal normal 20px / 24px PT Sans;
    }

    &__footer {
      margin-top: 16px;
    }

    @include phone() {
      max-width: 100% !important;
    }

    &-buttons {
      width: 100%;
      display: flex;
      flex-direction: column;

      .cr-button {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }
}
