@import "../../../../../../../common.scss";

.message-create {
  background-color: #d7d9e0;
  padding: 8px 32px;

  @include phone() {
    padding: 8px 16px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 4px;
    background-color: white;
  }

  &__header {
  }

  &__body {
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
  }

  &__input {
    width: 84.23%;
    max-height: 132px;
    resize: none;
    font: normal normal normal 18px/20px PT Sans;
    letter-spacing: 0px;
    color: #282828;
    padding: 18px 14px;
    border-radius: 4px;
    border: none;
    outline: none;
    overflow-y: auto;

    &:empty::before{
      content: attr(placeholder);
      color: #b1b2ba;
    }

    @include scrollbar(5px, 4px);

    @include phone() {
      font: normal normal normal 16px/19px PT Sans;
      padding: 10px 12px;
    }
  }

  &__attachments {
    padding: 0 8px;
  }

  &__extra {
    display: flex;
    align-items: center;
    padding: 0 14px 14px 0;

    @include phone() {
      padding-bottom: 8px;
    }
  }

  &__count {
    font: normal normal normal 14px/23px PT Sans;
    letter-spacing: 0px;
    color: #b1b2ba;
    margin-left: 32px;
    margin-right: 20px;

    @include phone() {
      margin-left: 8px;
      font: normal normal normal 10px/23px PT Sans;
    }
  }

  &__tools {
    display: flex;

    .ui-icon {
      font-size: 27px;
      color: var(--color-primary);

      @include phone() {
        font-size: 20px;
      }
    }

    .ui-icon-send-message-filled {
      margin-left: 16px;
    }
  }

  .cr-file-selector__inline-error {
    text-align: center;
    margin-top: -12px;
    line-height: normal;
  }
}
