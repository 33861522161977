@import "../../../../../../../common.scss";

.peer-panel {
  display: flex;
  align-items: stretch;
  background: white;
  padding: 12px;

  @include phone() {
    padding: 8px 12px;
  }


  &__back {
    border-right: 1px solid var(--base-border);
    display: flex;
    align-items: center;
    margin-right: 14px;
    padding-right: 20px;
    margin-left: 12px;

    @include phone() {
      font-size: 14px;
      margin-right: 12px;
      margin-left: 0;
    }

    .ui-icon {
      color: #797a80;

      @include phone() {
        font-size: 20px;
      }
    }
  }

  &__user {
    display: flex;
    align-items: center;
  }

  &__info {
    margin-left: 16px;
  }

  &__name {
    font: normal normal bold 20px/24px PT Sans;
    letter-spacing: 0px;
    color: #282828;

    @include phone() {
      font: normal normal bold 16px/24px PT Sans;
    }
  }

  &__status {
    font: italic normal normal 18px/24px PT Sans;
    letter-spacing: 0px;
    color: #7e7e7e;

    @include phone() {
      font: italic normal normal 14px/24px PT Sans;
    }
  }

  &__tools {
    margin-left: auto;
    display: flex;
    align-items: center;
    border-left: 1px solid var(--base-border);
    padding-left: 20px;
    margin-right: 12px;

    .ui-icon {
      color: #797a80;

      @include phone() {
        font-size: 20px;
      }
    }
  }
}
