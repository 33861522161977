@import "../../../../common.scss";

.textAreaEditor {
  margin-top: rem(16);
  padding: rem(2);

  pre {
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    font: normal normal normal rem(20) / rem(32) PT Sans;
    font-style: inherit;
  }

  [contentEditable="true"]:empty:not(:focus):before {
    content: attr(placeholder);
    color: var(--textarea-hint-color);
    font: var(--editor-font);
  }

  &__editable {
    background-color: #fff;
    padding: 16px;
    font: var(--input-font);
    border: var(--editor-wrapper-border);
    border-radius: var(--editor-wrapper-border-radius);
    min-height: 160px;
    white-space: pre-line;
    overflow-y: auto;
    height: 160px;

    a {
      cursor: pointer;
      color: var(--base-blue);
    }
  }

  &__editable:focus-visible {
    outline: none;
    border: 1px solid var(--base-bright-blue);
    box-shadow: 0 0 0 1px var(--base-bright-blue);
  }

  &__quote {
    background: #ebebeb;
    padding: rem(16) rem(24) rem(16) rem(60);
    border-radius: 4px;
    margin: rem(16) 0 rem(24);
    background-repeat: no-repeat;
    background-position: 20px 28px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAKCAMAAACzB5/1AAAAElBMVEWPj4+Pj4+Pj4+Pj4+Pj4+Pj4+wJnG0AAAABXRSTlMAP0C/wOKAE0EAAAAhSURBVHgBY2AFAwYoTU0uAwMjKysLA4JmZmVlYoDS+CUBogYB4VIw2WMAAAAASUVORK5CYII=);
    font-style: italic;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;

    &__author {
      color: #808080;

      @include phone() {
        font: italic normal normal rem(14) / rem(18) PT Sans;
      }
    }
    &__text {
      pre {
        font: normal normal normal rem(18) / rem(24) PT Sans;
        @include phone() {
          font: normal normal normal rem(16) / rem(20) PT Sans;
        }
      }
    }

    @include phone() {
      padding: rem(20) rem(16) rem(16) rem(48);
      margin: rem(16) 0 rem(16);
    }
  }

  &__error {
    color: var(--base-red);
    margin: var(--textarea-hint-margin);
    letter-spacing: var(--textarea-hint-letter-spacing);
    font: var(--textarea-hint-font);
  }
}
