@import "../../../../common.scss";

.actual-post {
  padding-top: rem(40);
  width: 100%;
  position: relative;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include afterBeforeBackground(white);

  &--slotted {
    margin-top: 0;
  }

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__time {
    margin-top: rem(8);
  }

  &__text {
    margin-top: rem(32px);
    font: normal normal normal rem(20) / rem(32) PT Sans;
    letter-spacing: 0;
    color: var(--base-text);

    img {
      border-radius: 4px;
      max-width: var(--editor-desktop-image-max-width);
      @include tablet() {
        max-width: var(--editor-tablet-image-max-width);
      }
      @include phone() {
        max-width: var(--editor-phone-image-max-width);
      }
    }

    ul,
    ol {
      margin-left: rem(32);
    }

    a {
      color: var(--base-blue);
    }
  }

  &__footer {
    width: 100%;
    margin-top: rem(32);
    display: flex;
    justify-content: space-between;

    &__controls {
      display: flex;

      .simple-btn {
        color: var(--textarea-hint-color);
        font-size: rem(20);

        @include phone() {
          margin: 0 rem(12);
        }

        &:nth-child(2) {
          margin-left: rem(29);

          @include tablet() {
            margin-left: rem(24);
          }

          @include phone() {
            margin: 0 rem(12);
          }
        }
      }
    }
  }

  &__stages {
    margin-top: rem(32px);
    background-color: #dbf4ff;
    padding: rem(24px) rem(32px);
    border-radius: 4px;
    position: relative;
    z-index: 1;
  }

  &__stage {
    &:not(:first-child) {
      margin-top: rem(32);
      @include phone() {
        margin-top: rem(24);
      }
    }

    &-list {
      margin-left: rem(18px);

      li {
        padding-left: rem(15px);
        margin-top: rem(10px);
      }
    }
  }

  &__slide {
    display: flex;
    justify-content: flex-end;
    margin-left: rem(16);

    a {
      display: flex;
      align-items: center;
      color: var(--base-blue);
      font: normal normal bold rem(18) / rem(24) PT Sans;
      letter-spacing: 0;

      @include phone() {
        font: normal normal bold 14px/19px PT Sans;
      }

      span {
        margin: 0 rem(12);
      }

      &:last-child {
        margin-left: rem(96);

        @include tablet() {
          margin-left: rem(32);
        }
        span {
          margin-right: 0;
        }
      }
    }
  }

  @include tablet() {
    &__text,
    &__stages {
      margin-top: rem(24);
    }
  }

  @include phone() {
    padding-top: rem(16);

    &__text,
    &__footer {
      margin: 0;
      padding-top: rem(16);
    }

    &__footer {
      flex-direction: column;
      align-items: center;

      &__controls {
        margin-bottom: rem(20);
      }
    }

    &__slide {
      display: flex;
      justify-content: space-between;
      margin-left: 0;
      width: 100%;

      a:first-child span {
        margin-left: 0;
      }

      a:last-child span {
        margin-right: 0;
      }

      a:first-child,
      a:last-child {
        margin: 0;
      }
    }
  }
}
