@import "../../../common";

.about {
  .page-title {
    margin-top: rem(40);
    @include tablet() {
      margin-top: rem(32);
    }
    @include phone() {
      margin-top: rem(16);
      padding: 0 rem(16);
    }
  }
  &__wrapper {
    display: flex;
    margin-top: rem(48);
    @include tablet() {
      margin-top: rem(32);
      flex-direction: column-reverse;
    }
    @include phone() {
      margin-top: rem(16);
      padding: 0 rem(16);
    }
  }

  &__content {
    width: 100%;
    font: normal normal normal rem(20) / rem(32) PT Sans;
    color: var(--base-text);
    @include tablet() {
      font: normal normal normal rem(18) / rem(24) PT Sans;
      margin-top: 32px;
    }
    @include phone() {
      font: normal normal normal rem(16) / rem(20) PT Sans;
      margin-top: 16px;
      p,
      li,
      ol {
        font: normal normal normal rem(16) / rem(20) PT Sans;
      }
    }

    a {
      color: var(--base-blue);
    }

    img {
      border-radius: 4px;
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    max-width: 432px;
    margin-left: rem(32);
    > img {
      object-fit: fill;
      align-self: flex-start;
      width: auto;
      max-width: 100%;
      border-radius: 4px;
    }

    @include tablet() {
      width: 100%;
      max-height: 405px;
      max-width: unset;
      margin: 0;
      > img {
        align-self: auto;
      }
    }

    @include phone() {
      max-height: 190px;
    }
  }
}
