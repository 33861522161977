@import "../../../../../common";

.personal-post {
  &__post {
    margin-bottom: rem(32);
    background-color: #fff;
    padding: rem(24) rem(24) rem(40) rem(24);

    .cr-back-button {
      margin-top: 0;
      @include phone() {
        margin-bottom: rem(16);
        &__icon {
          margin-right: rem(10);
        }
        &__text {
          font: normal normal bold rem(14) / rem(19) PT Sans;
        }
      }
    }

    @include phone() {
      padding: rem(24) rem(16);
    }

    @include phone() {
      margin-bottom: rem(16);
      padding: rem(16);
    }

    > div:last-of-type {
      margin-bottom: 0;
    }

    &__text {
      margin: rem(16) 0 rem(32) 0;
      word-break: break-word;

      ul,
      ol {
        margin-left: rem(32);
      }

      img {
        max-width: 450px;
        margin-top: 24px;
        border-radius: 4px;
      }
    }

    &__images {
      display: flex;
      margin-top: rem(24px);
      margin-bottom: rem(32px);
    }
    @include phone() {
      .page-title {
        margin-top: rem(16);
      }
    }
  }

  a {
    color: var(--base-blue);
  }
}
