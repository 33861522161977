@import "../../../../../../common.scss";

.PlainDatePicker {
  &__title {
    font: normal normal normal rem(14px) / rem(24px) PT Sans;
    letter-spacing: 0;
    color: var(--textarea-hint-color);
    position: absolute;
    top: 0;
    left: rem(12px);
  }

  input {
    border: 1px solid var(--base-blue);
    border-radius: 4px;
    padding: rem(25px) 0 rem(5px) rem(10px);
    font: normal normal normal 18px/24px PT Sans;
    letter-spacing: 0px;
    outline: none;
  }

  .ui-icon {
    position: absolute;
    right: rem(14px);
    top: rem(18px);
    color: var(--color-secondary-l2);
  }
}
