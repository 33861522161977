@import "common.scss";

html {
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

img {
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

a.base-link {
  color: var(--base-blue);
}

p,
ul,
ol {
  font: normal normal normal 20px/32px PT Sans;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  color: var(--base-text);
}

h1 {
  font: normal normal bold 50px/60px Bebas Neue;
}
h2 {
  font: normal normal bold 36px/42px Bebas Neue;
}
h3 {
  font: normal normal bold 28px/32px PT Sans;
}
h4 {
  font: normal normal bold 24px/32px PT Sans;
}
h5 {
  font: normal normal bold 20px/32px PT Sans;
}
h6 {
  font: normal normal bold 18px/28px PT Sans;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.ui-icon {
  font-size: 24px;
}

.ui-thin-icon {
  font-size: 48px;
}

.pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}

.error {
  color: var(--base-red);
  letter-spacing: var(--textarea-hint-letter-spacing);
  font: var(--textarea-hint-font);
}

.flex {
  display: flex;
}

.flex-fill {
  flex: 1;
  min-width: 0;
}

.scroll-x {
  overflow-x: auto;
  overflow-y: hidden;
}

em {
  font: italic normal normal rem(18) / rem(24) PT Sans;
  color: var(--textarea-hint-color);
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}

.break-word {
  word-break: break-word;
}

@include tablet() {
  h1 {
    font: normal normal bold 38px/47px Bebas Neue;
  }
  h2 {
    font: normal normal bold 32px/38px Bebas Neue;
  }
  h3 {
    font: normal normal bold 26px/33px PT Sans;
  }
  h4 {
    font: normal normal bold 22px/29px PT Sans;
  }
  h5 {
    font: normal normal bold 18px/26px PT Sans;
  }
  h6 {
    font: normal normal bold 16px/20px PT Sans;
  }

  p,
  ul,
  ol {
    font: normal normal normal rem(18) / rem(24) PT Sans;
  }

  em {
    font: italic normal normal rem(16) / rem(22) PT Sans;
  }
}

@include phone() {
  h1 {
    font: normal normal bold 26px/30px Bebas Neue;
  }
  h2 {
    font: normal normal bold 24px/29px Bebas Neue;
  }
  h3 {
    font: normal normal bold 20px/26px PT Sans;
  }
  h4 {
    font: normal normal bold 18px/23px PT Sans;
  }
  h5 {
    font: normal normal bold 16px/22px PT Sans;
  }
  h6 {
    font: normal normal bold 14px/19px PT Sans;
  }

  p,
  ul,
  ol {
    font: normal normal normal rem(16) / rem(20) PT Sans;
  }

  em {
    font: italic normal normal rem(14) / rem(19) PT Sans;
  }
}

// MODALS
div.crowd-modal {
  max-width: calc(100vw - 66px);
  width: 800px;
  border-radius: 4px;

  @include tablet-only() {
    max-width: calc(100vw - 76px);
  }

  @include phone() {
    border-radius: unset;
  }
}

.crowd-modal #close-modal-icon {
  width: rem(24);
  height: rem(24);
  outline: none;

  svg {
    fill: var(--color-secondary);
  }

  @include phone() {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 31;
  }
}

.submit-form__footer {
  text-align: right;
}

@include phone() {
  .react-responsive-modal-root {
    div.crowd-modal {
      padding: rem(16);
      margin: 0;
      max-width: 100vw;
      min-height: 100%;

      &__header {
        position: fixed;
        height: 65px;
        background-color: #fff;
        z-index: 20;
        width: 100%;
        left: 0;
        top: 0;
        padding: rem(16);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: var(--color-secondary);
      }

      &__body {
        margin-top: calc(var(--header-height-phone) - 16px);
      }
    }
  }
}

.user-name-hidden-icon {
  margin-left: 8px;
  @include tablet() {
    margin-left: 8px;
  }
  @include phone() {
    margin-left: 6px;
  }
}

// attachments
.attachment__content,
.cr-file-preview {
  border-radius: 4px;
  overflow: hidden;
}

.cr-search__list {
  border-radius: 4px;
  box-shadow: 0px 3px 10px #28282826 !important;
  border: none !important;
}
