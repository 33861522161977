@import "../../../common";

@mixin disc {
  font: normal normal normal 16px/22px PT Sans;
  letter-spacing: 0;
  color: #ffffff;
  background-color: black;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.faq {
  font: normal normal normal 20px/32px PT Sans;
  color: var(--base-text);
  letter-spacing: 0;

  .page-title {
    margin-top: rem(40);
    @include tablet() {
      margin-top: rem(32);
    }
    @include phone() {
      margin-top: rem(16);
      padding: 0 rem(16);
    }
  }
  a {
    color: var(--base-blue);
  }

  &__wrapper {
    margin-top: rem(48);
    @include tablet() {
      margin-top: rem(24);
    }
    @include phone() {
      padding: 0 rem(16);
      margin-top: 0;
    }
  }
  &__tabs-wrapper {
    display: flex;
    min-height: 672px;
    @include tablet() {
      flex-direction: column;
      min-height: unset;
    }
  }
  &__tabs {
    display: flex;
    flex-direction: column;
    max-width: 432px;
    width: 100%;

    @include tablet() {
      width: 100%;
      max-width: unset;
      margin-bottom: rem(16);
      .cr-select {
        margin-top: 0;

        .cr-select__input {
          .ui-icon-arrow-slider-left {
            right: rem(16);
            top: rem(18);
            font-size: 22px;
          }
        }
      }
    }

    @include phone() {
      .cr-select {
        margin-top: rem(16);
      }
    }
    span {
      color: #757679;
      font: normal normal normal rem(18) / rem(24) PT Sans;
      @include phone() {
        white-space: pre;
        padding-right: rem(47);
      }
    }
  }

  &__tab {
    cursor: pointer;
    padding: rem(20) rem(32);

    &:not(:last-child) {
      border-bottom: 1px solid #d7d9e0;
    }

    &_active {
      color: white;
      background: var(--base-blue);
    }
  }

  &__content {
    width: 100%;
    margin-left: rem(32);

    .video-react {
      border-radius: 4px;
      overflow: hidden;
      background-color: unset;
      margin-top: rem(40);
      @include tablet() {
        margin-top: rem(32);
      }
      @include phone() {
        margin-top: rem(24);
      }

      .video-react-poster {
        background-size: cover;
        background-color: unset;
      }
    }
    @include tablet() {
      margin-left: 0;
      p {
        font: normal normal normal rem(18) / rem(24) PT Sans;
        &.faq__tab-heading {
          font-weight: bold;
        }
      }
    }
    @include phone() {
      p {
        font: normal normal normal rem(16) / rem(20) PT Sans;
        &.faq__tab-heading {
          font-weight: bold;
        }
      }
    }
  }

  &__question {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: rem(48);
    padding: rem(32);
    background: var(--base-blue);

    @include afterBeforeBackground(var(--base-blue));

    &-text {
      color: white;
      margin-right: rem(48);
      @include tablet() {
        margin-right: 0;
        font: normal normal normal rem(18) / rem(24) PT Sans;
      }
      @include phone() {
        margin-bottom: rem(16);
        font: normal normal normal rem(16) / rem(20) PT Sans;
      }
    }

    button:hover {
      background-color: white;
      color: #3c8de2;
    }
    @include tablet() {
      margin-top: rem(32);
      padding: rem(32) 0;
      justify-content: space-between;
    }
    @include phone() {
      margin-top: rem(24);
      padding: rem(16) 0;
      flex-direction: column;
    }
  }

  &__tab-heading {
    font: normal normal bold rem(28) / rem(32) PT Sans;
    color: var(--base-text);
  }

  &__tab-subheading {
    font: normal normal bold rem(24) / rem(32) PT Sans;
    color: var(--base-text);
  }

  .mt-32 {
    margin-top: rem(32);
    p {
      font-weight: bold;
    }
    @include phone() {
      margin-top: rem(16);
    }
  }

  .mt-40 {
    margin-top: rem(40);
    @include phone() {
      margin-top: rem(16);
    }
  }

  &__tab-text {
    font: normal normal normal rem(20) / rem(32) PT Sans;
    color: var(--base-text);
  }

  &__stages {
    &-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: rem(40px);

      .faq-card {
        width: 31%;
        &:nth-child(4) {
          flex-direction: row;
          align-items: flex-end;
          width: 100%;
          margin-top: rem(32px);

          .faq-card__body {
            margin: 0;
            padding-top: 0;
          }

          .faq-card__footer {
            padding-bottom: 5px;
          }
          @include phone() {
            margin-top: rem(8);
          }
        }
        @include phone() {
          &__body {
            &-heading {
              margin-top: rem(5);
            }
            margin-top: rem(8);
          }
        }
      }
    }
  }

  &__send {
    &-wrap {
      display: flex;
      justify-content: space-between;
      margin-top: rem(16);

      .faq-card {
        width: 23%;
        &__header {
          &-title {
            @include disc;
          }
        }
        &__body {
          margin: 0;
          @include tablet() {
            &-text {
              margin: 0;
            }
          }
        }
        &__footer {
          margin-top: 0;
        }
      }
      @include tablet() {
        flex-wrap: wrap;
      }
    }
  }

  &__status {
    &-wrap {
      display: flex;
      justify-content: space-between;
      margin-top: rem(16px);

      .faq-card {
        width: calc(50% - 16px);
        padding-top: 16px;

        &__header {
          &-icon {
            font-size: 56px;
            @include tablet() {
              font-size: 48px;
            }
            @include phone() {
              font-size: 32px;
              margin-bottom: rem(-24);
            }
          }
        }
        &__body {
          margin-top: 0;
          margin-right: 0;
          padding-top: 0;
          @include tablet() {
            margin-top: 0;
          }
          @include phone() {
            margin-top: 16px;
          }
        }
      }
      @include tablet() {
        flex-wrap: wrap;
      }
    }
  }

  &__adopt {
    &-wrap {
      display: flex;
      justify-content: space-between;
      margin-top: rem(16px);

      .faq-card {
        width: 48%;

        &__header {
          &-icon {
            font-size: 56px;
            @include tablet() {
              font-size: 48px;
            }
            @include phone() {
              font-size: 32px;
            }
          }
        }
        &__body {
          margin-top: 0;
        }
      }
    }
  }

  &__questions {
    &-wrap {
      display: flex;
      justify-content: space-between;
      margin-top: rem(16px);

      .faq-card {
        width: 23%;
        &__header {
          &-title {
            @include disc;
          }

          &-icon {
            font-size: 56px;
            @include tablet() {
              font-size: 48px;
            }
            @include phone() {
              font-size: 32px;
            }
          }
        }
        &__body {
          margin: 0;
          @include tablet() {
            &-text {
              margin: 0;
            }
          }
        }
      }
      @include tablet() {
        flex-wrap: wrap;
      }
    }
    @include tablet() {
      ol,
      p,
      li,
      strong {
        font: normal normal normal rem(18) / rem(24) PT Sans;
      }
      .mt-40 {
        font: normal normal bold rem(26) / rem(33) PT Sans;
      }
      .mt-32,
      strong {
        font-weight: bold;
      }
    }
    @include phone() {
      ol,
      p,
      li,
      strong {
        font: normal normal normal rem(16) / rem(20) PT Sans;
      }
      .mt-40 {
        font: normal normal bold rem(20) / rem(26) PT Sans;
      }
      .mt-32,
      strong {
        font-weight: bold;
      }
    }
  }

  &__os {
    &-wrap {
      display: flex;
      justify-content: space-between;
      margin-top: rem(16px);

      @include phone() {
        flex-direction: column;
      }
      .faq-card {
        width: 48%;
        &__header {
          &-title {
            @include disc;
          }

          &-icon {
            font-size: 56px;
            @include tablet() {
              font-size: 48px;
            }
            @include phone() {
              font-size: 32px;
            }
          }
        }
        &__body {
          margin: 0;
          @include tablet() {
            &-text {
              margin: 0;
            }
          }
        }
      }
    }
  }
  .faq-card {
    @include tablet() {
      padding: rem(5) 0;
      margin-bottom: rem(8);
      &__body {
        padding: 0 rem(12) rem(12);
      }
    }
    @include phone() {
      width: 49%;
      padding: rem(5) 0;
      margin-bottom: rem(8);
      &__body {
        padding: 0 rem(12) rem(12);
        &-heading {
          font: normal normal bold rem(14) / rem(20) PT Sans;
        }
      }
    }
  }
  @include phone() {
    .faq__os-wrap {
      .faq-card {
        width: 100%;
      }
    }
  }
}
