@mixin adaptive($max-width) {
  @media screen and (max-width: #{$max-width}px) {
    @content;
  }
}

@mixin short-desktop {
  @media screen and (max-width: 1280px) {
    @content;
  }
}

@mixin short-desktop-only {
  @media screen and (max-width: 1280px) and (min-width: 551px) {
    @content;
  }
}

@mixin wide-tablet {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 834px) {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (max-width: 834px) and (min-width: 551px) {
    @content;
  }
}

@mixin phone {
  @media screen and (max-width: 550px) {
    @content;
  }
}

@mixin only($width) {
  @media screen and (max-width: #{$width}px) and (min-width: #{$width}px) {
    @content;
  }
}

@mixin min($min) {
  @media screen and (min-width: #{$min}px) {
    @content;
  }
}

@mixin scrollbar($width, $round: 0px, $trackColor: #dedede, $thumbColor: #8a8a8a) {
  &::-webkit-scrollbar {
    width: $width;
  }
  &::-webkit-scrollbar-track {
    background: $trackColor;
    border-radius: $round;
  }
  &::-webkit-scrollbar-thumb {
    background: $thumbColor;
    border-radius: $round;
  }
}

@mixin afterBeforeBackground($color) {
  &::before {
    left: 0;
    margin-left: calc(-50vw + 1px);
  }
  &::after {
    left: calc(100% - 1px);
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: $color;
    height: 100%;
    width: 50vw;
    top: 0;
    @content;
  }
}

$html-font-size: 16px;

@function stripUnit($value) {
  @return $value / ($value * 0 + 1);
}
@function rem($pxValue) {
  @return #{stripUnit($pxValue) / stripUnit($html-font-size)}rem;
}

@mixin multilineEllipsis($lines-to-show: 2, $width: 100%, $font-size: 14px, $line-height: 1.6) {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: $width;
  height: $font-size * $line-height * $lines-to-show; /* Fallback for non-webkit */
  font-size: $font-size;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  overflow-wrap: break-word;
}

@mixin desktopHover() {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}
