@import "../../../../common";

$dateListHeight: 48px;
$timelineMarginTop: 60px;
$titleWidth: 44%;
$sliderChartWidth: 56%;

.stage-list {
  position: relative;
  margin-left: auto;
  letter-spacing: 0;

  .calendar-stripe {
    margin-right: 48px;

    @include tablet() {
      margin-top: rem(24);
      margin-right: 0;
    }

    @include tablet() {
      margin-top: rem(16);
    }
  }

  &__foldable {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    transition: all 0.4s ease;
  }

  &__info {
    width: $titleWidth;

    &-title {
      font: normal normal bold rem(40) / rem(40) Bebas Neue;
      color: var(--base-text);
      line-height: 1.2;
      width: $titleWidth;
      min-width: $titleWidth;

      @include tablet() {
        font: normal normal bold rem(32) / rem(38) Bebas Neue;
      }
    }

    &-list {
      margin-top: $timelineMarginTop;
    }

    &-name {
      height: 62px;
      display: flex;
      align-items: center;
      background-color: var(--base-ligth-blue);
      margin-top: rem(2);
      margin-bottom: rem(12);
      padding: 0 rem(32);
      cursor: pointer;

      > div {
        font: normal normal bold rem(20) / rem(24) PT Sans;
        color: var(--base-text);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: auto !important;

        @supports (-webkit-line-clamp: 2) {
          @include multilineEllipsis(2, 100%, 20px, 1.2);
        }
      }
    }
  }

  &__time {
    width: $sliderChartWidth;
  }

  &__legend {
    display: flex;
    width: 100%;
    margin-top: rem(33);

    @include tablet() {
      justify-content: space-between;
      margin-top: 0;
    }
  }

  .legend-item {
    display: flex;
    align-items: center;
    margin-right: rem(50);

    @include tablet() {
      margin-right: 10px;
    }

    &__mark {
      width: 16px;
      height: 16px;
      display: inline-block;
      border-radius: 100%;
    }

    &__title {
      margin-left: 14px;
      font: italic normal normal rem(18) / rem(23) PT Sans;
      color: #888888;

      @include tablet() {
        font: italic normal normal rem(16) / rem(22) PT Sans;
      }
    }
  }

  &__title {
    font: normal normal bold rem(40) / rem(40) Bebas Neue;
    color: var(--base-text);
  }

  &__header {
    top: 65px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    z-index: 10;
    background-color: transparent;

    &--fixed {
      left: 0;
      position: fixed;
      background-color: white;
    }

    @include tablet() {
      flex-direction: column;
    }
  }

  &__arrows {
    position: absolute;
    width: 60.88%;
    height: $dateListHeight;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ic {
      cursor: pointer;
    }

    .ui-icon-keyboard-arrow-left {
      margin-left: rem(25);
    }
  }

  &__body {
    overflow: hidden;
  }

  &__draggable {
    margin-top: $timelineMarginTop;
  }

  .stages-block {
    background-color: var(--base-ligth-blue);

    &__line {
      height: 64px;
      margin-bottom: rem(4);
      cursor: pointer;
      width: 400px;
    }

    &__cell {
      display: block;
      width: 60px;
      margin: 0 auto;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    background-color: var(--base-ligth-blue);
    margin-bottom: rem(12);
  }

  &__cell {
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    padding: 1px;
    height: 60px;

    @include tablet() {
      height: 32px;
    }

    &--color {
      height: 100%;
      display: block;
      transition: background-color 0.2s;
      opacity: 1;
    }

    &--filled {
      cursor: pointer;
    }
  }

  &__foldbtn {
    margin-left: auto;
    @include tablet() {
      text-align: end;

      .cr-button {
        font: normal normal bold 16px/24px PT Sans;
        letter-spacing: 0px;
        color: #65aae0;
      }
    }

    .cr-button-outlined {
      &:hover {
        background-color: transparent;
      }

      @include desktopHover() {
        &:hover {
          background-color: rgba(0, 174, 239, 0.1);
        }
      }
      &:active {
        background-color: var(--base-blue);
      }
    }

    .cr-button {
      border: none;
    }
  }

  .project-timing {
    font: normal normal normal 14px/20px PT Sans;
    letter-spacing: 0px;
    color: #65aae0;
    margin-top: 17px;
    margin-left: 30px;
    display: flex;
    align-items: center;

    &__day {
      font: normal normal bold 14px/20px PT Sans;
      letter-spacing: 0px;
    }

    .ui-icon-clock {
      font-size: 16px;
      margin-right: 8px;
    }
  }
}

.fragment-modal {
  &__body {
    margin-top: 2rem;
    font: normal normal normal rem(20) / rem(32) PT Sans;
    letter-spacing: 0;
    color: var(--base-text);
  }
  &__subhead {
    @include phone() {
      margin-top: calc(var(--header-height-phone) - 15px) !important;
    }
  }
}

.stage-lines {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.stage-line {
  display: flex;
  height: 60px;
  margin-bottom: rem(12);
  letter-spacing: 0;

  @include tablet() {
    flex-direction: column;
    height: 100%;
    min-height: 110px;
    margin-bottom: rem(8);
    border-radius: 4px;
  }

  @include phone {
    border-radius: unset;
  }

  &__title {
    width: $titleWidth;
    background-color: var(--base-ligth-blue);
    padding: 0 rem(32);
    display: flex;
    border-radius: 4px 0 0 4px;
    @include tablet {
      border-radius: 4px 4px 0 0;
    }
    @include phone {
      border-radius: unset;
    }
  }

  &__blocks {
    width: $sliderChartWidth;
    overflow: hidden;
    border-radius: 0 4px 4px 0;
    @include tablet {
      border-radius: unset;
    }
  }

  &__button > span {
    font: normal normal bold rem(20) / rem(24) PT Sans;
    color: var(--base-text);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: auto !important;
    text-align: left;

    @supports (-webkit-line-clamp: 2) {
      @include multilineEllipsis(2, 100%, 20px, 1.2);
    }

    @include tablet() {
      font: normal normal bold rem(18) / rem(23) PT Sans;
    }
  }

  &__dragg {
    transition: all 0.4s ease 0s;
    min-width: 100%;
    background-color: var(--base-ligth-blue);
    border-radius: 0 4px 4px 0;
  }

  &__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: var(--base-ligth-blue);
    margin-bottom: 12px;
    border-radius: 0 4px 4px 0;
    overflow: hidden;
  }
}

@include adaptive(1380) {
  .stage-list {
    &__info-title {
      width: 35%;
      min-width: 35%;
    }
  }

  .stage-line {
    &__title {
      width: 35%;
    }

    &__blocks {
      width: 65%;
    }
  }
}

@include tablet() {
  .stage-list {
    &__header {
      align-items: flex-start;
    }

    &__info-title {
      width: 100%;
    }

    &__cell {
      height: 32px;
    }

    .calendar-stripe {
      padding: 0 24px;
      margin: 24px auto 0;

      .calendar-stripe__arrow-left {
        left: -2px;
      }

      .calendar-stripe__arrow-right {
        right: -2px;
      }
    }
  }

  .stage-line {
    background-color: var(--base-ligth-blue);

    &__title {
      width: 100%;
      padding: rem(8) rem(24) rem(12);
    }

    &__blocks {
      width: calc(100% - 48px);
      margin: 0 auto;
    }
  }
}

@include phone() {
  .stage-list {
    .stage-mobile-cards {
      margin-top: rem(16);
      width: 100%;

      &__empty {
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .calendar-stripe {
      padding: 0;

      .calendar-stripe__arrow-left {
        left: -10px;
      }

      .calendar-stripe__arrow-right {
        right: -10px;
      }
    }
  }
}
