.support-form {
  .cr-file-selector {
    &__head {
      align-items: center;
    }
  }
  .cr-select {
    .cr-select__placeholder {
      .ui-icon-arrow-slider-left {
        right: 20px;
        top: 22px;
        font-size: 16px;
      }
    }
  }
  .recaptcha-wrapper--error {
    iframe {
      border: 1px solid #ff6666;
      box-shadow: 0 0 0 1px #ff6666;
    }
  }
}
