@import "../../../../../common.scss";

.selection-idea {
  $root: &;

  &__reject-reason-card {
    @include tablet-only() {
      padding: 24px;
      margin-top: 10px !important;
    }

    @include phone() {
      font-size: 1rem;
    }

    .selection-page__card-label-info {
      @include tablet-only() {
        margin-bottom: 0.25rem;
        font-size: 18px;
        line-height: 21px;
      }

      @include phone() {
        margin-bottom: 0.25rem;
        line-height: 18px;
        font-size: 1rem;
      }

      &:not(:first-of-type) {
        @include phone() {
          margin-top: 16px;
        }
      }
    }

    div {
      @include tablet() {
        font-size: 18px;
        line-height: 21px;
      }

      @include phone() {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  &__forwarded-card {
    @include phone() {
      padding: 0.75rem 1rem;
    }

    > * {
      @include tablet-only() {
        font-size: 18px;
        line-height: 21px;
      }
    }

    .selection-page__card-label-info {
      @include tablet-only {
        margin-bottom: 8px;
        font-size: 18px;
        line-height: 21px;
      }

      @include phone {
        margin-bottom: 0.25rem;
        font-size: 1.125rem;
      }
    }
  }

  &__expert {
    @include phone() {
      margin-bottom: 32px;
    }

    &--small-margin {
      @include phone() {
        margin-bottom: 20px;
      }
    }
  }

  &:last-of-type {
    #{$root}__expert {
      @include phone() {
        margin-bottom: 20px;
      }
    }
  }
}
