@import "../../../../common.scss";

.profile-sub {
  margin-top: rem(36);

  .cr-card,
  .comment-card {
    margin-top: rem(32);
    @include phone() {
      margin-top: rem(16q);
    }
  }

  .tabs-mobile {
    background-color: white;
  }

  .post-card {
    margin-top: rem(32);

    &--hb {
      @include tablet() {
        font: normal normal bold 20px/32px PT Sans;
      }
      @include phone() {
        font: normal normal bold 16px/22px PT Sans;
      }
    }
    &--hi {
      @include phone() {
        font: italic normal normal 14px/18px PT Sans;
      }
    }
    &--bn {
      @include tablet() {
        font: normal normal normal 18px/24px PT Sans;
      }
      @include phone() {
        font: normal normal normal 16px/20px PT Sans;
      }
    }

    @include tablet() {
      margin-top: rem(24);
    }
    @include phone() {
      padding: rem(16);
      margin-top: rem(16);

      .profile-blog-card__footer {
        margin-top: rem(16);
        button {
          border: 0;
          font: normal normal bold rem(16) / rem(20) PT Sans;
          width: auto;
          padding: 0;

          &:hover {
            background-color: transparent;
          }
          &:active {
            color: var(--color-primary-d1);
            background-color: transparent;
          }
        }
      }
    }
  }
  &__head {
    display: flex;
    justify-content: space-between;
    @include tablet() {
      flex-direction: row;
      flex-wrap: wrap;
      flex-direction: row;
      margin-top: rem(32);
    }
    @include adaptive(650) {
      flex-wrap: nowrap;
    }
    @include phone() {
      flex-direction: column;
      justify-content: flex-start;
      margin-top: rem(8);
    }
  }
  &__content {
    padding: rem(36) rem(116);
    @include tablet() {
      padding: rem(8) 0;
    }
    @include phone() {
      padding: 0;
    }
  }
  &__title {
    font: normal normal bold rem(28) / rem(32) PT Sans;
    letter-spacing: 0;
    flex: 1;
    @include tablet() {
      min-width: 100%;
      flex-shrink: 1;
      font: normal normal bold rem(26) / rem(33) PT Sans;

      &.notification {
        min-width: 20%;
        @include tablet() {
        }
      }
    }
    @include phone() {
      font: normal normal bold rem(20) / rem(26) PT Sans;
      padding: 0 rem(16);
    }
  }

  &__tabs {
    .cr-button-outlined {
      background-color: var(--base-ligth-blue);
    }

    @include tablet() {
      margin-top: 32px;
      .cr-button {
        font-weight: normal;
        padding: rem(10px) rem(16px);
        border-radius: 20px;
      }
    }

    @include phone() {
      margin-top: 16px;
    }
  }

  &__panel {
    @include tablet() {
      margin-top: 32px;
    }
  }

  @include phone() {
    margin-top: rem(12);

    &__panel {
      align-self: center;
      margin-top: rem(16);
      width: 100%;
      padding: 0 rem(16);
      justify-content: center;
    }
  }
}

.mobailPositionBtn {
  &.profile-sub {
    position: relative;
    .profile-sub__tabs {
      margin-top: rem(66);
    }
    .profile-sub__content {
      .fade-in {
        .my-blog__content,
        .my-mail__content {
          & > button {
            position: absolute;
            top: rem(58);
            left: 50%;
            width: calc(100% - 32px);
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}
