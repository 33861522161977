@import "../../../../../common";

.personal-proposal {
  padding: rem(24) 0;

  .proposal-card {
    padding: 0;
  }

  .cr-back-button {
    margin-top: 0;
  }
  @include phone() {
    padding: rem(24) rem(16);
  }
}
