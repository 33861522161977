@import "../../../../common.scss";

@mixin module-card-font {
  font: normal normal bold 1.25rem/1.75rem PT Sans;
  letter-spacing: 0;
  color: var(--base-text);
}

.module-card {
  border: 1px solid var(--color-primary);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  letter-spacing: 0;

  &:hover {
    background-color: var(--base-ligth-blue);
  }

  @include tablet() {
    padding: rem(16);
  }

  @include phone() {
    border: none;
    flex-direction: row;
    align-items: center;
    padding: rem(14) 0;
  }

  &__head {
    @include phone() {
      margin-right: rem(11);
      display: flex;
      align-items: center;
    }
  }

  &__icon {
    font-size: rem(48);
    color: var(--color-secondary-l2);
    @include phone() {
      font-size: rem(22);
    }
  }

  &__body {
    margin-top: rem(16);
    @include module-card-font;

    @include tablet() {
      margin-top: rem(21);
      font: normal normal bold rem(22) / rem(29) PT Sans;
    }
    @include phone() {
      margin: 0;
      margin-right: auto;
      font: normal normal bold rem(18) / rem(23) PT Sans;
    }
  }

  &__countNew {
    background-color: var(--base-orange);
    color: white;
    padding: rem(4) rem(8);
    border-radius: 16px;
    font: normal normal bold rem(16) / rem(16) PT Sans;
    margin-left: rem(8);

    @include tablet() {
      font: normal normal bold rem(16) / rem(16) PT Sans;
    }
    @include phone() {
      margin-left: 0;
      margin-right: rem(8);
    }
  }

  &__footer {
    @include module-card-font;
    min-height: 28px;
    display: flex;
    align-items: center;
    margin-top: auto;

    @include tablet() {
      font: normal normal bold rem(26) / rem(33) PT Sans;
    }
    @include phone() {
      flex-direction: row-reverse;
      margin: 0;
      font: normal normal bold rem(18) / rem(23) PT Sans;
    }
  }
}
