@import "../../../../../common.scss";

.decline-proposal-form {
  margin-top: 25px;

  @include tablet() {
    margin-top: 0;
  }

  @include phone() {
    margin-bottom: 4px;
  }

  .cr-select {
    @include tablet-only() {
      margin-top: 16px;
    }

    &__input {
      &-list-item {
        @include phone() {
          font-size: 16px;
          padding: 0.5rem 1rem;
          line-height: normal;
        }
      }
    }
  }

  &__title {
    @include phone() {
      line-height: 18px !important;
    }
  }

  &__body {
    .cr-textarea {
      @include tablet() {
        margin-top: 1rem;
      }
    }
  }

  &__footer {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;

    @include tablet-only() {
      margin-top: 24px;
    }

    @include phone() {
      margin-top: 20px;
      flex-direction: column;
      gap: 0.75rem;
    }

    > *:last-child {
      margin-left: auto;
    }
  }

  .cr-textarea__input {
    @include tablet {
      display: block;
      margin-bottom: 7px;
    }
  }

  .cr-textarea__length {
    @include tablet() {
      line-height: 17px;
    }
  }
}
