@import "../../../../common.scss";

.info-modal {
  &__root {
    z-index: 1010 !important;
  }

  &__message {
    font: normal normal normal 20px/32px PT Sans;
    letter-spacing: 0px;
    color: var(--base-text);
  }

  &__buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    button:not(:last-child) {
      margin-right: rem(16);
    }

    @include phone() {
      flex-direction: column;
      button:not(:last-child) {
        margin-right: 0;
        margin-bottom: rem(16);
      }
    }
  }

  a {
    color: var(--base-blue);
  }

  @include phone() {
    .crowd-modal__subhead {
      margin-top: calc(var(--header-height-phone) - 15px);
    }
  }

  // TODO при введении респонсива и замене <meta name="viewport" content="width=1360" /> на width=device-width
  // переделать .mobile/.tablet на нормальный вид
  &.crowd-modal.mobile {
    max-width: initial;
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    margin: 0;
    padding: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    a {
      outline: none;
    }

    .info-modal__message {
      font: normal normal normal 62px/84px PT Sans;
      text-align: center;
    }

    .react-responsive-modal-closeButton {
      transform: scale(4);
      margin: 40px;
    }

    .info-modal__buttons {
      justify-content: center;
      margin-top: 100px;
      button {
        margin: 0;
        transform: scale(4);
      }
    }
  }

  &.crowd-modal.tablet {
    .info-modal__message {
      font: normal normal normal 32px/44px PT Sans;
    }

    .react-responsive-modal-closeButton {
      transform: scale(2);
      margin: 20px;
    }

    .info-modal__buttons {
      margin-top: 50px;
      button {
        transform: scale(2);
      }
    }
  }
}
