@import "../../../../common";

.stages-tape {
  display: flex;
  width: 100%;
  justify-content: space-evenly;

  &__item {
    max-width: 280px;
    width: 100%;
    &:not(:last-child) {
      margin-right: 1.75rem;
    }
  }

  .swiper-pagination {
    position: relative;
  }

  @include tablet() {
    &__item {
      &:not(:last-child) {
        margin-right: rem(16);
      }
    }
  }

  @include phone() {
    flex-direction: column;

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 100%;
    }

    .swiper {
      width: 100%;
      height: 100%;
    }

    .swiper-container {
      padding: rem(20px) 0 rem(12px);
    }

    .swiper-wrapper {
      width: 100vw;
    }

    .swiper-pagination {
      margin-top: 0;
    }

    .swiper-pagination-bullet,
    .swiper-pagination-bullet-active {
      margin-right: rem(4);
      opacity: 1;
    }

    .swiper-pagination-bullet {
      background-color: var(--base-ligth-blue);
    }

    .swiper-pagination-bullet-active {
      background-color: var(--base-blue);
    }

    .stage-card {
      max-width: 280px;
    }
  }
}
