@import "../../../../../common";

div.modal-avatar {
  max-width: 752px;
  padding-top: rem(48);
  @include tablet() {
    padding-top: rem(38);
    width: calc(100vw - 66px);
    max-width: 692px;
  }
  @include phone() {
    width: 100%;
    padding: 16px;
  }
}

.modal-avatar {
  .crowd-modal__footer {
    margin-top: rem(4);
    @include phone() {
      margin-top: rem(8);
      width: 100%;
      justify-content: center;
      .modal__wrapper-footer {
        width: 100%;
        padding: 0 rem(16);
        display: flex;
        flex-direction: column-reverse;
        button {
          &:nth-child(2) {
            margin-bottom: rem(12);
          }
        }
      }
    }
  }

  &__title {
    color: var(--base-text);
    font: normal normal bold rem(36) / rem(36) Bebas Neue;
    @include tablet() {
      font: normal normal bold rem(32) / rem(32) Bebas Neue;
    }
    @include phone() {
      font: normal normal bold rem(24) / rem(24) Bebas Neue;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    margin-left: rem(-8px);
    margin-right: rem(-8px);

    @include tablet() {
      margin-left: rem(-12px);
      margin-right: rem(-12px);
    }

    @include phone() {
      margin-left: rem(-6px);
      margin-right: rem(-6px);
    }
  }

  &__body {
    margin-top: rem(20) !important;
    @include phone() {
      margin-top: var(--header-height-phone) !important;
    }
  }

  &__avatar {
    width: 100%;
  }

  &__item {
    position: relative;
    overflow: hidden;
    display: inline-block;
    height: 96px;
    width: 96px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #dddddd;
    transition: all 0.2s;
    box-shadow: 0 0 0 3px transparent;
    background-size: cover;
    background-position: center center;
    margin: rem(12px) rem(8px);

    @include tablet() {
      margin: rem(12px) rem(12px);
      height: 100px;
      width: 100px;
    }

    @include adaptive(500) {
      height: 70px;
      width: 70px;
      margin: rem(8px) rem(8px);
    }

    @include adaptive(360) {
      height: 63px;
      width: 63px;
      margin: rem(4px) rem(6px);
    }

    &.selected {
      box-shadow: 0 0 0 2px var(--base-bright-blue);
    }

    &:hover {
      box-shadow: 0 0 0 2px var(--base-bright-blue);
    }

    .ui-icon-photo {
      position: absolute;
      left: 0;
      top: 0;
      font-size: 25px;
      display: flex;
      width: 100%;
      height: 100%;
      color: var(--color-secondary-l2);
      align-items: center;
      justify-content: center;

      &:hover {
        color: var(--base-blue);
      }
    }
  }

  &__label {
    display: inline-block;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    cursor: pointer;

    input {
      display: none;
    }

    img {
      opacity: 0.3;
    }
  }
  &__footer {
    .cr-button {
      margin-top: 0;
      margin-left: rem(20);
      height: 44px;
      @include tablet() {
        margin-left: rem(16);
      }
      @include phone() {
        margin-left: 0;
        margin-top: rem(12);
      }
    }

    @include phone() {
      padding: 0;
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

.modal-avatar-disabled {
  opacity: 0.3;
  pointer-events: none;
}
