@import "../../../../../../common.scss";

.dialog-card {
  $root: &;

  display: flex;
  align-items: center;
  background-color: white;
  transition: background-color 0.3s ease;

  &__wrapper {
    display: flex;
    align-items: center;
    background-color: white;
    width: 100%;
    padding: 24px;
    &:hover {
      background-color: #f9f9f9;
    }

    @include phone() {
      padding: 8px 16px;
    }
  }

  &--deleting {
    pointer-events: none;
    #{$root}__wrapper {
      opacity: 0.6;
    }
  }

  &__message {
    margin-left: 16px;
    margin-right: 8px;
    width: 50%;
  }

  &__name {
    font: normal normal bold 20px/24px PT Sans;
    letter-spacing: 0px;
    color: #282828;

    @include phone() {
      text-align: left;
      font: normal normal bold 16px / 24px PT Sans;
    }
  }

  &__text {
    font: normal normal normal 20px/32px PT Sans;
    letter-spacing: 0px;
    color: #7e7e7e;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;

    @include phone() {
      font: normal normal normal 14px/19px PT Sans;
      letter-spacing: 0px;
    }
  }

  &__info {
    margin-left: auto;
    margin-right: 18px;
  }

  &__time {
    font: normal normal normal 20px/32px PT Sans;
    letter-spacing: 0px;
    color: #7e7e7e;

    @include phone() {
      font: normal normal normal 12px/18px PT Sans;
      letter-spacing: 0px;
    }
  }

  &__count {
    font: normal normal bold 13px/32px PT Sans;
    letter-spacing: 0px;
    color: #ffffff;
    background-color: #ff9425;
    padding: 3px 5px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-left: auto;
    height: 22px;
    min-width: 22px;

    @include phone() {
      font: normal normal bold 11px/13px PT Sans;
      margin-top: 4px;
      height: 20px;
      min-width: 20px;
    }
  }

  &__tools {
    border-left: 1px solid var(--base-border);
    padding-left: 18px;
    height: 52px;
    display: flex;
    align-items: center;

    &-delete {
      .ui-icon-delete {
        color: #797a80;
        font-size: 20px;
      }
    }
  }
}
