@import "../../../../common.scss";

.compose-post {
  width: 60%;
  margin-top: rem(32px);

  &__controls {
    .cr-input,
    .app-editor__root {
      margin-top: rem(32px);
      margin-bottom: rem(32px);

      .app-editor {
        height: 400px;
      }
    }

    .cr-button {
      border: none;
      padding-left: 0;

      @include phone() {
        margin-right: auto;
        width: auto;
      }
    }
  }

  &__time {
    display: flex;
    margin-top: rem(32px);
    margin-left: 1px;

    .cr-datepicker {
      margin-left: rem(16px);
    }
  }

  &__footer {
    margin-top: rem(32px);
    display: flex;
    justify-content: flex-end;

    .cr-button {
      margin-left: 16px;

      @include phone() {
        margin-left: 0;
        margin-top: 12px;
      }
    }
  }

  &__team-post {
    display: flex;
    align-items: center;

    div {
      color: var(--base-text);
    }
  }

  @include tablet() {
    width: 100%;

    &__time {
      .cr-datepicker {
        max-width: 200px;

        input {
          width: 100%;
        }
      }

      .cr-timepicker {
        width: 120px;

        input {
          width: 100%;
        }
      }
    }
  }

  @include phone() {
    margin-top: rem(16);

    &__team-post {
      font: normal normal normal rem(14) / rem(19) PT Sans;
    }

    &__controls {
      .cr-input,
      .app-editor__root {
        margin: rem(24) 0;
      }
    }

    &__time {
      margin-top: rem(16);

      .cr-datepicker {
        width: calc(100% - 75px);
      }

      .cr-timepicker {
        width: 75px;
      }
    }

    &__footer {
      margin-top: rem(24);
      flex-direction: column-reverse;
    }
  }
}
