@import "../../../common.scss";

.page-title {
  margin-top: rem(30px);
  width: 100%;
  text-align: left;
  word-wrap: break-word;

  @include tablet() {
    font: normal normal bold rem(38) / rem(47) Bebas Neue;
  }

  @include phone() {
    font: normal normal bold rem(26) / rem(30) Bebas Neue;
  }
}
