@import "../../../../common.scss";

.faq-card {
  display: flex;
  flex-direction: column;
  border-radius: rem(10px);
  padding: rem(20px) 0;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 rem(16px);
    font: normal normal bold 20px/32px PT Sans;
    letter-spacing: 0px;
    color: var(--base-text);

    &-icon {
      font-size: 30px;
    }
  }

  &__body {
    margin: rem(20px) rem(16px) 0 0;
    padding: rem(16px);
    display: flex;
    flex-direction: column;

    &-heading {
      font: normal normal bold 18px/28px PT Sans;
      letter-spacing: 0px;
      color: var(--base-text);
    }

    &-text {
      font: normal normal normal 16px/22px PT Sans;
      letter-spacing: 0px;
      color: var(--base-text);
      margin-top: rem(8px);
    }
  }

  &__footer {
    margin: rem(16px);

    &-text {
      font: normal normal normal 14px/20px PT Sans;
      letter-spacing: 0px;
      color: #757679;
    }
  }

  .trash-icon {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56"><defs><style>.a,.b,.c{fill:none;}.b,.c{stroke:#000;stroke-miterlimit:10;}.b{stroke-width:0.5px;}.c{stroke-width:0.35px;}</style></defs><g transform="translate(0 -716.358)"><g transform="translate(0 716.358)"><rect class="a" width="56" height="56" transform="translate(0 0)"/><g transform="translate(16.6 13.105)"><path class="b" d="M1381.9,1127.258h-13.526a2.19,2.19,0,0,1-2.181-2.055l-1.216-20.175a.429.429,0,0,1,.4-.453h19.49a.427.427,0,0,1,.429.427.222.222,0,0,1,0,.026l-1.218,20.175A2.188,2.188,0,0,1,1381.9,1127.258Z" transform="translate(-1363.731 -1097.295)"/><path class="b" d="M1386.115,1104.575h-21.956a.428.428,0,0,1-.428-.428v-.958a3.067,3.067,0,0,1,3.063-3.063h16.686a3.066,3.066,0,0,1,3.063,3.063v.958a.428.428,0,0,1-.428.428Z" transform="translate(-1363.731 -1097.295)"/><path class="b" d="M1379.089,1100.126h-7.9a.428.428,0,0,1-.428-.428v-.658a1.747,1.747,0,0,1,1.745-1.745h5.27a1.747,1.747,0,0,1,1.745,1.745v.658A.428.428,0,0,1,1379.089,1100.126Z" transform="translate(-1363.731 -1097.295)"/><path class="c" d="M1379.445,1122.865a.428.428,0,0,1-.428-.428v-12.66a.428.428,0,0,1,.856,0v12.66A.427.427,0,0,1,1379.445,1122.865Z" transform="translate(-1363.731 -1097.295)"/><path class="c" d="M1375.137,1122.865a.428.428,0,0,1-.428-.428v-12.66a.428.428,0,1,1,.856,0v12.66A.428.428,0,0,1,1375.137,1122.865Z" transform="translate(-1363.731 -1097.295)"/><path class="c" d="M1370.829,1122.865a.427.427,0,0,1-.428-.428v-12.66a.428.428,0,0,1,.856,0v12.66A.428.428,0,0,1,1370.829,1122.865Z" transform="translate(-1363.731 -1097.295)"/></g></g></g></svg>');
  }
}
