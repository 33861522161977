@import "../../../common";

div .main {
  .page-title {
    margin-bottom: rem(8);
    @include phone() {
      margin: rem(12) rem(16) rem(0) rem(16);
    }
  }

  &__stats-wrap {
    display: flex;
    justify-content: space-between;
  }

  &__actions {
    display: flex;
    width: 100%;
    margin-top: rem(32);
    @include short-desktop() {
      flex-direction: column;
    }
    @include phone() {
      margin-top: 0;
    }

    &-left {
      display: flex;
      flex-direction: column;
      max-width: 896px;
      width: 100%;
      margin-right: rem(32);
      @include short-desktop() {
        max-width: unset;
      }
      @include phone() {
        margin-left: rem(16);
        margin-right: rem(16);
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      max-width: 432px;
      width: 100%;
      position: relative;
      z-index: 1;
      @include short-desktop() {
        margin-top: rem(24);
        max-width: unset;
      }
      @include phone() {
        margin: rem(20) 0;
      }
    }
  }

  &__slider-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__slider-arrows {
    display: flex;
    width: 72px;
    justify-content: space-between;
    align-items: center;

    .ui-icon:hover {
      cursor: pointer;
    }
  }

  &__participants {
    margin-top: 53px;

    @include tablet() {
      margin-top: 24px;
    }

    @include phone() {
      margin-top: 4px;
      padding: 0 16px;
    }
  }

  &__actual {
    display: flex;
    flex-direction: column;
    margin-top: rem(24);
    padding: rem(16) rem(24) rem(24);
    background: var(--base-ligth-blue);
    max-height: 291px;
    height: 100%;
    border-radius: 4px;
    @include phone() {
      margin-top: rem(20);
      padding: rem(16);
      border-radius: unset;
    }

    &-empty {
      max-height: 121px;
    }

    &-noposts {
      margin-top: rem(24);
      font: normal normal normal rem(20) / rem(32) PT Sans;
      letter-spacing: 0;
      color: var(--base-text);
      @include phone() {
        margin-top: rem(16);
        font: normal normal normal rem(16) / rem(20) PT Sans;
      }
    }

    &-container {
      width: 100%;
      margin-top: rem(16);
    }

    &-item {
      display: flex;
      flex-direction: column;

      &-title h5 {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &-date {
        font: italic normal normal rem(18) / rem(18) PT Sans;
        color: var(--color-secondary-l2);
        margin-top: rem(4);
        margin-bottom: rem(6);
        @include phone() {
          font: italic normal normal rem(14) / rem(20) PT Sans;
        }
      }

      &-text {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font: normal normal normal rem(20) / rem(32) PT Sans;
        color: var(--base-text);
        margin-bottom: 1.5rem;
        word-break: break-word;
        height: 95px;

        @include phone() {
          height: auto;
          font: normal normal normal rem(16) / rem(20) PT Sans;
        }

        ul,
        ol {
          margin-left: rem(32);
        }
      }

      &-more {
        font: normal normal bold rem(18) / rem(24) PT Sans;
        color: var(--base-blue);
        margin: auto 0 0 auto;
        @include phone() {
          font: normal normal bold rem(16) / rem(20) PT Sans;
        }
      }
    }
  }

  &__experts {
    .main__slider-top {
      padding: 16px 0;
      @include phone() {
        padding: rem(16);
      }
    }

    &-item {
      display: flex;
      align-items: center;
      background: var(--base-ligth-blue);
      padding: rem(16);
      height: 109px;
      cursor: pointer;
      min-height: 86px;
      border-radius: 4px;
      @include phone() {
        height: auto;
        padding: rem(12);
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      margin-left: rem(16);
      color: var(--base-text);
      max-width: 236px;
      @include tablet() {
        margin-left: rem(12);
      }
      @include phone() {
        margin-left: rem(8);
      }
    }

    &-name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font: normal normal bold rem(20) / rem(28) PT Sans;
      @include tablet() {
        font: normal normal bold rem(16) / rem(22) PT Sans;
      }
      @include phone() {
        text-overflow: initial;
        white-space: initial;
        overflow: initial;
        font: normal normal bold 16px/22px PT Sans;
      }
    }

    &-position {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-top: 0.125rem;
      font: normal normal normal rem(18) / rem(24) PT Sans;
      @include tablet() {
        font: normal normal normal rem(14) / rem(20) PT Sans;
      }
    }

    &-container {
      @include phone() {
        padding: 0 rem(16);
      }
    }
  }

  &__white-block {
    display: flex;
    flex-direction: column;
  }

  &__discussions {
    position: relative;
    padding: rem(40) 0;
    @include phone() {
      padding: rem(20) 0;
    }

    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include phone() {
        padding: 0 rem(16);
      }
    }

    &-tabs {
      display: flex;
      padding: 0 1px;
    }

    &-tab {
      display: flex;
      align-items: center;
      justify-content: center;
      font: italic normal normal rem(18) / rem(23) PT Sans;
      margin-left: 1rem;
      padding: 0.563rem 1.25rem;
      border-radius: 20px;
      color: var(--base-blue);
      border: 1px solid var(--base-blue);
      cursor: pointer;

      &.active {
        color: white;
        background: var(--base-blue);
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      margin-top: rem(32);
      align-items: center;
      @include short-desktop() {
        margin-top: rem(20);
      }
    }

    &-item {
      width: 100%;
      max-width: 1128px;
      margin-bottom: rem(32);
    }

    &-title {
      display: flex;
      width: fit-content;
      margin-bottom: rem(24);
      font: normal normal bold rem(24) / rem(32) PT Sans;
      letter-spacing: 0px;
      @include tablet() {
        font: normal normal bold rem(22) / rem(29) PT Sans;
      }
      @include phone() {
        font: normal normal bold rem(18) / rem(23) PT Sans;
        padding: 0 rem(16);
      }
    }

    &-posts {
      display: flex;
      flex-direction: column;

      .element-observer__item,
      .comment-card {
        &:not(:last-child) {
          margin-bottom: rem(32);
        }
      }

      .comment-card {
        @include phone() {
          margin-bottom: rem(16);
        }
      }

      &_null {
        text-align: center;
        font: italic normal normal rem(18) / rem(24) PT Sans;
        color: #757679;
        margin-top: 1rem;
      }

      &__all-answers {
        margin-top: rem(16);
        display: flex;
        flex-direction: row-reverse;
        @include phone() {
          margin-top: 0;
          justify-content: center;
        }

        a {
          color: var(--base-blue);
          font: normal normal bold rem(18) / rem(24) PT Sans;
          letter-spacing: 0px;
          @include tablet() {
            font: normal normal bold rem(16) / rem(24) PT Sans;
          }
          @include phone() {
            font: normal normal bold rem(14) / rem(19) PT Sans;
          }
        }
      }
    }

    &-more {
      display: flex;
      align-self: flex-end;
      font: normal normal bold rem(18) / rem(24) PT Sans;
      color: var(--base-blue);
      cursor: pointer;
      margin-top: rem(16);
    }
  }

  @include phone() {
    .item__stats-main {
      margin-left: rem(16);
      margin-right: rem(16);
      font: normal normal normal rem(14) / rem(20) PT Sans;
    }
  }
}
