@import "../../../../common.scss";

.blog-modal.crowd-modal {
  width: 896px;
  overflow: visible;
  @include adaptive(885) {
    width: 100%;
    max-width: unset;
    margin: auto;
    padding: rem(24);
    height: 100%;
  }
  @include adaptive(600) {
    padding: rem(10);
  }
  @include phone() {
    padding: 1rem;
    margin: 0;
    max-width: 100vw;
    min-height: 100%;
    height: unset;

    .blog-modal__footer > div {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      .cr-button {
        &:not(:first-child) {
          margin-bottom: 0;
        }
      }
    }
  }

  .cr-input {
    margin-top: rem(32px);
    @include phone() {
      margin-top: rem(50);
    }
  }

  .cr-select {
    .ui-icon-arrow-slider-left {
      top: 24px;
    }
  }

  .app-editor__root {
    margin-top: rem(32);
    @include phone() {
      margin-top: rem(16);
    }

    .app-editor {
      height: 290px;
      padding: rem(16);

      .rdw-image-imagewrapper img {
        max-width: 500px;
      }
    }
  }
  .blog-modal__title {
    padding-left: rem(16);
    margin-top: rem(-16);
    padding-top: rem(16);
    background-color: #fff;
  }
  .blog-modal__footer {
    .submit-form__footer {
      .cr-button {
        &:nth-child(1) {
          margin-left: 0;
        }

        @include adaptive(770) {
          padding: rem(11) rem(10);
        }
        @include adaptive(660) {
          padding: rem(8) rem(8);
          margin-left: 10px;
        }
        @include adaptive(630) {
          padding: rem(4) rem(4);
          margin-left: 5px;
        }
        @include phone() {
          margin-left: 0;
          padding: var(--button-mobile-padding);
        }
      }
    }
  }
}
