@import "../../../../common.scss";

.my-ideas {
  &__exact {
    .cr-back-button {
      @include phone() {
        margin: rem(16px) rem(16) 0;
      }
    }

    .proposal-card {
      margin-top: rem(16px);
    }
  }

  &__list {
    max-width: 1128px;
  }

  .profile-sub__title,
  .my-ideas__paging {
    @include tablet() {
      padding-left: rem(24);
      padding-right: rem(24);
    }
    @include phone() {
      padding-left: rem(16);
      padding-right: rem(16);
    }
  }

  .profile-sub__tabs {
    @include tablet() {
      width: 100%;

      .tabs {
        width: 100%;
        padding-left: rem(24);
        padding-right: rem(24);
      }
    }
  }
}
