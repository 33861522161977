@import "../../../../common.scss";

.generation {
  &__propose {
    &-title {
      margin-bottom: rem(24);
      font: normal normal bold rem(20) / rem(32) PT Sans;

      @include phone() {
        margin-bottom: rem(16);
        font: normal normal bold rem(18) / rem(22) PT Sans;
      }
    }

    &-description {
      font-size: rem(20px);
      line-height: rem(32px);
      margin-bottom: rem(48px);

      @include phone() {
        font: normal normal normal rem(16) / rem(20) PT Sans;
        &__title {
          font: normal normal bold rem(20) / rem(26) PT Sans;
        }
      }
    }

    &-link {
      color: var(--base-blue);
    }

    &-form {
      display: flex;
      flex-flow: column;

      @include phone() {
        margin-top: rem(16);

        div.cr-input,
        div.cr-textarea {
          margin-bottom: rem(16);
        }
        .cr-input__length {
          margin-top: rem(6);
        }
      }
      .spell-checker__backdrop {
        overflow-y: hidden;
      }
      &__description {
        display: inline-block;
        font-size: rem(18px);
        line-height: rem(28px);
        margin-bottom: rem(32px);
        color: var(--textarea-hint-color);
        @include phone() {
          font-size: rem(14);
          line-height: rem(19);
          margin-bottom: rem(20);
        }
      }

      &__idea {
        &-description,
        &-title {
          font-size: rem(18px);
          padding: rem(16px);
          border: 1px solid var(--base-blue);
          border-radius: 4px;
        }

        &-description {
          resize: none;
          height: rem(160px);
        }

        &-title {
          height: rem(56px);
        }
      }
      //
      &__upload {
        display: inline-flex;
        color: var(--color-primary);
        font-weight: bold;
        font-size: rem(18px);
        cursor: pointer;

        @include phone() {
          font: normal normal bold rem(14) / rem(19) PT Sans;
        }

        .ui-icon {
          margin-right: rem(12px);
          font-size: rem(24px);
        }

        input {
          display: none;
        }
      }

      &__controls {
        align-self: flex-end;
        margin-top: 2rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        button:not(:last-of-type) {
          margin-right: rem(16);
        }

        @include phone() {
          width: 100%;
          margin-top: rem(24);
          flex-direction: column-reverse;

          button.cr-button {
            margin-right: 0;
          }

          button:not(:first-of-type) {
            margin-bottom: rem(16);
          }
        }
      }

      &__hint {
        font-size: rem(14px);
        color: var(--color-secondary-l2);
        padding: 0 rem(16px);
        margin-bottom: rem(32px);
      }

      textarea {
        resize: none;
        width: 100%;
        height: rem(160px);
      }
    }
  }

  &__linkIcon {
    color: var(--base-gray);
    margin-bottom: 1rem;
    float: left;
    position: relative;
  }

  &__propose-description.empty {
    display: none;
  }
  .link-popup__inputs {
    margin-top: 24px;
  }
  .spell-checker {
    margin-bottom: 2rem;
  }

  @include tablet() {
    .link-popup {
      right: inherit;
    }
  }

  @include phone() {
    div.generation__content-switch {
      margin-bottom: 0;
    }

    .page-title {
      margin-top: 0;
    }
  }
}
