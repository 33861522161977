@import "../../../common.scss";

.spell-checker {
  display: block;
  margin: 0 auto;
  transform: translateZ(0);
  -webkit-text-size-adjust: none;
  width: 100%;
  height: 100%;

  &__backdrop {
    position: absolute;
    z-index: 1;
    background-color: #fff;
    overflow: auto;
    pointer-events: none;
    transition: transform 1s;
    width: 100%;
    height: 100%;
  }

  &__highlight {
    padding: 16px;
    height: 100%;
    white-space: pre-wrap;
    word-wrap: break-word;
    color: transparent;
    font: var(--textarea-font);
  }

  &__element {
    height: 100%;
  }

  &__element .cr-textarea,
  &__element .cr-input,
  &__element .textAreaEditor {
    height: 100%;

    &__input,
    &__editable {
      width: 100%;
      display: block;
      position: absolute;
      z-index: 2;
      margin: 0;
      background-color: transparent;
      overflow: auto;
      resize: none;
      transition: transform 1s;
    }

    &__length,
    .app-editor__hint {
      position: absolute;
      bottom: -22px;
      z-index: 10;

      @include phone() {
        bottom: -25px;
      }
    }
  }

  mark {
    color: transparent;
    background-color: transparent;
    border-bottom: 2px solid red;
    position: relative;
  }
}
