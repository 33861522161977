@import "../../../common.scss";

.actual-page {
  &__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include phone() {
      padding: 0 16px;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding-bottom: rem(40px);
    position: relative;
    @include afterBeforeBackground(white);
  }

  &__posts {
    width: 70%;
    padding-right: 32px;
  }

  &__expbtn {
    padding-top: rem(40px);
    position: relative;
    background-color: white;
    @include afterBeforeBackground(white);

    @include phone() {
      margin: 0 16px;
    }
  }

  &__content {
    width: 100%;
  }

  &__empty {
    width: 100%;
    padding: rem(48) 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-title {
    position: relative;
    margin-top: 0;
    background-color: white;
    padding-top: 2.5rem;
    @include afterBeforeBackground(white);
  }

  &__linked {
    margin-top: rem(40);

    .expert-post-card {
      max-width: 1128px;
      margin: rem(32) auto;
    }
  }

  //Overrides

  .calendar-stripe {
    &__controls {
      height: 50%;
      top: 40px;
    }
  }

  .post-card img {
    margin-top: 0;
  }

  @include tablet() {
    .page-title {
      padding-top: rem(16);
    }

    &__linked {
      .expert-post-card {
        margin: rem(16) 0;
      }
    }

    &__posts {
      width: initial;
      padding-right: initial;
    }

    &__top {
      flex-direction: column;
    }

    .extra-nav {
      border-radius: 4px;
      max-width: unset;
    }
  }

  @include phone() {
    .page-title {
      padding: rem(16);
    }

    &__linked {
      margin: rem(12) 0;

      h3 {
        margin-top: rem(4);
        margin: 4px 0 0;
      }

      .expert-card-list {
        margin: 0;
      }

      .expert-post-card {
        margin: rem(16) 0;
        position: relative;
        padding-left: 0;
        padding-right: 0;
        @include afterBeforeBackground(white);
      }

      .expert-post-card:last-of-type {
        margin-bottom: 0;
      }
    }

    &__expbtn {
      padding: 0;
    }

    &__top {
      padding: 0 0 rem(20);
    }

    .extra-nav {
      border-radius: unset;
      position: relative;
      padding-left: 0;
      padding-right: 0;
      @include afterBeforeBackground(white);
    }
  }
}
