@import "../../../../common";

.preview-modal {
  &__title {
    margin-bottom: rem(16);
    font: var(--modal-subhead-font);
  }

  &__body {
    pointer-events: none;
    word-break: break-word;

    p {
      min-height: 1em;
    }

    ul,
    ol {
      margin-left: rem(32px);
    }
  }

  &__main {
    div[style="text-align:none;"] {
      text-align: center !important;
    }

    img {
      max-width: 500px;

      @include phone() {
        max-width: 200px;
      }
    }
  }

  &__attachments {
    display: flex;
    margin-top: rem(16);
    pointer-events: auto;
  }
}
