.dotter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &-dot {
    height: 6px;
    width: 6px;
    background-color: #dbf4ff;
    border-radius: 100%;
    margin: 0 5px;

    &--active {
      background-color: #65aae0;
    }
  }
}
