@import "../../../../common.scss";

.cr-datepicker {
  position: relative;
  width: fit-content;

  &__position {
    &--left {
      .DayPickerInput-Overlay {
        right: 0;
        left: auto;
      }
    }
    &--right {
      .DayPickerInput-Overlay {
      }
    }
  }
}

.DayPickerInput-Overlay {
  z-index: 2;
}

.DayPicker-Months {
  flex-wrap: nowrap;
}

.PickerTo,
.PickerFrom {
  .DayPickerInput {
    width: 100%;
  }
}
.PickerTo {
  .DayPickerInput {
    input {
      padding-left: 0;
    }
  }
}

.PickerFrom {
  display: flex;
  align-items: center;
  &-dash {
    margin: 0 4px;
    z-index: 2;
  }
  .DayPickerInput {
    input {
      padding-left: rem(16);
      padding-right: 0;
    }
  }
}
@include phone() {
  .RangedDatePicker {
    width: 100%;
    position: relative;
  }
  .PickerFrom,
  .PickerTo {
    .DayPickerInput-OverlayWrapper {
      position: unset;
    }
    .DayPickerInput-Overlay {
      width: 320px;
      padding: rem(16);
      left: 50%;
      right: 50%;
      transform: translate(-50%, 0%);

      .DayPicker {
        width: 100%;
        .DayPicker-wrapper {
          padding-bottom: rem(48);
        }
        .RangedDatePicker__footer {
          margin: 0;
        }
        .DayPicker-Months {
          flex-wrap: nowrap;
          .DayPicker-Month {
            margin: 0;
            width: 288px;
            &:nth-child(2) {
              display: none;
            }
            .DayPicker-Weekdays {
              font: normal normal normal rem(12) / rem(15) PT Sans;
            }
            .DayPicker-Body {
              font: normal normal normal rem(16) / rem(20) PT Sans;
            }
            .DayPicker-Day {
              padding: rem(12) rem(12) rem(12);
            }
            .DayPicker-Caption {
              text-align: center;
              margin-bottom: rem(19);
              letter-spacing: 2px;
              padding: rem(4) rem(8);
              & > div {
                font: normal normal bold rem(20) / rem(26) PT Sans;
              }
            }
          }
        }
      }
      .DayPicker-NavButton {
        top: rem(5);
        &--next {
          right: 0;
        }
        &--prev {
          left: 0;
        }
      }
    }
  }
  .PickerTo {
    text-align: right;
  }
}
