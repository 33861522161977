.user-crumb {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 6px;
  border-radius: 6px;
  background-color: var(--color-primary-l1);

  &__name {
    font: normal normal normal 18px/23px PT Sans;
  }

  .ui-icon-close {
    cursor: pointer;
  }
}
