@import "../../../../../common.scss";

.reject-reason {
  font: normal normal normal rem(20) / rem(32) PT Sans;
  letter-spacing: 0;
  color: var(--base-text);
  background-color: var(--base-ligth-gray);
  margin-top: rem(24);
  padding: rem(16) rem(24);
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  border-radius: 4px;
  &__title {
    font-weight: bold;
    &:not(:first-child) {
      margin-top: 16px;
    }
  }
  &__text {
    margin-top: 8px;
  }
  &__hint {
    color: var(--base-red);
    font: italic normal normal 18px/23px PT Sans;
    letter-spacing: 0px;

    @include tablet() {
      font: italic normal normal 16px/20px PT Sans;
    }

    @include tablet() {
      font: italic normal normal 14px/18px PT Sans;
    }
  }

  @include tablet() {
    font: normal normal normal rem(18) / rem(24) PT Sans;
  }
  @include phone() {
    font: normal normal normal rem(16) / rem(20) PT Sans;
  }
}
