@import "../../../common.scss";

.groups {
  &__row {
    display: grid;
    grid-template-columns: 40px 1fr;
    align-items: flex-start;

    @include phone() {
      grid-template-columns: 24px 1fr;
    }

    &:not(:first-child) {
      margin-top: 8px;

      @include phone() {
        margin-top: 4px;
      }
    }

    &__key {
      font: normal normal bold 14px/28px PT Sans;
      letter-spacing: 0.11px;
      color: var(--base-text);
    }

    &__items {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: -8px;

      > * {
        margin-bottom: 8px;
        &:not(:last-child) {
          margin-right: 24px;
        }
      }
    }
  }
}
