@import "../../../../../common.scss";

.selection-accept-form {
  label {
    @include phone() {
      line-height: 18px;
    }
  }

  .cr-input {
    @include tablet-only() {
      margin-top: 16px;
    }
  }

  .groups {
    margin-top: 12px;

    @include tablet() {
      margin-top: 16px;
    }
  }

  &__buttons {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;

    @include phone() {
      margin-top: 1.25rem;
    }

    > *:first-child {
      margin-left: auto;
    }
  }
}
