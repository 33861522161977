@import "../../../common";

.dy-user-list {
  position: relative;
  z-index: 1;

  &__item {
    display: flex;
    align-items: center;
    padding: rem(3) rem(5);
    border-bottom: 1px solid var(--base-border);
    cursor: pointer;
  }

  &__item:hover {
    background-color: var(--color-primary-l1);
  }

  &__user-name {
    margin-left: rem(5);
    font-size: 1rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: rem(10) 0;
  }

  .cr-dynamo-list {
    &__list {
      max-height: 45vh !important;
      overflow-y: auto;
      
      @include scrollbar(5px, 4px);

      @include phone() {
        max-height: unset !important;
      }
    }
  }
}