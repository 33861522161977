.blog-modal {
  .cr-select {
    .cr-select__placeholder {
      .ui-icon-arrow-slider-left {
        right: 20px;
        top: 22px;
        font-size: 16px;
      }
    }
  }
}
