@import "../../../../common.scss";

.expert-post-card {
  border-radius: 4px;

  @include phone {
    border-radius: unset;
  }

  .user-avatar__container {
    margin-right: rem(16);
    @include phone() {
      margin-right: rem(8);
    }
  }

  &__body {
    margin-top: rem(32);

    @include tablet() {
      margin-top: rem(16);
    }
  }

  &__footer {
    margin-top: rem(32);

    @include phone() {
      margin-top: rem(24);
    }
  }

  @include phone() {
    padding: rem(16);
  }
}
