@import "../../../../common.scss";

.profile-favorite-card {
  &.post-card {
    @include phone() {
      margin-top: rem(16);
      padding: rem(16);
    }
  }

  &__header {
    justify-content: space-between;

    a {
      color: #65aae0;
      font: normal normal bold rem(20) / rem(32) PT Sans;
      @include phone() {
        font: normal normal bold rem(16) / rem(22) PT Sans;
      }
    }
  }

  &__body {
    margin-top: rem(12px);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    p {
      font: normal normal normal rem(18) / rem(24) PT Sans;
      @include tablet() {
        font: normal normal normal rem(16) / rem(20) PT Sans;
      }
      @include phone() {
        font: normal normal normal rem(16) / rem(20) PT Sans;
      }
    }
  }

  &__footer {
    margin-top: rem(24);
    @include tablet() {
      margin-top: rem(29);
    }
    @include phone() {
      margin-top: rem(16);
    }
  }
}
