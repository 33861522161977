@import "../../../common.scss";

.pagination {
  display: flex;
  list-style: none;
  justify-content: center;

  &__page,
  .break {
    font: normal normal normal rem(18) / rem(24) PT Sans;
    letter-spacing: 0px;
    color: var(--base-blue);
    cursor: pointer;
    margin: 0 rem(8px);
    @include tablet() {
      font: normal normal normal rem(16) / rem(24) PT Sans;
    }
    @include phone() {
      font: normal normal normal rem(14) / rem(19) PT Sans;
    }
    a {
      outline: none;
    }
    &.active {
      font-weight: bold;
      letter-spacing: 0px;
      color: var(--base-text);
    }
  }

  .directions {
    color: #888888;
    cursor: pointer;
    position: relative;
    a {
      outline: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
