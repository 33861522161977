@import "../../../../common.scss";

.conversations {
  margin-top: 32px;

  @include phone() {
    margin-top: 16px;
  }

  &__title {
    font: normal normal bold 28px/32px PT Sans;
    letter-spacing: 0px;
    color: #282828;

    @include phone() {
      margin-left: 16px;
      font: normal normal bold 20px/26px PT Sans;
      letter-spacing: 0px;
    }
  }

  &__wrapper {
    width: 85%;
    margin: 0 auto;

    @include phone() {
      width: 100%;

      .cr-dynamo-list {
        padding: 0;
        margin: 16px 16px 0 16px;
      }
    }
  }

  .dialog-list {
    margin-top: 32px;

    @include phone() {
      margin-top: 16px;
    }
  }

  .pagination {
    margin-top: 32px;
  }
}

.chat-create-dialog__modal {
  .data-loader-wr {
    position: relative;
  }
}