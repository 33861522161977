@import "../../../../../common.scss";

.selection-edit-tag-form {
  .cr-input {
    @include tablet-only() {
      margin-top: 16px;
    }
  }

  .cr-button {
    margin: 24px 0 0 auto;
    display: flex;

    @include phone() {
      margin-top: 20px;
    }
  }
}
