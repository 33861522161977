@import "../../../common";

.speech {
  .page-title {
    margin-top: rem(40);
    @include tablet() {
      margin-top: rem(32);
    }
    @include phone() {
      margin-top: rem(16);
      padding: 0 rem(16);
    }
  }
  &__wrapper {
    display: flex;
    background: white;
    padding: rem(32) rem(48) rem(48);
    color: var(--base-text);
    margin-top: rem(48);
    border-radius: 4px;
    @include tablet() {
      flex-direction: column;
      padding: rem(24);
      margin-top: rem(32);
    }
    @include phone() {
      margin-top: rem(16);
      padding: rem(16);
      border-radius: unset;
    }
  }

  &__customer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 336px;
    gap: rem(24);
    @include tablet() {
      flex-direction: row;
      align-items: flex-start;
    }
    @include phone() {
      flex-direction: column;
      align-items: center;
      gap: rem(16);
      min-width: unset;
      width: 100%;
    }

    .user-avatar__xxl {
      @include tablet() {
        width: rem(162);
        min-width: rem(162);
        height: rem(162);
      }
      @include phone() {
        width: rem(140);
        min-width: rem(140);
        height: rem(140);
      }
    }

    &_text {
      display: flex;
      flex-direction: column;
      gap: rem(16);

      &-name {
        font: normal normal bold rem(20) / rem(32) PT Sans;
        letter-spacing: 0.15px;
        text-align: center;
        @include tablet() {
          font: normal normal bold rem(18) / rem(26) PT Sans;
        }
        @include phone() {
          font: normal normal bold rem(16) / rem(22) PT Sans;
        }
      }

      &-position {
        font: italic normal normal rem(18) / rem(28) PT Sans;
        letter-spacing: 0;
        color: var(--textarea-hint-color);
        text-align: center;
        width: 100%;
        @include tablet() {
          font: italic normal normal rem(16) / rem(22) PT Sans;
          text-align: left;
        }
        @include phone() {
          font: italic normal normal rem(14) / rem(19) PT Sans;
          text-align: center;
        }
      }
      @include tablet() {
        align-items: flex-start;
      }
      @include phone() {
        gap: rem(12);
        align-items: center;
      }
    }

    &-info {
      font: italic normal normal rem(18) / rem(28) PT Sans;
      letter-spacing: 0;
      text-align: center;
      margin-top: rem(32);
    }

    &-icon {
      cursor: pointer;
      margin-top: rem(24);
      color: var(--textarea-hint-color);

      &_revert {
        transform: rotate(180deg);
      }
    }
  }

  &__content {
    font: normal normal normal rem(20) / rem(32) PT Sans;
    letter-spacing: 0;
    color: var(--base-text);
    margin-left: rem(80);
    @include tablet() {
      font: normal normal normal rem(18) / rem(24) PT Sans;
      margin-left: 0;
      margin-top: rem(32);
    }
    @include phone() {
      font: normal normal normal rem(16) / rem(20) PT Sans;
      margin-top: rem(20);
    }

    b {
      font-weight: bold;
    }
    a {
      color: var(--base-blue);
    }
  }
}
