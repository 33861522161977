@import "../../../../common.scss";

.extra-nav {
  letter-spacing: 0;
  position: relative;
  margin-top: rem(48);
  max-width: 364px;

  &__title {
    font: normal normal bold rem(24) / rem(32) PT Sans;
    color: var(--base-text);

    @include phone() {
      font: normal normal bold 20px/26px PT Sans;
      letter-spacing: 0px;
    }
  }

  &__post {
    cursor: pointer;
    padding: rem(24) 0;

    @include phone() {
      padding: rem(16) 0;
    }

    &-title {
      font: normal normal bold rem(20) / rem(32) PT Sans;
      color: var(--base-blue);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include phone() {
        font: normal normal bold 16px/22px PT Sans;
        letter-spacing: 0px;
        color: var(--base-text);
      }
    }

    &-time {
      margin-top: rem(4);
    }
  }

  &__more {
    font: normal normal bold rem(18) / rem(32) PT Sans;
    color: var(--base-blue);
    text-align: end;

    a {
      @include phone {
        font: normal normal bold 16px/20px PT Sans;
        letter-spacing: 0px;
      }
    }
  }

  @include tablet() {
    background-color: var(--base-white);
    padding: rem(16);
    margin-top: rem(16);
  }

  @include tablet() {
    margin-top: rem(12);
  }
}
