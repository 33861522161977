$scroll-top-button-color: #54c3f6;

.scroll-top-button {
  position: relative;
  border: 1px solid $scroll-top-button-color;
  border-radius: 50px;
  cursor: pointer;
  background: $scroll-top-button-color;
  opacity: 0.7;
  width: 64px;
  height: 64px;

  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }

  &__wrapper {
    position: fixed;
    bottom: 50px;
    z-index: 100;
    right: 43px;
  }

  .ui-icon-arrow-up {
    font-size: 24px;
  }

  .ic {
    color: white;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transform: rotate(-90deg);
  }
}
