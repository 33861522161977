@import "../../../../common";
.message-modal {
  width: 800px;
  color: var(--base-text);
  &__title {
    padding-left: rem(16);
    margin-top: rem(-16);
    padding-top: rem(16);
    background-color: #fff;
  }
  .cr-textarea__input {
    width: 100%;
  }

  &__file-selector {
    display: flex;
    i {
      margin-right: rem(12);
    }
  }

  .cr-input:first-of-type {
    margin-top: rem(32);
  }

  .cr-file-selector__files {
    margin-top: 0;
  }

  .cr-file-preview {
    margin-top: rem(32);
    margin-bottom: 0;
  }

  &__user {
    display: flex;
    align-items: center;
    overflow: hidden;

    &-name {
      margin-left: rem(12);
      font-weight: 700;
    }
  }

  @include phone() {
    &__subhead {
      margin-top: rem(50) !important;

      .tabs {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 0;
        button {
          margin: 0;
        }
      }
    }
    .crowd-modal__body {
      margin-top: 0;
    }
    &__body {
      margin-top: 0 !important;
      .cr-textarea {
        margin-top: rem(32);
        &__length {
          line-height: rem(16);
        }
      }
    }

    &__footer {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
