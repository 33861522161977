@import "../../../common.scss";

.expander {
  $root: &;

  background-color: var(--color-secondary-l6);
  padding: 12px 24px;
  border-radius: 4px;

  @include tablet-only() {
    padding: 15px 24px;
  }

  @include phone() {
    padding: 13px 16px 12px;
  }

  .cr-button {
    padding: 0;

    .ic {
      margin-right: 16px;
      font-size: 24px;

      @include phone() {
        align-self: flex-start;
        margin-right: 8px;
      }
    }
  }

  &__title {
    .cr-button {
      font-size: 20px;
      line-height: 32px;
      text-transform: none;

      @include tablet-only() {
        font-size: 18px;
        line-height: 24px;
      }

      @include phone() {
        font-size: 16px;
        white-space: normal;
        text-align: left;
        line-height: 20px;
      }
    }
  }

  &__hidden-wrapper {
    display: none;
    .cr-button {
      font-size: 18px;
      line-height: 24px;

      @include tablet-only() {
        font-size: 16px;
      }

      @include phone() {
        font-size: 14px;
      }

      .ic {
        font-size: 24px;

        @include tablet-only() {
          margin-right: 12px;
        }

        @include phone() {
          font-size: 22px;
          margin-right: 6px;
        }
      }
    }
  }
  &-opened {
    #{$root}__hidden-wrapper {
      display: unset;
    }
  }

  &__hiddent-element {
    padding: 18px 0 18px 40px;
    color: var(--base-text);
    font: normal normal normal 20px/26px PT Sans;

    @include phone() {
      padding: 9px 0 18px 32px;
    }
  }
}
