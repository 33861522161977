@import "../../../../common.scss";

.my-blog {
  &__content {
    @include tablet() {
      margin-top: rem(32);
    }
    @include phone() {
      margin-top: 0;
    }
  }
}
