@import "../../../common";

.rules {
  .page-title {
    margin-top: rem(40);
    @include tablet() {
      margin-top: rem(32);
    }
    @include phone() {
      margin-top: rem(16);
      padding: 0 rem(16);
    }
  }
  &__wrapper {
    margin-top: rem(48);
    font: normal normal normal rem(20) / rem(32) PT Sans;
    letter-spacing: 0;

    // strong {
    //   font: normal normal bold 28px/32px PT Sans;
    //   letter-spacing: 0.21px;
    //   color: var(--base-text);
    // }

    // > ol > li {
    //   font: normal normal bold 28px/32px PT Sans;
    //   letter-spacing: 0.21px;
    //   color: var(--base-text);
    //   list-style: none;
    // }

    a {
      color: var(--base-blue);
    }

    img {
      width: auto;
      max-width: 100%;
      @include phone() {
        width: 50px;
        height: 50px;
      }
    }
    @include tablet() {
      font: normal normal normal rem(18) / rem(24) PT Sans;
      margin-top: rem(32);
    }
    @include phone() {
      font: normal normal normal rem(16) / rem(20) PT Sans;
      padding: 0 rem(16);
      margin-top: rem(24);
      p,
      li,
      ol {
        font: normal normal normal rem(16) / rem(20) PT Sans;
      }
      tr {
        min-width: 288px;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        p {
          text-align: center;
        }
      }
      td {
        width: 33%;
        font: normal normal normal rem(14) / rem(20) PT Sans;
        p {
          font: normal normal normal rem(14) / rem(20) PT Sans;
        }
      }
    }
  }
}
