@import "../../../../../common.scss";

.selection-tag-switcher {
  margin-left: auto;
  display: flex;
  gap: 24px;
  align-items: center;
  font: normal normal bold 18px/24px PT Sans;
  color: var(--color-primary);

  @include tablet-only() {
    font-size: 16px;
  }

  @include phone() {
    justify-content: space-between;
    margin-bottom: 12px;
    font-size: 0.875rem;
  }

  > * {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 12px;

    .ui-icon {
      @include phone() {
        font-size: 20px;
      }
    }

    @include phone() {
      gap: 8px;
    }
  }
}
