@import "../../../../common.scss";

.team__coordinators__card {
  display: flex;
  justify-content: center;
  margin-top: rem(24);
  position: relative;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include phone() {
      flex-direction: row;
      align-items: flex-start;
      width: 100%;
    }

    .fade-in {
      display: flex;
      justify-content: center;
      @include phone() {
        justify-content: flex-start;
      }
    }
  }

  &__picture {
    border-radius: 50%;
    width: 192px;
    height: 192px;
    background-color: var(--base-border);
    @include tablet() {
      width: 176px;
      height: 176px;
    }
    @include phone() {
      width: 66px;
      height: 66px;
      margin-right: rem(8);
    }
  }
  &__block {
    display: flex;
    flex-direction: column;
    margin-left: rem(8);
    @include phone() {
      align-items: flex-start;
    }
  }
  &__name {
    display: flex;
    flex-direction: column;
    align-items: center;
    font: normal normal bold rem(20) / rem(24) PT Sans;
    letter-spacing: 0px;
    color: var(--base-text);
    margin-top: rem(24);
    margin-bottom: rem(8);

    @include tablet() {
      font: normal normal bold rem(18) / rem(26) PT Sans;
    }
    @include phone() {
      margin-top: 0;
      align-items: center;
      flex-direction: row;
      gap: 8px;
      justify-content: flex-start;
      font: normal normal bold rem(16) / rem(22) PT Sans;
    }
  }

  &__last-name {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  &__project-role {
    font: italic normal normal rem(18) / rem(24) PT Sans;
    letter-spacing: 0px;
    color: #757679;
    text-align: center;
    min-height: 44px;
    visibility: visible;

    &.invisible {
      visibility: hidden;
    }

    @include tablet() {
      font: italic normal normal rem(16) / rem(22) PT Sans;
    }
    @include phone() {
      min-height: auto;
      font: italic normal normal rem(14) / rem(20) PT Sans;
    }
  }

  button {
    position: absolute;
  }

  @include phone() {
    justify-content: flex-start;
    flex-direction: row;
    margin-top: rem(16);
  }
}
