@import "../../../../common.scss";

.expert-card-list {
  width: 100%;
  max-width: 1128px;
  margin: rem(32px) auto 0;
  @include phone() {
    .post-card__header {
      .user-avatar__s {
        width: rem(40);
        min-width: rem(40);
        height: rem(40);
      }
    }
  }
}
