@import "../../../common.scss";

.link-popup {
  background: white;
  width: 400px;
  max-width: 90vw;
  z-index: 100;
  position: absolute;
  bottom: 30px;
  box-shadow: 0px 0px 8px #00000029;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  font: normal normal bold 1.25rem/1.75rem PT Sans;
  color: var(--base-text);

  @include adaptive(840) {
    right: -30vw;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    display: flex;
    margin-top: 32px;
  }
  .cr-button:not(:first-child) {
    margin-left: 1rem;
  }

  .cr-input {
    &__tooltip {
      font-size: 15px;
      padding: 7px;
      top: 63px;
    }
  }
}
