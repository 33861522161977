@import "../../../common.scss";

.team-blogs {
  &__head {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    @include tablet() {
      flex-direction: column;

      .page-title {
        margin-bottom: rem(32);
      }
    }
    @include phone() {
      flex-direction: column;
      align-items: center;
      margin: rem(16);

      .page-title {
        margin-bottom: 0;
        margin-top: 0;
      }
    }

    &-controls {
      display: flex;

      @include phone() {
        margin-top: rem(16);
        width: 100%;
      }

      .cr-search {
        min-width: 316px;
      }

      .cr-datepicker {
        margin-right: rem(16);
      }
    }
  }

  &__list {
    margin-top: rem(64);

    @include tablet() {
      margin-top: rem(32);
    }
    @include phone() {
      margin-top: 0;
    }

    .comment-card {
      margin: rem(32) 0;

      @include phone() {
        margin: rem(16) 0;
      }
    }
  }

  .comment-list-loader {
    margin-top: rem(64);
  }
}
