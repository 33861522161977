@import "../../../../common.scss";
.my-notifications {
  &__content {
    @include tablet() {
      margin-top: rem(32);
    }
    @include phone() {
      margin-top: rem(24);
    }
  }

  &__subcontent {
    .profile-sub {
      &__title {
        min-width: unset;
        flex: 0;
      }

      &__content {
        @include tablet() {
          padding: 0 rem(16);
        }
      }

      &__panel {
        @include tablet() {
          margin-top: 0;
        }

        @include phone() {
          margin-top: 0;
        }
      }
    }
  }

  .notification {
    &:not(:first-child) {
      margin-top: rem(64);
      @include phone() {
        margin-top: rem(48);
      }
    }
    &__parent {
      &-time {
        font: normal normal bold rem(20) / rem(32) PT Sans;
        letter-spacing: 0px;
        color: var(--base-text);
        @include tablet() {
          font: normal normal bold rem(18) / rem(26) PT Sans;
        }
        @include phone() {
          font: normal normal bold rem(16) / rem(22) PT Sans;
        }
      }
    }
    &__child {
      border-bottom: 1px solid var(--color-secondary-l5);
      padding: rem(24) 0;

      &-time {
        font: italic normal normal rem(18) / rem(32) PT Sans;
        letter-spacing: 0;
        color: var(--textarea-hint-color);
        @include tablet() {
          font: italic normal normal rem(18) / rem(28) PT Sans;
        }
        @include phone() {
          font: italic normal normal rem(14) / rem(19) PT Sans;
        }
      }
      &-text {
        font: normal normal normal rem(20) / rem(32) PT Sans;
        letter-spacing: 0;
        color: var(--base-text);
        margin-top: rem(12);
        a {
          color: var(--color-primary);
        }
        @include tablet() {
          font: normal normal normal rem(18) / rem(24) PT Sans;
        }
        @include phone() {
          font: normal normal normal rem(16) / rem(20) PT Sans;
        }

        blockquote {
          display: block;
        }
      }
      &__linked-user {
        display: flex;
        align-items: center;
        overflow: hidden;
        &__info {
          margin-left: rem(16);
          overflow: hidden;
          @include phone() {
            margin-left: rem(8);
          }
        }
        &__name {
          font: normal normal bold rem(20) / rem(24) PT Sans;
          letter-spacing: 0;
          color: var(--base-text);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: auto !important;
          @supports (-webkit-line-clamp: 2) {
            @include multilineEllipsis(2, 100%, 20px, 1.2);
          }
          @include tablet() {
            font: normal normal bold rem(18) / rem(26) PT Sans;
          }
          @include phone() {
            font: normal normal bold rem(16) / rem(22) PT Sans;
          }
        }
        &__rel {
          font: italic normal normal rem(18) / rem(24) PT Sans;
          letter-spacing: 0;
          color: var(--base-gray);
          margin-top: rem(4);
          @include phone() {
            font: italic normal normal rem(14) / rem(18) PT Sans;
          }
        }
        @include phone() {
          align-items: flex-end;
        }
      }
      @include tablet() {
        padding: rem(32) 0;
      }
      @include phone() {
        padding: rem(25) 0;
      }
    }
    .user-avatar__container {
      margin: rem(20) 0;
      @include tablet() {
        margin: rem(12) 0 0;
      }
      @include phone() {
        &.user-avatar__m {
          .user-avatar__m {
            width: rem(40);
            min-width: rem(40);
            height: rem(40);
          }
          width: rem(40);
          min-width: rem(40);
          height: rem(40);
        }
      }
    }
  }

  &__panel {
    display: flex;
    align-items: center;
    @include phone() {
      width: 100%;
      margin-top: rem(24);
    }

    .cr-button {
      margin: 0 rem(12);
      background-color: var(--color-primary-l2);
      &__text {
        text-transform: none;
        font: normal normal bold rem(18) / rem(24) PT Sans;
      }
      @include tablet() {
        &__text {
          font: normal normal bold rem(16) / rem(24) PT Sans;
        }
      }
      @include phone() {
        margin: 0 rem(16);
        padding: 0;

        &__icon {
          margin-right: rem(4);
          .ui-icon-settings {
            font-size: rem(20);
          }
        }
        &__text {
          font: normal normal bold rem(12) / rem(15) PT Sans;
        }
      }
    }
    .cr-select {
      width: 100%;
      margin: 0;
      background-color: var(--color-primary-l2);
      &__input {
        padding: rem(12) rem(24);
        padding-left: rem(20);
        min-width: 172px;
        height: 44px;
        width: fit-content;
        border-radius: 6px;
        @include adaptive(655) {
          padding: rem(6) rem(12);
          min-width: 107px;
          height: unset;
        }
        @include phone() {
          padding: 0;
          min-width: 107px;
        }
      }
    }
    &__fixed-text-select {
      .cr-select__placeholder {
        font-size: 0;
      }
      .cr-select {
        position: relative;
      }
      .cr-select__label {
        position: absolute;
        top: rem(7);
        left: rem(24);
        color: var(--base-blue);
        font: var(--button-size-m-font);
        @include tablet() {
          top: rem(6);
          left: rem(23);
        }
        @include adaptive(655) {
          padding: 0;
          margin: 0;
          top: rem(8);
          left: rem(10);
          font: normal normal bold rem(12) / rem(20) PT Sans;
        }
        @include phone() {
          top: rem(2);
        }
      }
      .cr-select__input {
        .ui-icon-arrow-slider-left {
          display: none;
        }
      }
      @include adaptive(655) {
        .cr-select__input-list {
          width: 280px;
        }
      }
    }
  }
  .pagination {
    margin-top: rem(100);
  }
  &__empty {
    text-align: center;
    padding: 9rem;
    font: normal normal normal rem(15) / rem(10) PT Sans;
    letter-spacing: 0;
  }

  .element-observer__item {
    &:nth-last-child(1) {
      .notification__child {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}
