@import "../../../../common.scss";

.proposal-list,
.infinite-scroll-component__outerdiv {
  width: 100%;
}

.infinite-scroll-component {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.proposal-list--votable,
.proposal-list--empty {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.proposal-list--mine {
  .proposal {
    &__footer {
      display: none;
    }
  }
}

.proposal-list--empty {
  @include phone() {
    padding: 0 16px;
  }

  .proposal-list__message {
    color: var(--base-text);
    letter-spacing: 0px;
    margin: 0px auto rem(40px);
    font: normal normal normal rem(20) / rem(32) PT Sans;
    @include tablet() {
      font: normal normal normal rem(18) / rem(24) PT Sans;
    }
    @include phone() {
      font: normal normal normal rem(16) / rem(20) PT Sans;
    }
  }
}

.pagination {
  padding-bottom: rem(32px);
}
