@import "../../../../common.scss";

.banner-line {
  --h: 50px;
  @include adaptive(365) {
    --h: 40px;
  }

  height: var(--h);

  &__inner {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;
    height: var(--h);
  }

  &__slide {
    img {
      width: 100%;
      height: var(--h);
      object-fit: cover;
    }
  }
}
