@import "../../../../common.scss";

@mixin disable() {
  opacity: 0.3;
  pointer-events: none;
}

.modal__wrapper {
  padding: 0;
  margin: 0;
  z-index: 30;
  &.frontmen-team__modal {
    background-color: var(--base-ligth-blue);
  }
  &.experts-team__modal,
  &.coordinators-team__modal {
    background: #f2f4f7 0% 0% no-repeat padding-box;
  }

  &-head {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 20;
    &__title {
      padding: 0 rem(16);
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      height: 65px;
      margin-bottom: 16px;

      .cr-tooltip {
        display: none;
      }
      .header-menu {
        display: flex;
        align-items: center;
        padding-top: rem(5);
        .logged-in {
          &-avatar {
            margin-right: rem(16);
            width: 40px;
            height: 40px;
            font: normal normal bold rem(14) / rem(18) PT Sans;
          }
        }
        .cr-header__link {
          padding: rem(16) rem(32);
          span {
            font: normal normal bold rem(16) / rem(22) PT Sans;
          }
        }
      }

      .logo {
        margin-top: 8px;
      }
    }
  }
  .modal__wrapper-body {
    margin-top: rem(-1);
  }
}
