@import "../../../../common";

.project-participants {
    display: flex;
    flex-direction: column;
    margin-top: rem(52);
    @include short-desktop() {
      margin-top: rem(20);
    }
    @include phone() {
      margin: 0 rem(16) 0 rem(16);
    }

    &-container {
      position: relative;
      display: flex;
      align-items: center;
      margin-top: rem(24);
      background: var(--base-ligth-gray);
      padding: rem(24) 0;
      z-index: 1;

      @include afterBeforeBackground(var(--base-ligth-gray));

      @include short-desktop() {
        margin-top: rem(20);
      }
      @include phone() {
        margin-top: 0;
        background-color: var(--base-white);
        &:before,
        &:after {
          width: 0;
        }
      }
    }

    &-list {
      display: flex;
      width: 100%;
    }

    &-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      width: calc(100% / 6);
      max-width: calc(100% / 6);
      @include tablet() {
        width: calc(100% / 10);
        max-width: calc(100% / 10);
      }
      @include phone() {
        width: calc(100% / 5);
        max-width: calc(100% / 5);
      }
    }

    &-name {
      display: flex;
      flex-direction: column;
      margin-left: rem(16);
      font: normal normal normal rem(18) / rem(24) PT Sans;
      color: var(--base-text);
      overflow: hidden;
      @include tablet() {
        display: none;
      }

      span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    &-more {
      font: normal normal bold rem(18) / rem(24) PT Sans;
      margin-left: auto;
      color: var(--base-blue);
      @include tablet() {
        font: normal normal bold rem(16) / rem(20) PT Sans;
      }
    }
  }