@import "../../../../common.scss";

.profile-menu {
  display: flex;

  &__сards {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    @include tablet() {
      width: calc(100% + 24px);
      margin-left: -24px;
    }

    @include phone() {
      width: 100%;
      margin-left: 0;
    }

    .module-card {
      justify-content: space-between;
      transition: background 0.3s ease;
      height: 220px;
      margin-left: 24px;
      margin-bottom: 32px;
      width: 200px;
      border-radius: 4px;

      @include tablet() {
        justify-content: flex-start;
        margin-right: 0;
        margin-bottom: 24px;
        max-width: unset;
        width: auto;
      }

      @include phone() {
        width: 100%;
        height: 49px;
        max-width: none;
        margin: 0;
      }
    }

    @include tablet() {
    }

    @include phone() {
      padding: 0 rem(16);
    }
  }

  &__cwrap {
    @include tablet() {
      width: 33.33333%;
    }

    @include phone() {
      width: 100%;
      height: 49px;
      max-width: none;
      margin: 0;

      &:not(:last-child) {
        border-bottom: var(--base-border) 1px solid;
      }
    }
  }

  &__notifications {
    width: 50%;
    max-width: 408px;

    @include tablet() {
      margin-bottom: rem(24);
      width: 100%;
      max-width: none;
    }
  }

  @include tablet() {
    flex-direction: column-reverse;
  }
}
