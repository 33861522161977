@import "../../../../../../common";

.personal-post-statistic {
  display: flex;
  margin-top: rem(8);

  &__comment-count {
    margin-right: rem(24);
  }

  &__date-time {
    font: italic normal normal rem(18) / rem(24) PT Sans;
    color: var(--base-gray);
    margin-right: rem(24);
    @include phone() {
      font: italic normal normal rem(14) / rem(19) PT Sans;
    }
  }

  &__comment-count,
  &__favorite-count {
    display: flex;
    align-items: center;
    font: normal normal normal rem(18) / rem(24) PT Sans;
    color: var(--base-text);

    span {
      margin-right: rem(10);
      font-size: rem(20);
    }
    @include phone() {
      font: normal normal bold rem(14) / rem(19) PT Sans;
    }
  }

  &__comment-count span {
    color: var(--color-secondary-l2);
  }

  &__favorite-count span {
    color: var(--base-gold);
  }

  .post-add-favorite i {
    font-size: rem(20);
  }

  @include phone() {
    justify-content: space-between;

    &__comment-count {
      margin-right: rem(16);

      &__text {
        display: none;
      }
    }
  }
}
