@import "../../../../../common.scss";

.idea-transfer-history-modal {
  width: 992px !important;

  &__header {
    color: var(--color-secondary);

    @include tablet-only {
      line-height: 32px;
    }

    @include phone {
      line-height: 24px;
      padding-top: 21px !important;
    }
  }

  &__footer {
    @include tablet-only {
      margin-top: 0;
    }

    @include phone {
      margin-bottom: 4px;
    }

    .cr-button {
      @include tablet-only {
        margin-top: 12px;
      }
    }
  }

  .crowd-table-list {
    @include phone {
      padding-top: 16px;
    }
  }

  #close-modal-icon {
    @include tablet-only {
      top: 22px;
      right: 24px;
    }
  }

  .crowd-table {
    table {
      td,
      th {
        padding-left: 0;
      }

      th {
        @include tablet-only {
          font-size: 14px;
          line-height: 17px;
          padding-top: 0;
          padding-bottom: 14px;
        }
      }

      td {
        @include tablet-only {
          font-size: 16px;
          line-height: 19px;
          vertical-align: top;
          padding-top: 10px;
          padding-bottom: 12px;
        }
      }
    }
  }

  &__date-column {
    @include tablet-only {
      width: 98px;
    }
  }

  &__status-column {
    @include tablet-only {
      width: 116px;
    }
  }

  &__comment-column {
    @include tablet-only {
      width: 282px;
    }
  }

  &__author-column {
    @include tablet-only {
    }
  }
}
