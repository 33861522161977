@font-face {
    font-family: 'Bebas Neue';
    src: local('Bebas Neue Thin'), local('BebasNeue-Thin'), url('bebasneuethin.woff2') format('woff2'), url('bebasneuethin.woff') format('woff'), url('bebasneuethin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Bebas Neue';
    src: local('Bebas Neue Light'), local('BebasNeue-Light'), url('bebasneuelight.woff2') format('woff2'), url('bebasneuelight.woff') format('woff'), url('bebasneuelight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Bebas Neue';
    src: local('Bebas Neue Book'), local('BebasNeueBook'), url('bebasneuebook.woff2') format('woff2'), url('bebasneuebook.woff') format('woff'), url('bebasneuebook.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Bebas Neue';
    src: local('Bebas Neue Regular'), local('BebasNeueRegular'), url('bebasneueregular.woff2') format('woff2'), url('bebasneueregular.woff') format('woff'), url('bebasneueregular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Bebas Neue';
    src: local('Bebas Neue Bold'), local('BebasNeueBold'), url('bebasneuebold.woff2') format('woff2'), url('bebasneuebold.woff') format('woff'), url('bebasneuebold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
