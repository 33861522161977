@import "../../../common.scss";

.tabs {
  display: flex;

  @include tablet() {
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .cr-button {
    font-size: rem(18px);
    line-height: rem(18px);
    font-weight: normal;
    padding: rem(10px) rem(16px);
    border-radius: 20px;
    margin-right: rem(16px);
    text-transform: none;
    font-style: italic;
    height: rem(42);

    &-filled:hover {
      background-color: #65aae0 !important;
    }
  }

  @include tablet() {
    flex-wrap: nowrap;

    @supports (-webkit-touch-callout: none) {
      > button:not(:last-child) {
        margin-right: rem(16);
      }
    }
  }
}

.tabs-mobile {
  display: flex;
  justify-content: space-between;
  padding: rem(14) rem(16);
  background-color: var(--color-secondary-l6);

  &__title {
    font: normal normal normal rem(10) / rem(13) PT Sans;
    text-align: center;
    margin-top: rem(4);
    width: auto;
    hyphens: manual;
  }
}
