@import "../../../common.scss";

.popup-search-table {
  &__header {
    @include phone() {
      line-height: 24px;
      padding: 21px 16px !important;
    }
  }

  .popup-search-table__table {
    margin-top: 1.5rem;
    text-align: left;

    @include phone() {
      margin-top: 24px;
    }

    thead th {
      font-size: 0.875rem;
    }

    tbody td {
      font-size: 1rem;
    }
  }

  .cr-search {
    @include phone() {
      padding-top: 16px;
    }

    input {
      padding: 18px 1rem 17px 3.5rem !important;
      border-radius: 4px;
      width: 100%;
    }

    .ui-icon {
      color: var(--color-secondary-l2);
      position: absolute;
      left: 1rem;
      font-size: 24px;

      @include phone() {
        top: calc(50% + 8px);
        transform: translateY(-50%);
      }
    }
  }
}
