@import "../../../common.scss";

.post-card {
  display: flex;
  flex-direction: column;
  color: var(--base-text);
  width: 100%;
  padding: 1.5rem;
  background: white;
  word-break: break-word;
  border-radius: 4px;

  @include phone {
    border-radius: unset;
  }

  ul,
  ol {
    margin-left: rem(32);
  }

  a {
    color: var(--base-blue);
  }

  &__header {
    display: flex;
  }

  &--hb {
    font: normal normal bold 20px/24px PT Sans;
    letter-spacing: 0px;
    color: var(--base-text);

    @include tablet() {
      font: normal normal bold 16px/22px PT Sans;
      letter-spacing: 0px;
    }
  }

  &--hi {
    font: italic normal normal 18px/24px PT Sans;
    letter-spacing: 0px;
    color: #7e7e7e;
    margin-top: 0.25rem;

    @include phone() {
      font: italic normal normal 12px/18px PT Sans;
    }
  }

  &--hn {
    font: normal normal normal 16px/24px PT Sans;
    letter-spacing: 0px;
    color: var(--base-text);

    @include phone() {
      display: none;
    }

    &.phone {
      display: none;
      @include phone() {
        display: block;
        font: normal normal normal 12px/15px PT Sans;
        margin-top: 8px;
      }
    }
  }

  &--bb {
    font: normal normal bold 20px/32px PT Sans;
    letter-spacing: 0px;
    color: var(--base-text);

    @include tablet() {
      font: normal normal bold 18px/26px PT Sans;
      letter-spacing: 0px;
    }

    @include phone() {
      font: normal normal bold 16px/22px PT Sans;
      letter-spacing: 0px;
    }
  }

  &--bn {
    font: normal normal normal 20px/32px PT Sans;
    letter-spacing: 0px;
    color: var(--base-text);

    @include tablet() {
      font: normal normal normal 18px/24px PT Sans;
      letter-spacing: 0px;
      margin-top: rem(12);
    }

    @include phone() {
      font: normal normal normal 16px/20px PT Sans;
      letter-spacing: 0px;
    }

    p {
      img {
        max-width: 144px;
        object-fit: cover;
        margin-top: 12px;
        max-height: 144px;
      }
    }
  }

  &--shorten-10 > &--bn {
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .attachment__content {
    margin-top: 12px;

    img {
      max-width: 144px;
      object-fit: cover;
      max-height: 144px;
    }
  }
}
