@import "../../../../common.scss";

.auth-child-forbidden-modal {
  width: 648px !important;
  @include tablet {
    width: 520px !important;
  }

  &__title {
    padding: 15px 0 16px;
    text-align: center;
    font: normal normal bold 92px/119px PT Sans;
    letter-spacing: 0.68px;
    color: var(--base-pink);

    @include tablet {
      padding-top: 0;
      font-size: 64px;
      line-height: 84px;
      letter-spacing: 0.47px;
    }

    @include phone {
      padding-top: 21px;
      font-size: 52px;
      line-height: 67px;
      letter-spacing: 0.38px;
    }
  }

  &__text {
    text-align: center;
    font: normal normal normal 18px/28px PT Sans;
    color: var(--base-text);
    margin-bottom: 32px;

    @include tablet {
      line-height: 24px;
      margin-bottom: 24px;
      padding: 0 16px;
    }

    @include phone {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 20px;
      padding: 0;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    @include phone {
      width: 100%;
    }
  }
}
