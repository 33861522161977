@import "../../../../common.scss";

.proposal__voting__options {
  display: flex;
  flex-direction: column;

  &--touchscreen {
    flex-direction: row;

    .proposal__voting__option {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: rem(18);
      margin-top: rem(6);
      cursor: pointer;

      @include phone() {
        margin-right: rem(16);
        margin-top: rem(16);
      }

      &__icon {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background-color: #00cc88;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: rem(10);
        @include phone() {
          width: 40px;
          height: 40px;
          margin: 0;
        }

        i {
          color: white;
          font-size: rem(24);
          @include phone() {
            font-size: rem(20);
          }
        }
      }
      &__label {
        font: normal normal normal rem(18) / rem(24) PT Sans;
        letter-spacing: 0px;
        color: var(--base-gray);
        margin-top: 2px;
        @include phone() {
          font: normal normal normal rem(10) / rem(13) PT Sans;
          color: #717277;
          margin-top: 4px;
        }
      }
    }
  }
}

.proposal__voting__option {
  font: normal normal normal 20px/32px PT Sans;
  letter-spacing: 0px;
  color: var(--base-text);
  margin-top: 24px;
  label {
    margin-left: 16px;
  }
  .cr-radio {
    margin: 0;
  }
}
