@import "./../../../../common";

.logged-in-avatar {
  display: block;
  height: 48px;
  width: 48px;
  position: relative;

  @include tablet() {
    margin-right: rem(24);
    margin-left: rem(24);
    height: 40px;
    width: 40px;
  }

  @include phone() {
    margin-right: rem(16);
    margin-left: rem(16);
  }

  img {
    border-radius: 100%;
    max-height: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__notifications {
    background: var(--base-orange) 0 0 no-repeat padding-box;
    border-radius: 12px;
    text-align: center;
    font: rem(12) / rem(18) Roboto;
    letter-spacing: 0.4px;
    color: var(--base-white);
    font-size: 13px;
    position: absolute;
    top: -6px;
    right: -6px;
    font-weight: 500;
    padding: rem(3);
    min-width: 22px;
    height: 22px;
  }
}
