@import "../../../../common";

.comment-card {
  display: flex;
  flex-direction: column;
  color: var(--base-text);
  width: 100%;

  pre {
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    font: normal normal normal rem(20) / rem(32) PT Sans;
  }
  .textAreaEditor__quote__text {
    pre {
      @include tablet() {
        font: normal normal normal rem(18) / rem(24) PT Sans;
      }
      @include phone() {
        font: normal normal normal rem(16) / rem(20) PT Sans;
      }
    }
  }
  &--highlight {
    box-shadow: 0px 1px 6px #65aae07a;
  }

  &--readonly {
    pointer-events: none;
  }

  &__wrapper {
    display: flex;
    padding: 1.5rem;
    width: 100%;
    transition: all 0.6s ease;
    transition-delay: 1.1s;
    word-break: break-word;
    border-radius: 4px;

    @include phone() {
      padding: 1rem;
      border-radius: unset;
    }
  }

  &__avatar {
    cursor: pointer;
    margin-right: 1rem;

    @include phone() {
      margin-right: 0.5rem;
      .user-avatar__m {
        min-width: rem(40);
        height: rem(40);
        width: rem(40);
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__block {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 80px;

    @include tablet() {
      padding-left: 0;
    }
  }
  &__info {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    &-icon .ui-icon {
      cursor: pointer;
      color: var(--base-gray);
      font-size: 24px;

      @include phone() {
        font-size: rem(20);
      }
    }
    &-common {
      display: flex;
    }
  }

  &__author-name {
    margin-right: 8px;
    @include phone() {
      margin-right: 6px;
    }
  }

  &__author-name-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font: normal normal bold rem(20) / rem(28) PT Sans;
    cursor: pointer;
    margin-right: 12px;
    @include tablet() {
      margin-right: 10px;
    }
    @include phone() {
      margin-right: 8px;
    }

    a {
      span {
        display: flex;
        font: italic normal normal rem(18) / rem(24) PT Sans;
        color: var(--base-gray);
        @include phone() {
          font: italic normal normal rem(14) / rem(18) PT Sans;
        }
      }
    }
    & > span {
      margin-right: rem(8);
      @include phone() {
        color: var(--base-text);
        font: normal normal bold rem(16) / rem(22) PT Sans;
      }
    }
    @include phone() {
      font: normal normal bold rem(16) / rem(22) PT Sans;
    }
  }

  &__info-hint {
    color: var(--base-gray);
    font: italic normal normal rem(18) / rem(24) PT Sans;

    > a {
      color: var(--base-blue);
    }
    @include tablet() {
      display: flex;
      flex-direction: column;
    }
    @include phone() {
      font: italic normal normal rem(12) / rem(18) PT Sans;
      > a {
        font: italic normal normal rem(14) / rem(18) PT Sans;
      }
    }
  }
  &__title {
    font: normal normal bold rem(20) / rem(32) PT Sans;
    margin-bottom: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;

    @include tablet() {
      font: normal normal bold rem(18) / rem(26) PT Sans;
      margin-top: rem(16);
      margin-bottom: 0;
    }

    @include phone() {
      font: normal normal bold rem(16) / rem(22) PT Sans;
    }
  }

  &__text {
    font: normal normal normal rem(20) / rem(32) PT Sans;
    white-space: pre-wrap;

    img {
      max-width: 144px;
      margin-top: rem(12);
      border-radius: 4px;
    }

    a {
      color: var(--base-blue);
    }

    ul,
    ol {
      margin-left: rem(32);
    }

    @include tablet() {
      margin-top: rem(16);
      font: normal normal normal rem(18) / rem(24) PT Sans;
    }
    @include phone() {
      font: normal normal normal rem(16) / rem(20) PT Sans;
    }

    p {
      @include tablet() {
        font: normal normal normal rem(18) / rem(24) PT Sans;
      }
      @include phone() {
        font: normal normal normal rem(16) / rem(20) PT Sans;
      }
    }
  }

  &__answer-btn {
    margin-right: rem(24);
    @include phone {
      margin-right: rem(16);
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;

    @include phone() {
      margin-top: 1rem;
      & > button,
      .comment-card__answer-btn > button {
        font: normal normal bold rem(16) / rem(20) PT Sans;
      }
    }
  }

  &__stats {
    display: flex;
    &-item {
      display: flex;
      align-items: center;
      position: relative;

      color: var(--color-secondary-l2);
      .ui-icon {
        cursor: pointer;
        color: var(--base-blue);
        @include desktopHover() {
          &:hover {
            color: var(--color-primary-d1);
          }
        }
        @include phone() {
          font-size: rem(20);
        }
      }
      &--disabled {
        .ui-icon {
          cursor: auto;
          @include desktopHover() {
            &:hover {
              color: var(--base-blue);
            }
          }
        }
      }

      div.comment-card__like {
        margin-left: 14px;
        cursor: pointer;
        color: var(--base-blue);
        @include desktopHover() {
          &:hover {
            color: var(--color-primary-d1);
          }
        }
        font: normal normal bold 18px/24px PT Sans;
        @include phone() {
          margin-left: 12px;
          font: normal normal bold 16px/24px PT Sans;
        }
      }
    }
  }

  &__popup {
    .popup {
      border-radius: 44px;
      padding: 16px;
      right: -5px;
      left: initial;
      top: -82px;
      min-width: 72px;
      cursor: auto;
      @include phone() {
        right: -12px;
      }
    }
    .swiper-container {
      max-width: 280px;
      height: 40px;
    }
  }

  &__offset {
    .popup {
      padding: 16px 8px 16px 16px;
    }
  }

  &__images {
    display: flex;
    flex-wrap: wrap;
    margin-top: rem(24);
  }

  &__user {
    &-icons {
      display: flex;
      align-items: center;
      color: var(--base-gray);

      > *:not(:first-child) {
        margin-left: rem(24);
        @include phone() {
          margin-left: rem(16);
        }
      }
    }
    &-icon {
      cursor: pointer;
      &_selected {
        color: var(--base-blue);
      }
    }
  }

  &__deleted {
    display: flex;
    justify-content: space-between;
    padding: rem(13) rem(24);
    border: 1px solid #d7d9e0;
    font: italic normal normal rem(18) / rem(21) PT Sans;
    color: var(--textarea-hint-color);

    @include phone() {
      font: italic normal normal 14px/19px PT Sans;
      margin: 0 auto;
      border: none;
      padding: 0;
    }
  }

  &__answers {
    display: flex;
    flex-direction: column;
    margin-top: rem(32);
    margin-left: rem(104);

    &__answer:not(:first-of-type) {
      margin-top: rem(32);
    }

    @include tablet() {
      margin-top: rem(24);
    }

    @include phone() {
      margin-top: rem(16);
      margin-left: rem(16);
    }
  }

  &__more {
    display: flex;
    align-items: center;
    color: var(--base-blue);
    font: normal normal bold rem(18) / rem(24) PT Sans;
    margin: rem(16) 0 0 auto;
    cursor: pointer;
    user-select: none;

    @include tablet() {
      font: normal normal bold rem(16) / rem(24) PT Sans;
    }

    @include phone() {
      font: normal normal bold rem(14) / rem(19) PT Sans;
      width: 100%;
      justify-content: center;
      padding-right: 16px;
    }

    i {
      margin-right: rem(12);

      @include phone() {
        margin-right: rem(8);
      }
    }
  }

  &__label {
    span {
      padding: rem(4) rem(16);
      border-radius: rem(16);
      font: normal normal bold rem(12) / rem(32) PT Sans;
    }

    &-discussion {
      background: var(--base-purple);
      color: #fff;
    }

    &-blog {
      background: #e8e8e8;
      color: var(--base-text);
    }
  }
}
