@import "../../../../common";

div.user-modal {
  width: 576px;
  @include tablet() {
    width: 692px;
  }
}
.user-modal {
  color: var(--base-text);

  &__header {
    display: flex;
    align-items: center;
    text-transform: none;
    margin-bottom: rem(26);

    @include phone() {
      flex-direction: column;
      margin-bottom: rem(20);
      padding-top: rem(65);
    }
    .user-modal__avatar {
      margin-right: rem(16);

      @include phone() {
        margin-right: 0;
        margin-bottom: rem(16);
      }
    }
  }

  &__footer {
    margin-top: rem(32);
    display: flex;
    justify-content: flex-end;

    .cr-button {
      margin-left: rem(16);
      @include phone() {
        margin-left: 0;
        &:nth-child(2) {
          margin-bottom: 12px;
        }
      }
    }
    @include phone() {
      flex-direction: column-reverse;
      justify-content: center;
    }
  }

  &__error {
    font: normal normal normal rem(18) / rem(24) PT Sans;
    margin-bottom: rem(24);
  }

  &__body {
    display: flex;
    flex-direction: column;
    margin-top: 0 !important;
  }

  &__user-info {
    display: flex;
    flex-direction: column;
    width: calc(100% - 7rem);
    @include phone() {
      align-items: center;
      width: 100%;
      margin-left: 0;
    }
  }

  &__user-online,
  &__user-blocked {
    color: var(--base-gray);
    font: italic normal normal rem(18) / rem(21) PT Sans;
    white-space: nowrap;
  }

  &__user-name {
    font: normal normal bold rem(20) / rem(24) PT Sans;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: rem(4px);
    display: flex;
  }

  &__user-place {
    margin-bottom: rem(4px);
    @include phone() {
      text-align: center;
    }
  }

  &__user-place p {
    font: italic normal normal rem(18) / rem(22) PT Sans;
    color: var(--base-gray);
    @include tablet() {
      font: italic normal normal rem(16) / rem(22) PT Sans;
    }
    @include phone() {
      font: italic normal normal rem(14) / rem(19) PT Sans;
    }
  }

  &__stats {
    display: flex;
    flex-direction: column;
    padding: rem(24);
    border: 1px solid var(--base-blue);
    border-radius: rem(8);

    &-item {
      display: flex;
      align-items: center;
      margin-bottom: rem(24);

      @include phone() {
        margin-bottom: rem(16);
      }

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }

    &-text {
      font: normal normal normal rem(20) / rem(24) PT Sans;
      margin: 0 rem(16);
      @include phone() {
        margin-left: rem(12);
        font: normal normal normal rem(16) / rem(20) PT Sans;
      }
    }

    &-count {
      font: normal normal bold rem(20) / rem(24) PT Sans;
      margin-left: auto;
      @include phone() {
        font: normal normal bold rem(16) / rem(20) PT Sans;
      }
    }
    @include phone() {
      padding: rem(16);
    }
  }
}
