@import "../../../../common.scss";

.my-anwers {
  &__panel {
    display: flex;
    align-items: center;

    .cr-select {
      background-color: var(--base-ligth-blue);

      &__placeholder {
        font: normal normal bold 18px/24px PT Sans;
        letter-spacing: 0px;
        color: var(--base-blue);
        text-align: center;
      }
    }
    @include phone() {
      flex-direction: column;
    }
  }

  .cr-select {
    width: 172px;
    margin-top: 0;

    &__input {
      padding: 10px;
    }

    .ui-icon-arrow-slider-left {
      display: none;
    }
    @include phone() {
      width: 100%;
    }
  }

  .cr-datepicker {
    margin-left: rem(16px);
    @include phone() {
      margin-left: 0;
      margin-top: rem(8);
    }
  }
}
