@font-face {
  font-family: 'platform-icons';
  src:  url('fonts/platform-icons.eot?fecpzr');
  src:  url('fonts/platform-icons.eot?fecpzr#iefix') format('embedded-opentype'),
    url('fonts/platform-icons.ttf?fecpzr') format('truetype'),
    url('fonts/platform-icons.woff?fecpzr') format('woff'),
    url('fonts/platform-icons.svg?fecpzr#platform-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ui-icon-"], [class*=" ui-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'platform-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui-icon-hidden:before {
  content: "\e900";
}
.ui-icon-like-fill:before {
  content: "\e901";
}
.ui-icon-drive-file:before {
  content: "\ee92";
}
.ui-icon-drive-file-move:before {
  content: "\ee93";
}
.ui-icon-clipboard-done:before {
  content: "\ee94";
}
.ui-icon-clipboard-list:before {
  content: "\ee95";
}
.ui-icon-clipboard-more:before {
  content: "\ee96";
}
.ui-icon-clipboard-delete:before {
  content: "\ee97";
}
.ui-icon-check-selecting:before {
  content: "\ee98";
}
.ui-icon-check-selecting-filled:before {
  content: "\ee99";
}
.ui-icon-blog:before {
  content: "\ee9a";
}
.ui-icon-blogs:before {
  content: "\ee9b";
}
.ui-icon-blogs-filled:before {
  content: "\ee9c";
}
.ui-icon-create:before {
  content: "\ee9d";
}
.ui-icon-file-send:before {
  content: "\ee9e";
}
.ui-icon-file-delete:before {
  content: "\ee9f";
}
.ui-icon-sent-post:before {
  content: "\eea0";
}
.ui-icon-record:before {
  content: "\eea1";
}
.ui-icon-edit:before {
  content: "\eea2";
}
.ui-icon-pic:before {
  content: "\eea3";
}
.ui-icon-mail:before {
  content: "\eea4";
}
.ui-icon-inbox:before {
  content: "\eea5";
}
.ui-icon-inbox-filled:before {
  content: "\eea6";
}
.ui-icon-inbox-all:before {
  content: "\eea7";
}
.ui-icon-inbox-all-filled:before {
  content: "\eea8";
}
.ui-icon-visibility:before {
  content: "\eea9";
}
.ui-icon-visibility-off:before {
  content: "\eeaa";
}
.ui-icon-nocomments:before {
  content: "\eeab";
}
.ui-icon-appeal:before {
  content: "\eeac";
}
.ui-icon-quote:before {
  content: "\eead";
}
.ui-icon-comment-reply:before {
  content: "\eeae";
}
.ui-icon-comments:before {
  content: "\eeaf";
}
.ui-icon-question-answer:before {
  content: "\eeb0";
}
.ui-icon-question-answer-filled:before {
  content: "\eeb1";
}
.ui-icon-comments-round:before {
  content: "\eeb2";
}
.ui-icon-arrow-back:before {
  content: "\eeb3";
}
.ui-icon-arrow-backup:before {
  content: "\eeb4";
}
.ui-icon-arrow-up:before {
  content: "\eeb5";
}
.ui-icon-arrow-down:before {
  content: "\eeb6";
}
.ui-icon-arrow-slider-left:before {
  content: "\eeb7";
}
.ui-icon-arrow-slider-right:before {
  content: "\eeb8";
}
.ui-icon-chevron-up:before {
  content: "\eeb9";
}
.ui-icon-chevron-down:before {
  content: "\eeba";
}
.ui-icon-keyboard-arrow-left:before {
  content: "\eebb";
}
.ui-icon-keyboard-arrow-right:before {
  content: "\eebc";
}
.ui-icon-trending:before {
  content: "\eebd";
}
.ui-icon-link:before {
  content: "\eebe";
}
.ui-icon-add-link:before {
  content: "\eebf";
}
.ui-icon-attach:before {
  content: "\eec0";
}
.ui-icon-clock:before {
  content: "\eec1";
}
.ui-icon-close-round:before {
  content: "\eec2";
}
.ui-icon-close-round-filled:before {
  content: "\eec3";
}
.ui-icon-done:before {
  content: "\eec4";
}
.ui-icon-help:before {
  content: "\eec5";
}
.ui-icon-add:before {
  content: "\eec6";
}
.ui-icon-exit:before {
  content: "\eec7";
}
.ui-icon-info:before {
  content: "\eec8";
}
.ui-icon-more-round:before {
  content: "\eec9";
}
.ui-icon-remove-round:before {
  content: "\eeca";
}
.ui-icon-restore:before {
  content: "\eecb";
}
.ui-icon-report:before {
  content: "\eecc";
}
.ui-icon-warning:before {
  content: "\eecd";
}
.ui-icon-unlock:before {
  content: "\eece";
}
.ui-icon-lock:before {
  content: "\eecf";
}
.ui-icon-date:before {
  content: "\eed0";
}
.ui-icon-delete:before {
  content: "\eed1";
}
.ui-icon-delete-forever:before {
  content: "\eed2";
}
.ui-icon-like:before {
  content: "\eed3";
}
.ui-icon-dislike:before {
  content: "\eed4";
}
.ui-icon-favorite:before {
  content: "\eed5";
}
.ui-icon-favorite-filled:before {
  content: "\eed6";
}
.ui-icon-close-filled:before {
  content: "\eed7";
}
.ui-icon-close:before {
  content: "\eed8";
}
.ui-icon-remove:before {
  content: "\eed9";
}
.ui-icon-done-symbol:before {
  content: "\eeda";
}
.ui-icon-more:before {
  content: "\eedb";
}
.ui-icon-quote-symbol:before {
  content: "\eedc";
}
.ui-icon-tune:before {
  content: "\eedd";
}
.ui-icon-fullscreen:before {
  content: "\eede";
}
.ui-icon-home:before {
  content: "\eedf";
}
.ui-icon-lightning:before {
  content: "\eee0";
}
.ui-icon-notify:before {
  content: "\eee1";
}
.ui-icon-photo:before {
  content: "\eee2";
}
.ui-icon-user:before {
  content: "\eee3";
}
.ui-icon-search:before {
  content: "\eee4";
}
.ui-icon-settings:before {
  content: "\eee5";
}
.ui-icon-support:before {
  content: "\eee6";
}
.ui-icon-fire:before {
  content: "\eee7";
}
.ui-icon-tag:before {
  content: "\eee8";
}
.ui-icon-send-message:before {
  content: "\eee9";
}
.ui-icon-hamburger:before {
  content: "\eeea";
}
.ui-icon-idea:before {
  content: "\eeeb";
}
.ui-icon-idea-filled:before {
  content: "\eeec";
}
.ui-icon-idea-good:before {
  content: "\eeed";
}
.ui-icon-idea-my:before {
  content: "\eeee";
}
.ui-icon-accepted-idea:before {
  content: "\eeef";
}
.ui-icon-accepted-idea-filled:before {
  content: "\eef0";
}
.ui-icon-idea-description:before {
  content: "\eef1";
}
.ui-icon-idea-description-filled:before {
  content: "\eef2";
}
.ui-icon-idea-sent:before {
  content: "\eef3";
}
.ui-icon-idea-sent-filled:before {
  content: "\eef4";
}
.ui-icon-idea-draft:before {
  content: "\eef5";
}
.ui-icon-idea-draft-filled:before {
  content: "\eef6";
}
.ui-icon-delete-idea:before {
  content: "\eef7";
}
.ui-icon-delete-idea-filled:before {
  content: "\eef8";
}
.ui-icon-reject-idea:before {
  content: "\eef9";
}
.ui-icon-reject-idea-filled:before {
  content: "\eefa";
}
.ui-icon-review-idea:before {
  content: "\eefb";
}
.ui-icon-review-idea-filled:before {
  content: "\eefc";
}
.ui-icon-idea-voted:before {
  content: "\eefd";
}
.ui-icon-idea-voted-filled:before {
  content: "\eefe";
}
.ui-icon-idea-pending:before {
  content: "\eeff";
}
.ui-icon-idea-pending-filled:before {
  content: "\ef00";
}
.ui-icon-idea-skipped:before {
  content: "\ef01";
}
.ui-icon-idea-skipped-filled:before {
  content: "\ef02";
}
.ui-icon-idea-folder:before {
  content: "\ef03";
}
.ui-icon-send-message-filled:before {
  content: "\ef04";
}
.ui-icon-delete-filled:before {
  content: "\ef05";
}
.ui-icon-enter-arrow:before {
  content: "\ef06";
}
.ui-icon-exit-arrow:before {
  content: "\ef07";
}
.ui-icon-refresh:before {
  content: "\ef08";
}
.ui-icon-play-next:before {
  content: "\ef09";
}
.ui-icon-ic-restore-24px:before {
  content: "\ef0a";
}
.ui-icon-Component4073:before {
  content: "\ef0b";
}
.ui-icon-sports_score_black_24dp:before {
  content: "\ef0c";
}
.ui-icon-add-group:before {
  content: "\ef0d";
}
.ui-icon-top-comment:before {
  content: "\ef0e";
}
.ui-icon-top-comment-selected:before {
  content: "\ef0f";
}
