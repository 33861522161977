@import "../../../../common.scss";

.edit-info {
  width: 70%;
  display: flex;
  align-items: center;
  column-gap: rem(32);
  flex-wrap: wrap;

  .profile-pic {
    display: none;
    @include phone() {
      display: block;
      margin-top: 16px;
    }
  }

  .cr-input-readonly {
    input {
      opacity: 0.6;
    }
  }

  .cr-input,
  .cr-select {
    width: 100%;
    max-width: 432px;
    margin-top: rem(40);
    @include adaptive(950) {
      width: 90%;
      max-width: 379px;
    }
    @include tablet() {
      width: 48%;
      margin-top: rem(24);
      &__label {
        color: #797a80;
      }
      @supports (-webkit-touch-callout: none) {
        margin-right: rem(24);
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
    @include adaptive(650) {
      width: 45%;
    }
    @include phone() {
      margin-top: rem(16);
      margin-right: 0;
      max-width: unset;
      width: 100%;
    }

    .ui-icon-arrow-slider-left {
      right: 22px;
      top: 18px;
      font-size: 20px;
    }
  }

  &__actions {
    width: 890px;
    margin-top: rem(40);

    @include adaptive(950) {
      width: 790px;
    }

    @include tablet() {
      margin-top: rem(24);
      width: auto;
    }

    @include phone {
      margin-top: rem(16);
      width: 100%;
    }

    .hidden-fio-warning {
      margin-bottom: rem(40);
      @include phone {
        margin-bottom: rem(32);
      }
    }

    .cr-button {
      margin-right: rem(16);
      @include phone() {
        margin-right: 0;
        &:not(:last-child) {
          margin-bottom: rem(12);
        }
      }
    }
  }
  @include adaptive(1350) {
    width: 100%;
  }
  @include tablet() {
    column-gap: rem(24);
    justify-content: space-between;
  }

  @include phone() {
    padding: 0 rem(16);
    justify-content: center;
  }
}
