@import "../../../common";

.discussion {
  a {
    color: var(--base-blue);
  }

  &--off {
    .comment-card {
      .comment-card__answer-btn {
        display: none;
      }
    }
  }

  &__stats {
    display: flex;
    margin-top: rem(8);

    .item__stats {
      @include phone() {
        &:first-child {
          flex-grow: 1;
        }
      }

      &:not(:last-child) {
        margin-right: rem(24);

        @include phone() {
          margin-right: rem(8);
        }
      }
    }
  }

  &__text,
  &__text p {
    flex: 1;
    font: normal normal normal rem(20) / rem(32) PT Sans;

    @include tablet() {
      font: normal normal normal rem(18) / rem(24) PT Sans;
    }

    @include phone() {
      font: normal normal normal rem(16) / rem(20) PT Sans;
    }

    ul,
    ol {
      margin-left: rem(32);
    }

    img {
      margin-top: rem(32);
      border-radius: 4px;
      position: relative;
    }
  }

  &__buttons {
    display: flex;
    padding-top: rem(40);

    @include phone() {
      padding-top: rem(24);
    }

    &-icon {
      cursor: pointer;
      font-size: 20px;
      color: var(--base-gray);
      margin-left: rem(24);

      @include phone() {
        margin-left: rem(18);
      }
    }

    &-stop {
      margin-left: auto;
      padding-left: rem(24);
    }

    &__buttons-row {
      @include phone() {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .cr-button {
          margin-left: 0;
        }
      }
    }
    &__icons-row {
      @include phone() {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: rem(24);
        .discussion__buttons-icon:first-child {
          margin-left: 0;
        }
      }
    }

    .add-comment {
      max-width: 1360px;
      width: calc(100% - 1px);
    }

    .app-editor__wrapper {
      margin: 0;
    }

    .cr-button {
      &:not(:first-child) {
        margin-left: rem(16);
      }
      &:first-child {
        @include tablet() {
          margin-left: 0 !important;
        }
      }
      &.add-summary-button {
        margin-left: auto;
        @include phone() {
          margin-left: rem(16);
        }
      }
    }

    &-inner {
      display: flex;
      align-items: center;
      width: 100%;

      @include adaptive(1408px) {
        padding-right: rem(48);
      }

      @include phone() {
        flex-wrap: wrap;
        // margin-bottom: 16px;
      }

      .textAreaEditor {
        margin-top: 0;
      }
    }

    &-sticky {
      position: absolute;
      right: 0;
      left: 0;
      bottom: 40px;

      .discussion__buttons-inner {
        background: white;
        padding: rem(40) 0;
        width: calc(100% - rem(48)) !important;
        max-width: 1360px;

        @include afterBeforeBackground(white);

        @include phone() {
          padding: rem(24) rem(16) rem(20) rem(16);
          .add-comment {
            margin-top: rem(16);
          }
        }
      }
    }
  }

  &__white-block {
    display: flex;
    flex-direction: column;
    padding-bottom: rem(40);
    @include phone() {
      padding: rem(16);
    }

    .page-title {
      margin-top: rem(40);

      @include tablet() {
        margin-top: rem(17);
      }
      @include phone() {
        margin-top: 0;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: calc(100% - 432px);

    @include tablet() {
      max-width: unset;
    }

    &-wrapper {
      display: flex;
      margin-top: rem(32);

      @include phone() {
        margin-top: rem(16);
      }
    }
  }

  &__logo {
    display: flex;
    align-items: flex-start;
    max-width: 368px;
    width: 100%;
    margin-left: rem(64);

    @include tablet() {
      display: none;
    }
  }

  &__attachments {
    display: flex;
    flex-wrap: wrap;
    .attachment {
      margin-top: 2rem;
      &__content {
        background-color: #ffffff;
      }
    }
  }

  &__loader {
    margin-top: rem(32);
  }

  &__comments {
    margin-top: rem(40);
    color: var(--base-text);

    @include phone() {
      margin-top: 0;
    }

    &-title {
      font: normal normal 700 rem(28) / rem(32) PT Sans;
      margin-bottom: 2rem;
    }

    .comment-card-list {
      scroll-margin-top: 81px + 125px + 20px;
      @include tablet() {
        scroll-margin-top: 65px + 125px + 20px;
      }
      @include phone() {
        scroll-margin-top: 65px + 64px + 10px;

        .comment-card .add-comment {
          padding: 0 rem(16);
        }
      }

      .pagination {
        margin-top: rem(32);

        @include tablet() {
          margin-top: rem(24);
        }
      }
    }
  }

  &__summary {
    display: flex;
    flex-direction: column;
    background: #dbf4ff;
    padding: rem(24) rem(32);
    font: normal normal bold rem(20) / rem(32) PT Sans;
    color: var(--base-text);
    margin-top: rem(32);

    @include tablet() {
      font: normal normal bold rem(18) / rem(24) PT Sans;
    }

    @include phone() {
      padding: rem(16);
      margin-top: rem(16);
      font: normal normal bold rem(16) / rem(20) PT Sans;
    }

    &-title {
      font-weight: bold;
    }
    &-content {
      margin-top: rem(12);
    }
    &-buttons {
      display: flex;
      align-items: center;
      margin-top: rem(24);
    }
    &-button {
      cursor: pointer;
      font: normal normal bold rem(18) / rem(32) PT Sans;
      color: var(--base-blue);
      &:not(:first-child) {
        margin-left: rem(32);
      }

      @include phone() {
        font: normal normal bold rem(14) / rem(19) PT Sans;
      }
    }
    &-edit {
      display: flex;
      @include phone() {
        font-size: rem(20);
      }
    }
  }
}
