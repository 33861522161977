@import "../../../../common.scss";

.card-label {
  span {
    padding: rem(4) rem(16);
    border-radius: 16px;
    font: normal normal bold rem(12) / rem(32) PT Sans;
  }

  &--discussion {
    background: var(--base-purple);
    color: #fff;
  }

  &--blog {
    background: #e8e8e8;
    color: var(--base-text);
  }
}
