@import "../../../../../common.scss";

.selection-move-form {
  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }

  &__submit {
    @include phone() {
      margin-top: 20px;
    }
  }
}
