@import "../../../../../../common.scss";

.cr-chat {
  height: 100%;
  position: relative;
  @include afterBeforeBackground(white);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: -72px;

  @include tablet() {
    margin-bottom: -88px;
  }

  @include phone() {
    margin-bottom: -20px;
  }
}

.chat-files-loading__modal {
  height: 200px;
  .react-responsive-modal-closeButton {
    display: none;
  }
}

