@import "../../../common.scss";

.blocked-page {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 130px;

    @include tablet() {
      margin-top: 220px;
    }

    @include phone() {
      margin-top: 20px;
    }
  }

  &__image {
    background: transparent url("../../../assets/user/userBlocked.svg") 0% 0% no-repeat padding-box;
    background-size: cover;
    opacity: 1;
    width: 270px;
    height: 270px;
    margin-bottom: 36px;

    @include phone() {
      width: 144px;
      height: 144px;
      margin-bottom: 20px;
    }
  }

  &__text {
    text-align: center;
    font: normal normal normal 20px/28px PT Sans;
    letter-spacing: 0.16px;
    color: var(--base-text);
    margin-bottom: 16px;
    margin-left: 23px;
    margin-right: 23px;

    @include tablet() {
      font: normal normal normal 18px/24px PT Sans;
      letter-spacing: 0.15px;
    }

    @include phone() {
      font: normal normal normal 16px/24px PT Sans;
      letter-spacing: 0.13px;
    }

    &--error {
      font: normal normal bold 60px/72px Bebas Neue;
      letter-spacing: -0.5px;
      color: pink;
      margin-bottom: 48px;

      @include tablet() {
        font: normal normal bold 50px/60px Bebas Neue;
        letter-spacing: -0.42px;
      }

      @include phone() {
        font: normal normal bold 40px/40px Bebas Neue;
        letter-spacing: -0.33px;
      }
    }

    &--countdown {
      font: normal normal bold 30px/36px Bebas Neue;
      letter-spacing: 0.12px;

      @include tablet() {
        font: normal normal bold 20px/24px Bebas Neue;
        letter-spacing: 0.15px;
      }

      @include phone() {
        font: normal normal bold 24px/29px Bebas Neue;
        letter-spacing: -0.29px;
      }

      &__digits {
        font: normal normal bold 36px/43px Bebas Neue;
        letter-spacing: 0.01px;
        color: var(--base-blue);
        margin-left: 16px;

        @include tablet() {
          font: normal normal bold 24px/29px Bebas Neue;
          letter-spacing: 0.18px;
        }

        @include phone() {
          font: normal normal bold 36px/43px Bebas Neue;
          letter-spacing: 0.01px;
        }
      }
    }
  }
}
