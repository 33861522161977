@import "../../../common.scss";

.not-started-page {
  margin-top: 250px;
  display: flex;
  justify-content: center;

  h2 {
    font: normal normal normal rem(28) / rem(32) PT Sans;
    letter-spacing: 0.25px;
    color: #65aae1;
    width: max-content;
  }
}
