@import "../../../common.scss";

.carousel-slider {
  display: flex;

  &__prev,
  &__next {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0px 4px 16px #0000001a;
    opacity: 0.8;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    z-index: 100;

    @include tablet() {
      width: 40px;
      height: 40px;
    }
  }
  &__prev {
    margin: 0 7px 0 0;
  }
  &__next {
    margin: 0 0 0 7px;
  }
}

.carousel-one-page {
  display: flex;

  @include phone() {
    flex-direction: column;
    &__item {
      width: 100% !important;
    }
  }
}
