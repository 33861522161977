@import "../../../common";

.post-add-favorite {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 24px;

  i.ui-icon-favorite {
    color: var(--textarea-hint-color);
  }

  i.ui-icon-favorite-filled {
    color: var(--base-gold);
  }

  &__small {
    font-size: 20px;
  }

  &__count {
    font: normal normal bold rem(18px) / rem(24px) PT Sans;
    color: var(--base-text);

    @include tablet() {
      font: normal normal bold rem(16px) / rem(24px) PT Sans;
    }

    @include phone() {
      font: normal normal bold rem(14px) / rem(19px) PT Sans;
    }
  }

  > i {
    margin-right: rem(10px);
    font-size: rem(20px);
    @include phone() {
      margin-right: rem(5px);
      font-size: rem(20px);
    }
  }
}
