@import "./../../../../common";

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 50%;
  right: 0;
  margin: auto;
  padding: rem(24);
  background-color: var(--base-white);
  box-shadow: 0 3px 15px rgba(40, 40, 40, 0.149);
  align-items: baseline;
  width: max-content;
  z-index: 20;
  transform: translate(-50%, 0);

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom-color: var(--base-white);
    border-top: 0;
    margin-left: -8px;
    margin-top: -8px;
  }

  &__link > a {
    font: normal normal bold 16px/22px PT Sans;
    letter-spacing: 0px;
    color: var(--base-text);
    cursor: pointer;
  }

  &__link:not(:last-of-type) {
    margin-bottom: 16px;
  }

  &__link:hover {
    color: var(--base-blue);
  }
}
