@import "../../../common.scss";

.action-tag {
  position: relative;
  font-size: 0;

  & &__btn {
    border-radius: 22px;
    padding: 0 8px 0 16px;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    height: 24px;

    .cr-button__text {
      display: flex;
      align-items: center;
    }

    .ic {
      font-size: 20px;
      &.ui-icon-chevron-up,
      &.ui-icon-chevron-down {
        margin-left: 6px;
      }
    }
  }

  ul {
    width: max-content;
    list-style: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: #fff;
    box-shadow: 0px 4px 24px #65aae04d;
    padding: 7px 0;

    li {
      padding: 6px 24px;
      font: normal normal normal 16px/20px PT Sans;
      color: #000000e6;
      cursor: pointer;

      &:hover {
        background-color: #cceffc;
      }
    }
  }
}
