@import "../../../../../common.scss";

.selection-criteria {
  * {
    font-size: 20px;
    line-height: 26px;
    font-family: PT Sans;
    letter-spacing: 0;

    @include tablet-only() {
      font-size: 18px;
      line-height: 24px;
    }

    @include phone() {
      font-size: 16px;
      line-height: 20px;
    }
  }

  ul,
  ol {
    margin-top: 30px;
    padding-left: 16px;

    @include tablet-only() {
      margin-top: 20px;
    }

    @include phone() {
      margin-top: 8px;
    }

    &:not(:last-child) {
      margin-bottom: 48px;

      @include tablet-only() {
        margin-bottom: 32px;
      }

      @include phone() {
        margin-bottom: 14px;
      }
    }
  }

  li {
    padding-left: 28px;
    margin-bottom: 6px;

    @include phone() {
      padding-left: 23px;
    }
  }
}
