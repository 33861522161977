.crowd-table-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  &__header {
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
    padding: 0.75rem 1rem;
    border-bottom: 2px solid var(--color-secondary-l5);
    color: var(--color-secondary);
  }

  &__row {
    border: 1px solid var(--color-secondary-l5);
    border-radius: 0.25rem;
  }

  &__column {
    display: grid;
    grid-template-columns: 104px 1fr;
    border-bottom: 1px solid var(--color-secondary-l5);
    padding: 0.75rem 1rem;

    &-header {
      font-size: 0.875rem;
      line-height: 18px;
      color: var(--color-secondary-l2);
    }

    &-value {
      font-size: 1rem;
      line-height: 19px;
      color: var(--color-secondary);
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
