@import "../../../common.scss";

.went-wrong {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;

  > h1 {
    font-size: 2rem;
    color: #65aae1;
  }

  a {
    color: var(--base-blue);
  }
}
