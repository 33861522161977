@import "../../../../common.scss";

.stage-selector-modal {
  $root: &;
  letter-spacing: 0;

  &__header {
    font: normal normal bold rem(36) / rem(34) Bebas Neue;
    @include tablet() {
      font: normal normal bold rem(32) / rem(38) Bebas Neue;
    }
    @include phone() {
      font: normal normal bold rem(24) / rem(29) Bebas Neue;
    }
  }

  &__body {
    margin-top: rem(32);
    @include phone() {
      margin-top: calc(var(--header-height-phone) - 15px);
    }
  }

  &__description {
    font: normal normal normal rem(18) / rem(28) PT Sans;
    color: var(--base-text);
    @include tablet() {
      font: normal normal normal rem(18) / rem(24) PT Sans;
    }
    @include phone() {
      font: normal normal normal rem(16) / rem(20) PT Sans;
    }
  }

  &__subhead {
    font: normal normal bold rem(24) / rem(32) PT Sans;
    margin-top: rem(32);
    @include tablet() {
      font: normal normal bold rem(22) / rem(29) PT Sans;
    }
    @include phone() {
      margin-top: rem(24);
      font: normal normal bold rem(20) / rem(26) PT Sans;
    }
  }

  &__short {
    #{$root}__body {
      margin-top: 0;
    }
  }

  &__stage-item {
    padding-top: rem(32);
    cursor: pointer;
    @include phone() {
      padding-top: rem(24);
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-secondary-l5);
      padding-bottom: rem(32);
      @include phone() {
        padding-bottom: rem(24);
      }
    }

    a {
      color: var(--base-blue);
    }

    &__title {
      font: normal normal bold rem(20) / rem(28) PT Sans;
      color: var(--base-blue);
      margin-bottom: rem(4px);
      @include tablet() {
        font: normal normal bold rem(18) / rem(26) PT Sans;
      }
      @include phone() {
        font: normal normal bold rem(16) / rem(22) PT Sans;
      }
    }

    &__countdown {
      font: italic normal normal rem(18) / rem(24) PT Sans;
      color: var(--select-placeholder-color);
      @include tablet() {
        font: italic normal normal rem(18) / rem(28) PT Sans;
      }
      @include phone() {
        font: italic normal normal rem(14) / rem(19) PT Sans;
      }
    }
  }
}

