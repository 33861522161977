@import "../../../../common.scss";

$first-column-width: 56.99%;
$second-column-width: 28.23%;
$third-column-width: 14.78%;

.notification-form {
  &__headers {
    display: grid;
    grid-template-columns: $first-column-width $second-column-width auto;
    font: normal normal normal rem(20) / rem(32) PT Sans;
    letter-spacing: 0;
    color: var(--color-secondary-l2);

    div:last-child {
      text-align: center;
    }
    @include tablet() {
      font: normal normal normal rem(18) / rem(24) PT Sans;
    }
    @include phone() {
      margin-top: rem(48);
      font: normal normal normal rem(14) / rem(18) PT Sans;
    }
  }

  &__notifications {
    margin-top: rem(32);
  }
  &__notification {
    display: grid;
    grid-template-columns: $first-column-width $second-column-width auto;
    margin-top: rem(24);
    @include phone() {
      div {
        font: normal normal normal rem(14) / rem(19) PT Sans;
      }
    }
    .cr-checkbox {
      width: fit-content;
      margin: auto;
    }
  }
  &__footer {
    .submit-form__footer {
      @include phone() {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
      }

      button {
        &:first-child {
          margin-right: rem(16);
          @include tablet() {
            margin-right: 0;
          }
        }
        @include phone {
          margin-right: 0;
        }
      }
    }
  }
}
