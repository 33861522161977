@import "../../../../common.scss";

.generation-modal {
  .generation__propose {
    &-description {
      font: normal normal normal rem(20) / rem(32) PT Sans;
      letter-spacing: 0px;
      margin-bottom: rem(32);
      @include tablet() {
        font: normal normal normal rem(18) / rem(24) PT Sans;
      }
      @include phone() {
        font: normal normal normal rem(16) / rem(20) PT Sans;
        margin-bottom: rem(20);
      }

      &__title {
        font: normal normal bold rem(24) / rem(32) PT Sans;
        letter-spacing: 0px;
        margin-bottom: rem(24);
        @include tablet() {
          font: normal normal bold rem(22) / rem(29) PT Sans;
        }
        @include phone() {
          font: normal normal bold rem(20) / rem(26) PT Sans;
          margin-top: rem(49);
          margin-bottom: rem(16);
        }
      }
    }

    &-title {
      font: normal normal bold rem(20) / rem(32) PT Sans;
      letter-spacing: 0px;
      @include tablet() {
        font: normal normal bold rem(18) / rem(26) PT Sans;
      }
      @include phone() {
        font: normal normal bold rem(16) / rem(22) PT Sans;
      }
    }
  }

  &__body {
    margin-top: rem(32px);
    @include phone() {
      margin-top: calc(var(--header-height-phone) - 15px);
    }
  }

  &__header {
    position: relative;
    @include phone() {
      position: fixed;

      .ui-icon-fullscreen-wrap {
        right: 24px;
        bottom: 8px;
      }
    }

    .ui-icon-fullscreen-wrap {
      position: absolute;
      right: 12px;
      bottom: 36px;

      .type-info {
        font-family: Helvetica, Arial, sans-serif;
      }

      button {
        outline: none;
        background: transparent;
        border: none;
        color: var(--base-gray);
        cursor: pointer;
      }
    }
  }

  button {
    border-radius: var(--button-size-m-radius);
    text-transform: none;
    span {
      line-height: rem(22px); // 32px дают отличную от макета высоту кнопки
    }
  }

  .spell-checker {
    margin-bottom: rem(32px);
  }

  .cr {
    &-select,
    &-textarea,
    &-input {
      margin-top: 0;
      margin-bottom: rem(32px);

      &__label {
        margin: 0;

        &-required {
          display: none;
        }
      }
    }

    &-select {
      &__placeholder {
        .ui-icon-arrow-slider-left {
          right: 20px;
          top: 22px;
          font-size: 16px;
        }
      }
    }
    &-file-selector {
      &__head {
        margin-top: 0;
      }
    }
  }

  button.react-responsive-modal-closeButton {
    top: 24px;
    right: 24px;
    @include phone() {
      top: 9px;
      right: 12px;
    }
  }

  @include phone() {
    &__header {
      .ui-icon-fullscreen-wrap {
        right: 48px;
        bottom: 26px;
      }
    }
  }
}
