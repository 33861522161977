.crowd-table {
  $root: &;

  table {
    width: 100%;
    border-collapse: collapse;
  }

  thead {
    font: normal normal normal 16px/14px PT Sans;
    letter-spacing: 0px;
    color: var(--color-secondary-l2);

    th {
      font: normal normal normal 16px/14px PT Sans;
      letter-spacing: 0px;
      color: var(--color-secondary-l2);
    }
  }

  tbody {
    td {
      font: normal normal normal 18px/28px PT Sans;
      letter-spacing: 0px;
      color: var(--color-secondary);
    }
  }

  thead > tr,
  tbody > tr:not(:last-child) {
    border-bottom: 1px solid var(--base-ligth-gray);
  }

  table th {
    padding: 12px;
  }
  table td {
    padding: 8px 12px;
  }

  &_interactive {
    table tbody tr {
      cursor: pointer;
      &:hover {
        background-color: var(--color-primary-l2);
      }
      &#{$root}__row-active {
        background-color: var(--color-primary-l1);
      }
    }
  }

  &__sort {
    cursor: pointer;

    &_active,
    &:hover {
      color: var(--color-primary);
    }

    > *:not(.ui-icon) {
      border-bottom: 1px dashed;
    }
  }

  .cr-checkbox {
    margin: 0;
    input {
      margin: 0;
    }
  }
}
