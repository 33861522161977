@import "../../../../common.scss";

.proposal-card {
  padding: rem(24);
  background-color: white;
  width: 100%;
  margin-top: rem(32px);
  border-radius: 4px;

  &__stageName {
    font: italic normal normal rem(20) / rem(32) PT Sans;
    letter-spacing: 0;
    color: #7e7e7e;

    @include tablet() {
      margin-bottom: rem(12);
      font: italic normal normal rem(18) / rem(24) PT Sans;
    }
    @include phone() {
      font: italic normal normal rem(14) / rem(19) PT Sans;
    }
  }

  &__date {
    font: italic normal normal rem(18) / rem(24) PT Sans;
    color: var(--color-secondary-l2);
    margin-top: rem(4);

    @include tablet {
      font-size: rem(16);
      line-height: rem(22);
    }

    @include phone() {
      font: italic normal normal rem(14) / rem(19) PT Sans;
      span {
        font: italic normal normal rem(14) / rem(19) PT Sans;
      }
    }
  }

  &__description {
    font: normal normal normal rem(20) / rem(32) PT Sans;
    letter-spacing: 0;
    color: var(--base-text);
    margin-top: rem(16);
    display: flex;
    flex-direction: column;

    @include tablet() {
      font: normal normal normal rem(18) / rem(24) PT Sans;
    }
    @include phone() {
      margin-top: rem(16);
      font: normal normal normal rem(16) / rem(20) PT Sans;
    }

    &__item {
      position: relative;

      &.multiple {
        padding-left: rem(36);
      }

      &:not(:last-child) {
        margin-bottom: rem(24);
      }

      &__number {
        position: absolute;
        left: 0;
      }

      &__title {
        font-weight: bold;
        margin-bottom: rem(16);
      }

      &__value {
        white-space: pre-wrap;
        a {
          cursor: pointer;
          color: var(--base-blue);
        }
      }
    }
  }

  &__reason {
    font: normal normal normal rem(20) / rem(32) PT Sans;
    letter-spacing: 0;
    color: var(--base-text);
    background-color: var(--base-ligth-gray);
    margin-top: rem(24);
    padding: rem(16) rem(24);
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    &-title {
      font-weight: bold;
      &:not(:first-child) {
        margin-top: 16px;
      }
    }

    @include tablet() {
      font: normal normal normal rem(18) / rem(24) PT Sans;
    }
    @include phone() {
      font: normal normal normal rem(16) / rem(20) PT Sans;
    }
  }

  &__attachments {
    margin-top: rem(24);
    display: flex;
  }

  &__actions {
    margin-top: rem(24);
    display: flex;
    button:first-child {
      margin-right: rem(16);
    }

    @include tablet() {
      .cr-tooltip {
        margin-top: 0;
      }

      .ui-icon-edit,
      .ui-icon-delete {
        font-size: rem(24px);
        color: var(--base-blue);
        cursor: pointer;
      }

      > * {
        margin-right: rem(16);
        @include phone() {
          margin-right: rem(8);
        }
      }
    }

    @include phone() {
      margin-top: rem(16);
    }
  }
  @include tablet() {
    margin-top: rem(24);
  }
  @include phone() {
    padding: rem(16);
    margin-top: rem(17);
    border-radius: unset;
  }
}
