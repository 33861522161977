@import "../../../../common.scss";

.active-tab,
.mobile-tab:hover {
  color: var(--base-blue) !important;
}

.mobile-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  color: var(--base-gray);
  cursor: pointer;
  width: rem(51);
  i.ui-icon {
    font-size: rem(32);
    position: relative;

    span {
      font: normal normal 900 rem(10) / rem(13) Roboto;
      position: absolute;
      top: 0;
      left: 66%;
      min-width: 16px;
      height: 16px;
      border-radius: 8px;
      background: var(--base-ligth-gray);
      line-height: 16px;
      padding: 0 5px;
      @include phone() {
        background: #fff;
      }
    }
  }
}
