@import "../../../../common.scss";

.comment-card-list {
  .comment-card:not(:first-child) {
    margin-top: rem(32);
  }

  .pagination {
    margin-top: rem(48);
  }

  &__empty {
    font: normal normal normal rem(20) / rem(32) PT Sans;
    color: var(--base-text);
  }

  &__title {
    font: normal normal 700 rem(28) / rem(32) PT Sans;
    margin-bottom: rem(32);
  }

  @include tablet() {
    &__title {
      font: normal normal bold rem(26) / rem(33) PT Sans;
    }

    .comment-card:not(:first-child) {
      margin-top: rem(24);
    }
  }

  @include phone() {
    &__title {
      font: normal normal bold rem(20) / rem(26) PT Sans;
      margin: rem(16);
    }

    .comment-card:not(:first-child) {
      margin-top: rem(16);
    }

    &__empty {
      margin: rem(16);
      form: normal normal normal rem(16) / rem(22) PT Sans;
    }
  }
}
