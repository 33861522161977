@import "../../../../common.scss";

.proposal {
  padding: rem(24);
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 100%;
  max-width: 1128px;
  margin-bottom: rem(32);
  border-radius: 4px;
  @include phone() {
    margin-bottom: rem(16);
    border-radius: unset;
  }

  &__clickable-area {
    display: flex;
    flex-direction: column;
  }
  &__title {
    // font: italic normal normal rem(20) / rem(32) PT Sans;
    font: normal normal bold rem(20) / rem(32) PT Sans;
    letter-spacing: 0;
    color: var(--base-text);
    @include tablet() {
      // font: italic normal normal rem(18) / rem(24) PT Sans;
      font: normal normal bold rem(18) / rem(24) PT Sans;
    }
    @include phone() {
      // font: italic normal normal rem(14) / rem(19) PT Sans;
      font: normal normal bold rem(14) / rem(19) PT Sans;
    }
  }
  &__description {
    font: normal normal normal rem(20) / rem(32) PT Sans;
    letter-spacing: 0;
    color: var(--base-text);
    margin-top: rem(12);
    display: flex;
    flex-direction: column;
    @include tablet() {
      font: normal normal normal rem(18) / rem(24) PT Sans;
    }
    @include phone() {
      font: normal normal normal rem(16) / rem(20) PT Sans;
    }
    &__item {
      position: relative;
      &.multiple {
        padding-left: rem(36);
      }

      &:not(:last-child) {
        margin-bottom: rem(12);
      }
      &__number {
        position: absolute;
        left: 0;
      }

      &__title {
        font-weight: bold;
        margin-bottom: rem(12);
        display: none;
      }

      &__value {
        white-space: pre-wrap;
        a {
          cursor: pointer;
          color: var(--base-blue);
        }
      }
    }
  }

  &__attachments {
    margin-top: rem(16);
    display: flex;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    .proposal__actions {
      margin-top: rem(66);
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;

      .cr-button {
        height: 44px;
        margin-top: 0;
        margin-right: 0;
      }
    }

    @include phone() {
      .proposal__actions {
        margin-top: unset;
        margin-bottom: 15px;
        align-items: end;

        .cr-button {
          height: 40px;
        }
      }
    }
  }
  &__actions {
    display: flex;
    margin-top: rem(24);
    button {
      margin-right: 16px;
      @include phone() {
        border: 0;
        font: normal normal bold rem(16) / rem(20) PT Sans;
        width: auto;
        padding: 0;

        &:hover {
          background-color: transparent;
        }
        &:active {
          color: var(--color-primary-d1);
          background-color: transparent;
        }
      }
    }
    @include phone() {
      margin-top: rem(16);
    }
  }

  &__voting {
    display: flex;
    flex-direction: column;
    &__selected-vote {
      display: flex;
      align-items: center;
      margin-top: rem(24);
      @include phone() {
        align-items: flex-start;
        width: 100%;
      }

      .rating-icon {
        margin-right: rem(12);
        font-size: rem(24);
        @include phone() {
          font-size: rem(20);
        }
      }

      &__text {
        color: var(--base-text);
        font: normal normal bold rem(20) / rem(32) PT Sans;
        letter-spacing: 0;
        @include tablet() {
          font: normal normal bold rem(18) / rem(26) PT Sans;
        }
        @include phone() {
          flex-grow: 1;
          font: normal normal bold rem(16) / rem(20) PT Sans;
        }
      }
      .ui-icon-edit {
        margin-left: rem(24);
        cursor: pointer;
        color: #9a9a9a;
        @include phone() {
          font-size: rem(20);
        }
      }
    }
    &__question {
      font: normal normal bold rem(20) / rem(32) PT Sans;
      letter-spacing: 0;
      color: var(--base-text);
      margin-top: rem(24);
      @include tablet() {
        font: normal normal bold rem(18) / rem(26) PT Sans;
      }
      @include phone() {
        font: normal normal bold rem(16) / rem(20) PT Sans;
      }
    }
  }
  @include phone() {
    padding: rem(16);
  }
}
