@import "../../../common.scss";

.user-info {
  $root: &;
  position: relative;

  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;

  > :first-child {
    grid-row: 1/3;
    margin-right: 16px;

    @include phone() {
      margin-right: 8px;
    }
  }

  &__sub-text {
    font: italic normal normal 18px/24px PT Sans;
    color: var(--color-secondary-l2);

    @include phone() {
      font-size: 12px;
    }
  }

  &__name {
    color: var(--base-text);
    align-self: flex-end;
    font: normal normal bold 20px/24px PT Sans;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 0 8px;

    @include phone() {
      font-size: 16px;
    }

    #{$root}__sub-text {
      @include phone() {
        font-size: 14px;
        gap: 4px;
      }
    }

    &-text {
      display: inline-block;
    }
  }

  &__add-info {
    align-self: flex-start;
    margin-top: 4px;

    @include tablet-only() {
      margin-top: 0;
      margin-bottom: 4px;
    }

    @include phone() {
      margin-top: 0;
      line-height: 16px;
    }
  }

  &__icon-button {
    color: var(--color-secondary-l2);
    position: relative;
    outline: none;
    border: none;
    cursor: pointer;
    background: transparent;
    &-wrapper {
      position: absolute;
      right: -34px;
      top: 8px;

      @include tablet() {
        top: 2px;
      }
    }
    &:hover {
      color: var(--base-blue);
    }
  }
  &--icon-button {
    margin-right: 34px;
  }
}
