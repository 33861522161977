@import "../../../common";

$container-padding: 20px;

.private-page {
    display: flex;
    justify-content: center;
    align-items: center;

    &__container {
        max-width: calc(600px + (#{$container-padding} * 2));
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 $container-padding;

        @include tablet() {
            max-width: calc(472px + (#{$container-padding} * 2));
            margin-top: 120px;
        }

        @include phone() {
            margin-top: 16px;
        }

        &-text {
            margin-top: 22px;

            @include phone() {
                margin-top: 6px;
            }

            p {
                font: normal normal normal 20px/28px PT Sans;
                letter-spacing: 0.16px;
                color: #282828;
                margin-top: 10px;
    
                @include tablet() {
                    font: normal normal normal 20px/28px PT Sans;
                    letter-spacing: 0.16px;
                    font: normal normal normal 18px/24px PT Sans;
                    letter-spacing: 0.15px;
                }
    
                @include phone() {
                    font: normal normal normal 16px/20px PT Sans;
letter-spacing: 0px;
                    margin-top: 16px;
                }
            }

        }

        .cr-button {
            border-radius: 0;
            margin-top: 32px;
            font: normal normal bold 20px/26px PT Sans;
            letter-spacing: 0.15px;

            @include phone() {
                font: normal normal bold 14px/19px PT Sans;
                letter-spacing: 1.35px;
            }
        }

        .help-btn {
            font: normal normal normal 20px/28px PT Sans;
            letter-spacing: 0.16px;
            color: #65AAE0;
            display: flex;
            align-items: center;
            margin-top: 33px;

            i {
                font: inherit;
                margin-right: 5px;
            }

            @include phone() {
                font: normal normal normal 18px/24px PT Sans;
                letter-spacing: 0.15px;
            }
        }
    }
}