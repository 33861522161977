@import "../../../../common";

.stage-mobile-card {
  font: normal normal normal rem(16) / rem(20) PT Sans;
  padding: rem(16px) rem(30px);
  border-radius: 16px;
  margin-bottom: rem(12);
  color: var(--base-text);
  cursor: pointer;

  &__title {
    font: normal normal bold 20px/26px PT Sans;
    letter-spacing: 0px;
    color: var(--base-text);
    margin-bottom: rem(8);
  }

  &__directions {
    display: flex;
    flex-direction: column;
  }

  &__direction:not(:last-child) {
    margin-bottom: 4px;
  }

  &__direction {
    font: normal normal normal 16px/20px PT Sans;
    letter-spacing: 0px;
    color: var(--base-text);
    outline: none;

    text-align: left;
    transition: background-color 0.2s;
    padding: rem(8) rem(12);
    margin-left: -8px;
    border-radius: 4px;

    &--disable {
      background-color: rgba(255, 255, 255, 0.6);
    }
  }

  &__stage {
    margin: rem(8) 0;
  }
}
