@import "./../../../common";

@mixin navbar-font {
  text-align: center;
  text-decoration: none;
  position: relative;
  font: normal normal bold rem(20) / rem(24) PT Sans;
  letter-spacing: 0.15px;
  color: var(--base-text);
  cursor: pointer;

  &:hover {
    color: var(--base-blue);
  }
}

.cr-header:not(.cr-header-logged-in) {
  @include tablet() {
    .cr-header__toggle__entry {
      margin-left: rem(19);
    }
  }
}

.cr-header {
  position: fixed;
  display: -webkit-flex;
  display: flex;
  z-index: 20;
  width: 100%;
  background: var(--base-white);
  border-bottom: 1px solid var(--base-border);
  top: 0;
  left: 0;
  right: 0;
  max-height: var(--header-height);
  height: var(--header-height);

  .logged-out .ui-icon {
    position: relative;
    top: 2px;
  }

  &__inner {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1408px;
    width: 100%;
    padding: 0 1.5rem;

    .logo {
      margin-right: rem(48);
      @include tablet {
        margin-right: 0;
      }
    }
  }

  &__toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 1rem;

    .logged-out {
      padding-right: 0;
    }

    .logged-in-avatar {
      margin: 0;
    }
  }

  &__routes {
    display: flex;
    width: 100%;

    .active-link {
      color: var(--base-blue);
    }

    .open-link-with-children {
      color: var(--base-gray);
    }
  }

  &__link {
    list-style-type: none;
    padding: rem(24) rem(20);
    position: relative;
    display: flex;
    align-items: center;

    a,
    span {
      @include navbar-font;
    }

    span {
      cursor: default;
    }
  }

  @include wide-tablet() {
    &__link {
      padding: rem(24) rem(12);
    }
  }

  @include tablet() {
    max-height: var(--header-height-tablet);
    height: var(--header-height-tablet);

    .ui-icon-hamburger {
      font-size: rem(20);
    }

    &__toggle {
      justify-content: flex-end;
    }

    &__home-link {
      margin-right: rem(32);
    }

    &__toggle {
      &__entry {
        font: normal normal bold rem(18) / rem(26) PT Sans;
        color: var(--base-text);

        &:hover {
          color: var(--base-blue);
          cursor: pointer;
        }
      }
    }

    &__items.active-menu {
      height: 100vh;
    }

    &__items {
      position: absolute;
      top: calc(100% + 1px);
      width: 100%;
      background-color: var(--base-white);
      height: 0;
      overflow-y: hidden;
      transition: height 0.2s ease-in;

      &__home {
        padding: rem(26) rem(16) rem(8) 0;
        border-top: 1px solid var(--base-border);
        margin-left: rem(40);
        color: var(--base-blue);

        a {
          display: flex;
          align-items: center;

          i {
            margin-right: rem(8);
          }
        }

        &:hover {
          color: var(--base-blue);
          cursor: pointer;
        }
      }
    }

    &__routes {
      padding: rem(24) 0 rem(16);
      flex-direction: column;
      margin-top: 1px;
    }

    &__routes.children {
      border-top: none;
      padding: 0;

      .cr-header__link {
        a,
        span {
          padding: rem(5) rem(48);
        }
      }
    }

    &__link {
      padding: 0;
      flex-direction: column;
      align-items: flex-start;

      a,
      span {
        font: normal normal bold rem(34) / rem(42) Bebas Neue;
        display: flex;
        padding: rem(8) rem(24) rem(8) rem(40);
        width: 100%;
        text-align: left;
      }

      > span {
        cursor: pointer;
      }
    }
  }

  @include phone() {
    border-bottom: 1px solid transparent;
    max-height: var(--header-height-phone);
    height: var(--header-height-phone);

    .ui-icon-hamburger {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__inner {
      padding: 0 rem(16);
    }

    &__link {
      a,
      span {
        font: normal normal bold rem(20) / rem(24) Bebas Neue;
      }
    }
  }
}
