@import "../../../common.scss";

.access-denied {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;

  &__title {
    font: normal normal bold 60px/72px Bebas Neue;
    letter-spacing: -0.5px;
    color: pink;
    margin-bottom: 48px;
    text-align: center;

    @include tablet() {
        font: normal normal bold 50px/60px Bebas Neue;
        letter-spacing: -0.42px;
      }

      @include phone() {
        font: normal normal bold 40px/40px Bebas Neue;
        letter-spacing: -0.33px;
      }
  }

  &__text {
    text-align: center;
    font: normal normal normal 20px/28px PT Sans;
    letter-spacing: 0.16px;
    color: var(--base-text);
    margin: 0 24px 16px;

    @include tablet() {
        font: normal normal normal 18px/24px PT Sans;
        letter-spacing: 0.15px;
      }
  
      @include phone() {
        font: normal normal normal 16px/24px PT Sans;
        letter-spacing: 0.13px;
      }
  }

  a {
    color: var(--base-blue);
  }
}
