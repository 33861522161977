@import "../../../../common.scss";

.hidden-fio-warning {
  position: relative;
  margin-left: 14px;
  z-index: 2;
  @include tablet() {
    margin-left: 12px;
  }

  @include phone() {
    margin-left: 4px;
  }

  &_inline {
    margin-left: 0;
    display: flex;
    align-items: flex-start;

    &__text {
      font: normal normal normal 14px/19px PT Sans;
      color: var(--base-text);
      a {
        color: var(--color-primary);
      }
    }
  }

  &__icon {
    color: #ff5f76;
    font-size: 20px;
    margin-right: 12px;
  }
}

#hidden-fio-warning-tooltip {
  width: fit-content;
  max-width: 542px;
  background-color: #777b8c;
  box-shadow: 0px 4px 20px #0000001a;
  text-transform: capitalize;
  margin: 0;
  font: normal normal normal 14px/20px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  border-radius: 4px;
  padding: 16px;
  text-transform: inherit;
  z-index: 1000 !important;

  @include phone {
    max-width: calc(100% - 48px);
  }

  a {
    color: var(--base-blue);
  }

  &::before,
  &::after {
    content: none;
  }
}
