@import "../../../../common.scss";

$experdCardWidth: 432px;
$experdCardWidthTablet: 348px;
$experdCardWidthPhone: 100%;

.team__experts__expert-card {
  display: flex;
  flex-direction: column;
  width: $experdCardWidth;
  min-height: 160px;
  margin-top: rem(24);
  position: relative;

  &--basic {
    display: flex;
  }

  &__picture {
    flex-shrink: 0;
    width: 128px;
    height: 128px;
    background-color: var(--base-border);
    border-radius: 4px;
    @include adaptive(1200) {
      width: 100px;
      height: 100px;
    }
    @include phone() {
      width: 66px;
      height: 66px;
      margin-right: rem(8);
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-left: rem(24);

    @include tablet() {
      margin-left: rem(16);
    }
    @include phone() {
      margin-left: rem(8);
    }
  }

  &__name {
    font: normal normal bold rem(20) / rem(28) PT Sans;
    letter-spacing: 0px;
    color: var(--base-text);
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: rem(5);

    @include tablet() {
      font: normal normal bold rem(18) / rem(26) PT Sans;
    }
    @include phone() {
      font: normal normal bold rem(16) / rem(22) PT Sans;
    }
  }

  &__position {
    font: normal normal normal rem(16) / rem(22) PT Sans;
    letter-spacing: 0;
    color: var(--textarea-hint-color);
    visibility: visible;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &.invisible {
      visibility: hidden;
    }

    @include tablet() {
      font: normal normal normal rem(14) / rem(20) PT Sans;
    }
  }

  button {
    margin-top: rem(12);
    position: absolute;
  }
  @include adaptive(1400) {
    width: 31%;
  }
  @include adaptive(950) {
    width: 47%;
  }
  @include tablet() {
    width: 48%;
    margin-top: rem(32);
    min-height: auto;
  }
  @include adaptive(650) {
    width: 47%;
  }
  @include phone() {
    margin-top: rem(16);
    width: $experdCardWidthPhone;
  }
}
.team__experts__expert-card__wrapper {
  position: relative;
  padding: rem(24) rem(48);
  background-color: var(--base-ligth-gray);
  width: 100%;
  border-radius: 4px;
  z-index: 1;
  @include tablet() {
    padding: rem(48) rem(62);
  }
  @include phone {
    border-radius: unset;
  }
}