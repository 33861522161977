@import "../../../common.scss";

.profile-page {
  display: flex;
  flex-direction: column;
  .profile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: rem(24) 0 rem(32);
    position: relative;

    &--empty {
      padding: 0 !important;
    }

    @include afterBeforeBackground(white);

    @include tablet() {
      padding: rem(25) 0 rem(24);
    }

    @include phone() {
      padding: rem(12) rem(16);
      .cr-back-button {
        margin: 0;
        font: normal normal bold rem(14) / rem(19) PT Sans;
      }
    }

    &__user {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @include phone() {
        width: 100%;
        justify-content: center;
      }

      .profile-pic {
        @include phone() {
          display: none;
        }
      }
    }

    &__pic {
      width: 96px;
      border-radius: 100%;
    }

    &__apart {
      margin-left: rem(20);
      @include phone() {
        margin: 0;
      }
    }

    &__names {
      font: normal normal bold rem(24) / rem(32) PT Sans;
      letter-spacing: 0px;
      color: var(--base-text);

      @include tablet() {
        font: normal normal bold rem(22) / rem(29) PT Sans;
      }
      @include phone() {
        text-align: center;
        font: normal normal bold rem(20) / rem(26) PT Sans;
      }
    }

    &__edit {
      display: flex;
      align-items: center;
      font: normal normal normal rem(16) / rem(23) PT Sans;
      letter-spacing: 0;
      color: #65aae0;
      margin-top: rem(8);
      cursor: pointer;
      @include tablet() {
        font: normal normal bold rem(16) / rem(24) PT Sans;
      }
      @include phone() {
        font: normal normal normal rem(12) / rem(14) PT Sans;
      }

      .ui-icon-edit {
        font-size: rem(20);
        margin-right: rem(14);
        @include tablet() {
          font-size: rem(24);
          margin-right: rem(12);
        }
        @include tablet() {
          font-size: rem(20);
          margin-right: rem(12);
        }
      }
    }
  }
}
