@import "../../../common.scss";

.timetable-page {
  letter-spacing: 0;

  &__date {
    margin-top: rem(4);
    font: normal normal bold rem(24) / rem(32) PT Sans;
    color: var(--base-text);
  }

  &__switch {
    display: flex;
    justify-content: end;
    padding-right: 36px;
    margin: 32px 0;

    .cr-checkbox {
      margin-top: 0px;
    }

    @include tablet() {
      margin: 25px 0;
    }

    @include phone() {
      justify-content: start;
      margin: 16px 0 -8px 0;
    }
  }

  .stage-list {
    margin-top: rem(56);
  }

  &__switch + .stage-list {
    margin-top: 0;
  }
  
  .divider {
    margin-top: rem(32);
  }

  @include tablet() {
    &__date {
      font: normal normal bold rem(22) / rem(29) PT Sans;
    }

    .stage-list {
      margin-top: rem(32);
    }

    &__switch + .stage-list {
      margin-top: 0;
    }
  }

  @include phone() {
    padding: 0 rem(16);

    &__date {
      font: normal normal normal rem(14) / rem(20) PT Sans;
      color: var(--select-placeholder-color);
    }

    .stage-list {
      margin-top: 0;
    }

    &__switch + .stage-list {
      margin-top: 0;
    }
  }
}
