@import "../../../common.scss";

.team {
  background-color: #ffffff;

  .white-block {
    z-index: 1;
  }

  &__loader {
    padding-top: rem(32);
  }

  &__frontmen,
  &__coordinators {
    z-index: 2;
  }

  button {
    width: 162px;
    @include phone() {
      margin-top: rem(-5);
    }
  }

  &__group-header {
    display: flex;
    margin-bottom: rem(32);

    &__title {
      font: normal normal bold rem(36) / rem(42) Bebas Neue;
      letter-spacing: 0;
      color: #3d3d3d;
      min-width: 316px;
      margin-right: rem(32);
      @include tablet() {
        margin-right: 0;
        margin-bottom: rem(24);
        font: normal normal bold rem(38) / rem(47) Bebas Neue;
      }
      @include phone() {
        margin-bottom: rem(16);
        font: normal normal bold rem(26) / rem(30) Bebas Neue;
      }
    }
    &__description {
      font: normal normal normal rem(20) / rem(32) PT Sans;
      letter-spacing: 0;
      color: var(--base-text);
      @include tablet() {
        font: normal normal normal rem(18) / rem(24) PT Sans;
      }
      @include phone() {
        font: normal normal normal rem(16) / rem(20) PT Sans;
      }
    }

    @include tablet() {
      margin-bottom: 0;
      flex-direction: column;
    }
    @include phone() {
      margin-bottom: rem(8);
    }
  }

  &__experts,
  &__frontmen,
  &__coordinators {
    padding: rem(56) 0;

    @include tablet() {
      padding: rem(32) 0;
    }
    @include phone() {
      padding: rem(24) 0;
    }
  }

  &__experts {
    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 32px;
      row-gap: 48px;
      @include tablet() {
        grid-column-gap: 24px;
        grid-row-gap: 32px;
      }
      @include phone() {
        grid-row-gap: 0;
        grid-template-columns: 1fr;
        justify-content: flex-start;
      }
      @include adaptive(1400) {
        justify-content: space-between;
      }
    }
  }

  &__frontmen {
    position: relative;
    background: var(--base-ligth-blue);
    @include afterBeforeBackground(var(--base-ligth-blue));
    &__list {
      @include tablet() {
        margin-top: rem(32);
      }
      @include phone() {
        margin-top: 0;
      }
      .swiper-slide {
        min-width: 205px;
        @include tablet() {
          min-width: 185px;
        }
      }
      .carousel-slider__prev,
      .carousel-slider__next {
        top: 104px;
      }
    }
  }

  &__coordinators {
    position: relative;
    background: var(--base-ligth-gray);
    &__card__block {
      margin-left: 0;
    }
    @include afterBeforeBackground(var(--base-ligth-gray));
    &__list {
      @include tablet() {
        margin-top: rem(32);
      }
      @include phone() {
        margin-top: 0;
      }
      .swiper-slide {
        min-width: 210px;
        @include tablet() {
          min-width: 185px;
        }
      }
      .carousel-slider__prev,
      .carousel-slider__next {
        top: 95px;
      }
    }
  }

  @include phone() {
    padding: 0 rem(16);
  }
}
