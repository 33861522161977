.badge {
  &__container {
    position: relative;
    display: inline-block;
  }

  &__item {
    display: inline-block;
    position: absolute;
    right: 1px;
    top: 3px;
    border-radius: 50%;
    transform: translate(40%, -50%);
    padding: 4px 5px;
    font-weight: bold;
    line-height: 10px;
  }
}
