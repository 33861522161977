@import "../../../../../common";

.warning-table {
  table {
    width: 100%;
    margin-top: rem(16);

    thead th {
      font: normal normal normal rem(16) / rem(14) PT Sans;
      color: var(--textarea-color-placeholder);
      padding: rem(16) rem(4);
    }

    tbody td {
      font: normal normal normal rem(18) / rem(28) PT Sans;
      border-top: 1px solid var(--color-secondary-l5);
      color: var(--base-text);
      padding: rem(16) rem(4);
    }
  }

  &__no-warnings-msg {
    font: normal normal normal rem(18) / rem(26) PT Sans;
    color: var(--base-text);
    margin: rem(32) 0;
  }
}