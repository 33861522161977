@import "../../../../../../common.scss";

.RangedDatePicker {
  display: flex;
  border: 1px solid var(--base-blue);
  border-radius: 4px;
  font: normal normal normal 18px/24px PT Sans;
  letter-spacing: 0px;
  background-color: white;
  align-items: center;

  input {
    border: none;
    outline: none;
    padding: 10px;
    border-radius: 3px;
    width: 105px;
    cursor: pointer;
  }

  i {
    color: var(--color-secondary-l2);
    margin-right: rem(8);
    margin-left: auto;
    @include tablet-only() {
      margin-right: 14px;
    }
    @include phone() {
      position: absolute;
      right: 15px;
      margin-right: 0;
    }
  }

  &__footer {
    justify-content: flex-end;
    display: flex;
    margin: 10px 5px;
  }

  .PickerFrom.all-dates {
    input {
      width: 220px;
    }
  }

  .PickerTo.all-dates {
    input {
      width: 0px;
      padding: 0;
    }
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .DayPicker-Day {
    border-radius: 0 !important;
  }
  .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}
