@import "src/common";

.social-network {
  display: flex;

  &__item {
    cursor: pointer;
  }

  a:not(:last-child) &__item {
    margin-right: rem(16);
  }
}
