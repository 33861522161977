@import "../../../common.scss";

.generation {
  font-family: PT sans, serif;

  .page-title {
    margin-top: (24);
  }

  &__stage-announce,
  &__stage-finished {
    font: normal normal bold rem(28) / rem(32) PT Sans;
    letter-spacing: 0;
    color: var(--base-text);
  }
  &__stage-finished {
    @include phone() {
      margin-left: rem(16);
    }
  }

  &__is-team-member {
    font: normal normal normal rem(20) / rem(32) PT Sans;
    letter-spacing: 0;
    color: var(--base-text);

    @include phone() {
      font: normal normal normal 16px/20px PT Sans;
      letter-spacing: 0px;
      padding: 0 1rem;
    }
  }

  &__content {
    width: 100%;

    a {
      color: var(--base-blue);
    }

    &-switch {
      margin-bottom: rem(32);
    }
    .empty {
      margin-bottom: rem(48);

      @include tablet() {
        margin-bottom: rem(40);
      }
      @include phone() {
        margin-bottom: rem(20);
      }
    }
    button {
      border-radius: var(--button-size-m-radius);
      text-transform: none;
      font: var(--button-size-m-font);
      span {
        line-height: rem(22); // 32px дают отличную от макета высоту кнопки
      }
    }
  }

  &__criteria {
    &-description {
      font: var(--text-font);
      margin-bottom: rem(48);
    }

    &-lists {
      font: var(--text-font);
      padding: rem(32) rem(40);
      background-color: var(--color-primary-l1);
      border-radius: 4px;

      ol,
      ul {
        list-style-position: inside;
        margin-top: rem(12);
        margin-bottom: rem(32);

        @include phone() {
          margin-bottom: rem(22);
        }

        li:before {
          content: "";
          display: inline-block;
          margin-right: rem(24);
        }
      }
    }
  }

  &__ideas {
    display: flex;
    flex-flow: column nowrap;
    font-size: rem(20);
    max-width: 896px;

    .proposal-card {
      padding: rem(32) 0;
      margin: 0;

      &:first-child {
        padding-top: 0;
      }
      &:not(:last-child) {
        border-bottom: 1px solid var(--color-secondary-l5);
      }
    }
  }

  .cr {
    &-select,
    &-textarea,
    &-input {
      margin-top: 0;
      margin-bottom: rem(32);

      &__label {
        margin: 0;

        &-required {
          display: none;
        }
      }
    }

    &-file-selector {
      &__head {
        margin-top: 0;
      }
    }
  }

  .data-loader-wr {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #fffd;
    z-index: 1000;
  }

  @include tablet() {
    &__criteria-description,
    &__criteria-lists,
    &__criteria-lists li {
      font: var(--text-tablet-font);
    }
  }

  @include phone() {
    &__ideas {
      .proposal-card {
        padding: rem(16) 0;
      }
    }

    &__content {
      padding: rem(16);
    }

    &__criteria-lists li {
      font: var(--text-mobile-font);
    }

    &__criteria-description {
      font: var(--text-mobile-font);
      margin: 0 0 rem(16);
    }

    &__criteria-lists {
      font: var(--text-mobile-font);
      padding: rem(16);
      border-radius: unset;
      margin: 0 -1rem;
    }

    .cr-button {
      font: var(--button-mobile-font);
    }
  }
}
