@import "../../../../../common";

.personal-blog {
  min-height: calc(100vh - 376px);
  .data-loader-wr {
    min-height: 80vh;
  }
  .page-title {
    font: normal normal bold rem(28) / rem(32) PT Sans;
    text-transform: none;
    margin-top: rem(56);
  }
  &__posts {
    margin-top: rem(32);

    > div {
      margin: 0 auto;
    }

    > div:not(:last-of-type) {
      margin-bottom: rem(32);
    }

    &__post {
      border-radius: 4px;
      background: #fff;
      max-width: 1127px;
      word-break: break-word;
      @include phone {
        border-radius: unset;
      }
    }
  }
}

@include tablet() {
  .personal-blog {
    .page-title {
      font: normal normal bold rem(26) / rem(33) PT Sans;
      margin-top: rem(40);
    }
  }
}

@include phone() {
  .personal-blog {
    .page-title {
      font: normal normal bold rem(20) / rem(26) PT Sans;
      margin: rem(20) rem(16) rem(16);
    }

    &__list {
      margin-top: rem(16);
    }

    &__posts {
      margin-top: rem(20);

      > div:not(:last-of-type) {
        margin-bottom: rem(16);
      }
    }
  }
}
