@import "../../../../../common.scss";

.select-main-idea-modal {
  $root: &;

  width: 1224px !important;

  #close-modal-icon {
    display: none;

    @include phone() {
      display: block;
    }
  }

  &__header {
    color: var(--color-secondary);

    @include tablet-only() {
      margin-top: 6px;
    }

    @include phone() {
      color: var(--color-secondary);
      font: normal normal bold 26px/30px Bebas Neue;
      margin-bottom: 20px;
    }
  }

  &__body {
    @include tablet-only() {
      margin-top: 30px;
    }
  }

  &__timer {
    font: normal normal bold 18px/24px PT Sans;
    color: var(--color-negative);
    text-transform: none;
    position: absolute;
    top: 24px;
    right: 24px;
  }

  &__ideas {
    display: flex;
    gap: 2rem;

    @include tablet() {
      flex-direction: column;
      gap: 24px;
    }

    @include phone() {
      gap: 20px;
    }

    &__item {
      position: relative;
      cursor: pointer;
      border: 1px solid var(--color-secondary-l5);
      padding: 24px;
      flex: 1;
      border-radius: 4px;

      @include tablet-only() {
        padding: 16px;
      }

      @include phone() {
        padding: 14px 16px;
      }

      #{$root}__selected-mark {
        color: var(--color-accent);
        position: absolute;
        top: 0;
        right: 40px;
        transform: translateY(-50%);
        font-size: 40px;
        background-color: var(--color-secondary-l7);

        @include tablet-only() {
          right: 16px;
          font-size: 31px;
          padding: 4px;
        }

        @include phone() {
          right: 16px;
          font-size: 26px;
          top: -2px;
          padding: 3px;
        }
      }

      &_active {
        border-color: var(--color-accent);
      }
    }
  }

  .selection-idea-info {
    &__title {
      color: var(--color-secondary);
      
      @include tablet-only() {
        font-size: 16px;
        line-height: 16px;
      }

      @include phone() {
        line-height: 18px;
      }
    }

    &__date {
      @include tablet-only() {
        margin-top: 4px;
        font-size: 16px;
      }
    }

    &__list li {
      @include tablet-only() {
        border-top-width: 14px;
      }

      @include phone() {
        border-top-width: 10px;
      }

      > * + * {
        @include tablet-only() {
          margin-top: 0;
          font-size: 18px;
          line-height: 24px;
        }
      }
    }

    ol {
      @include tablet-only() {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
