@import "../../../../../common";

.personal-comments {
  min-height: calc(100vh - 376px);

  .data-loader-wr {
    min-height: 80vh;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-top: rem(56);

    @include tablet() {
      margin-top: rem(40);
    }

    @include phone() {
      flex-direction: column;
      padding: 0 rem(16);
      margin-top: rem(20);

      .cr-datepicker {
        margin-left: rem(16);
        @include phone() {
          margin-left: 0;
          width: 100%;
          margin-top: rem(16);
        }
      }
    }
    .page-title {
      font: normal normal bold rem(28) / rem(32) PT Sans;
      text-transform: none;
      margin-top: 0;
      @include tablet() {
        font: normal normal bold rem(26) / rem(33) PT Sans;
      }
      @include phone() {
        font: normal normal bold rem(20) / rem(26) PT Sans;
      }
    }
  }

  &__list {
    max-width: 1127px;
    overflow-wrap: break-word;
    margin: rem(32) auto 0;

    @include phone() {
      margin-top: rem(16);
    }

    &__comment {
      background: #fff;
      padding: rem(24);
      max-width: 1127px;
      word-break: break-word;
      margin-bottom: rem(32);
      border-radius: 4px;

      @include tablet() {
        margin-bottom: rem(24);
      }
      @include phone() {
        border-radius: unset;
        padding: rem(16);
        margin-bottom: rem(16);
        margin-top: 0;
        &__comment {
          margin-bottom: rem(16);
        }
      }
    }
  }
}
