.pdf-view {
  background-color: #e6e7eb;
  padding: 4px;
  border-radius: 4px;
  max-width: 96px;

  &__text {
    font: normal normal normal 14px/16px PT Sans;
    letter-spacing: 0px;
    color: #7e7e7e;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
  }

  svg {
    height: 20px;
    margin-top: 6px;
  }
}
