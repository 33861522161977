@import "../../../../../../../common.scss";

.message-view {
  background-color: #f2f4f7;
  flex-grow: 1;
  height: clamp(540px, 50vh, 100vh);
  overflow-y: auto;
  display: flex;
  flex-flow: column nowrap;

  @include scrollbar(5px, 4px);

  @include phone() {
    height: clamp(278px, 50vh, 100vh);
  }

  &__date {
    width: fit-content;
    margin: 0 auto;
    background: #e6e7eb;
    padding: 8px 12px;
    border-radius: 18px;
    font: normal normal normal 18px/24px PT Sans;
    letter-spacing: 0px;
    color: #282828;
    margin-bottom: 16px;

    @include phone() {
      font: normal normal normal 14px/24px PT Sans;
      letter-spacing: 0px;
      padding: 4px 8px;
    }
  }

  &__unread {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font: italic normal normal 18px/24px PT Sans;
    letter-spacing: 0px;
    color: #65aae0;
    padding: 8px 0;
    margin: 0 8px 20px;

    @include phone() {
      font: italic normal normal 14px/24px PT Sans;
    }
  }

  &__group {
    margin-top: auto;
  }
}
